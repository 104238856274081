import React, {Component} from "react";
import {Button, Card, CardBody, CardHeader, Col, Form, FormInput, FormSelect, Row,} from "shards-react";
import 'react-dropzone-uploader/dist/styles.css'
import {connect} from "react-redux";
import {recurrent} from "../../actions"

/**
 * La clase UpdateRecurrentMaterial representa un componente que permite actualizar materiales recurrentes.
 * @name UpdateRecurrentMaterial
 * @extends Component
 * @property {function} update_recurrent_files - Función que actualiza los materiales recurrentes.
 * @property {function} fetch_recurrent_files - Función que obtiene los materiales recurrentes actualizados.
 * @state {string} type - La sección del archivo a actualizar.
 * @state {File} file - El archivo a actualizar.
 * @function fileChangeHandler - Manejador de evento que actualiza el estado del archivo al cambiarlo.
 * @function reset_form - Función que reinicia el formulario.
 * @function submit_materials - Función que envía el formulario para actualizar los materiales recurrentes.
 * @returns {JSX.Element} - Retorna el JSX del formulario para actualizar materiales recurrentes.
*/

class UpdateRecurrentMaterial extends Component {

    state = {
        type: "",
        file: "",
    };


    fileChangeHandler = (e) => {
        this.setState({
            file: e.target.files[0],
        })
    };

    reset_form = () => {
        this.setState({message: "", file: ""})
    };

    submit_materials = (e) => {
        e.preventDefault();
        this.props.update_recurrent_files(this.state).then(this.props.fetch_recurrent_files());
        this.reset_form();
    };

    render() {
        return (
            <Card small className="h-100">
                <CardHeader className="border-bottom">
                    <i className="icon icon-anuncios header-icon"></i>
                    <h6 className="m-0">Actualizar Materiales</h6>
                </CardHeader>

                <CardBody className="d-flex flex-column">
                    <Form onSubmit={this.submit_materials}>
                        <Row form>
                            <Col lg="6" md="6">
                                <label htmlFor="Consumption">Sección</label>
                                <FormSelect
                                    value={this.state.type}
                                    onChange={(e) => this.setState({type: e.target.value})}
                                    required
                                >
                                    <option value="">---</option>
                                    <option value="Corporativos">Corporativos</option>
                                    <option value="Operativos">Operativos</option>
                                    <option value="Regulatorios">Regulatorios</option>
                                    <option value="Contractuales">Contractuales</option>
                                    <option value="Documentación Oficial">Documentación Oficial</option>
                                </FormSelect>
                            </Col>
                            <Col lg="6" md="6">
                                <label htmlFor="Consumption">Archivo</label>
                                <FormInput
                                    type="file"
                                    onChange={this.fileChangeHandler}
                                    required
                                />
                            </Col>
                            <Col lg="12" className="my-4">
                                <Button type="submit">Actualizar materiales</Button>
                            </Col>
                        </Row>
                    </Form>
                </CardBody>
            </Card>

        )
    }

}
/**
 * Función que mapea el estado a las props del componente
 * @param {Object} state - Estado actual de la aplicación
 * @returns {Object} - Objeto que contiene las props mapeadas al estado
*/
const mapStateToProps = state => {
    return {}
};
/**
 * Función que mapea las acciones a las props del componente
 * @param {Function} dispatch - Función de redux para despachar acciones
 * @returns {Object} - Objeto que contiene las props mapeadas a las acciones
*/
const mapDispatchToProps = dispatch => {
    return {
        fetch_recurrent_files: () => {
            return dispatch(recurrent.fetch_recurrent_files())
        },
        update_recurrent_files: (data) => {
            return dispatch(recurrent.update_recurrent_files(data))
        },
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(UpdateRecurrentMaterial);
