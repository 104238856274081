const initialState = {
  simulations: [],
  simulation: {},
  canon: null,
  simulation_options: { months: [], indexes: [], tariffs: [], regions: [] },
};

/**
 * Objeto que representa el estado inicial de la aplicación.
 * @typedef {Object} InitialState
 * @property {Array} simulations - Lista de simulaciones.
 * @property {Object} simulation - Simulación actual.
 * @property {Object} simulation_options - Opciones de simulación.
 * @property {Object} canon - Configuración actual del canon.
 */

/**
 * Función reductora de la tienda que maneja las acciones relacionadas con las simulaciones de cotización.
 * @name quoteSimulations
 * @param {InitialState} state - Estado actual de la aplicación.
 * @param {Object} action - Acción que se está ejecutando.
 * @param {string} action.type - Tipo de acción que se está ejecutando.
 * @param {Array} action.simulations - Lista de simulaciones.
 * @param {Object} action.simulation - Simulación actual.
 * @param {Object} action.canon - Configuración del canon.
 * @param {Object} action.options - Opciones de simulación.
 * @returns {InitialState} Nuevo estado de la aplicación después de ejecutar la acción.
 */

export default function quoteSimulations(state = initialState, action) {
  // let simulationList = state.slice();

  switch (action.type) {
    // case 'CREATE_FAI':
    //   console.log('CREATE FAI REDUCER');
    //   console.log(...state);
    //   console.log(action);
    //   return [...state, action.fai];

    case "FETCH_SIMULATIONS":
      return { ...state, simulations: [...action.simulations] };
    // return [...action.simulations];

    case "CREATE_SIMULATION":
      return {
        ...state,
        simulations: [action.simulation, ...state.simulations],
      };

    case "FETCH_SIMULATION":
      return {
        ...state,
        simulation: action.simulation,
        canon: action.simulation.canon,
      };

    case "UPDATE_SIMULATION":
      return { ...state, simulation: action.simulation };

    case "DELETE_SIMULATION":
      return {
        ...state,
        simulations: state.simulations.filter(
          (item) => item.id !== action.simulation
        ),
      };

    case "FETCH_OPTIONS":
      return {
        ...state,
        simulation_options: {
          months: action.options.months,
          indexes: action.options.indexes,
          tariffs: action.options.tariffs,
          regions: action.options.regions,
        },
      };

    case "CREATE_CANON":
    case "UPDATE_CANON":
      return { ...state, canon: action.canon };

    case "DELETE_CANON":
      return { ...state, canon: null };

    default:
      return state;
  }
}
