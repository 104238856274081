import React from "react";
import {Col, Container, Row} from "shards-react";

/**
 * Componente que define el diseño para la página de inicio de sesión.
 * @name LoginLayout
 * @param {Object} props - Las propiedades del componente.
 * @param {ReactNode} props.children - Los componentes hijos que se renderizarán dentro del diseño.
 * @returns {JSX.Element} - El componente de React que define el diseño para la página de inicio de sesión.
*/

const LoginLayout = ({ children }) => (
  <Container fluid>
    <Row>
      <Col
        className="mx-auto"
        lg={{ size: 4}}
        md={{ size: 6}}
        sm="12"
        tag="main"
      >
        {children}
      </Col>
    </Row>
  </Container>
);


export default LoginLayout;
