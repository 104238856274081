import { Dispatcher } from "flux";

/**
 * Objeto Dispatcher proporcionado por la librería Flux.
 * @external Dispatcher
*/

/**
 * Constante que exporta un nuevo objeto Dispatcher creado por la librería Flux.
 * @type {external:Dispatcher}
*/

export default new Dispatcher();
