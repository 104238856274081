import React, {Component} from "react";
import {Button, Card, CardBody, CardHeader, Col, Container, Form, FormSelect, FormTextarea, Row} from "shards-react";

import {connect} from "react-redux";
import {polls} from "../actions"
import {Link} from "react-router-dom";

/**
 * Clase CreatePoll que extiende de Componente de React.
 * @constructor
 * @param {object} props - Propiedades que recibe el componente.
 * @param {function} props.fetch_poll_types - Función que realiza una petición para obtener los tipos de encuestas.
 * @param {object} props.polls - Objeto que contiene los tipos de encuestas disponibles.
 * @param {function} props.create_poll - Función que crea una nueva encuesta.
 * @param {object} props.match - Objeto que contiene información sobre cómo la ruta de la URL coincide con un patrón.
 * @param {object} props.history - Objeto que permite la navegación hacia adelante y hacia atrás en la historia de la sesión del navegador.
 * @throws {TypeError} Si se llama sin la palabra clave "new", o si la propiedad "props" no es un objeto.
*/

class CreatePoll extends Component {

/**
 * Constructor del componente CreatePoll.
 * @param {object} props - Propiedades que recibe el componente.
*/

    constructor(props) {
        super(props);
        this.state = {
            poll_types: [],
            poll_values: [],
            selected_poll: null,
        };
    }
/**
 * Método que se ejecuta cuando el componente se ha montado en el DOM.
 * Realiza una petición para obtener los tipos de encuestas disponibles, y establece el estado inicial de la encuesta.
*/
    componentDidMount() {
        this.props.fetch_poll_types().then(() => {
            let current_poll = this.props.polls.types[0];
            let poll_config = current_poll.config;
            let poll_state = [];
            poll_config.map((question, index) => {
                poll_state[index] = "";
                return 'Done'
            });
            this.setState({
                poll_types: this.props.polls.types,
                poll_values: poll_state,
                selected_poll: current_poll.id,
            })
        });
    }
/**
 * Método que actualiza el valor de una pregunta de la encuesta.
 * @param {Event} e - Evento que se produce cuando el usuario interactúa con un elemento de la página.
 * @param {number} index - Índice de la pregunta en el arreglo de preguntas de la encuesta.
 * @param {string} value - Valor que se ha introducido en el campo de la pregunta.
*/
    update_question(e, index, value) {
        let poll_values = this.state.poll_values;
        poll_values[index] = value;
        this.setState({poll_values: poll_values});
    }
/**
 * Método que se ejecuta cuando el usuario envía la encuesta.
 * Crea una nueva encuesta con los valores introducidos por el usuario y redirige a la página del cliente.
 * @param {Event} e - Evento que se produce cuando el usuario interactúa con un elemento de la página.
*/
    submit_poll = (e) => {
        e.preventDefault();
        let customer_id = this.props.match.params.id;
        let data = {
            "poll": this.state.selected_poll,
            "customer": customer_id,
            "answers": this.state.poll_values,
        };
        this.props.create_poll(data).then(() => {
            this.props.history.push(`/clientes/${customer_id}`)
        })

    };
/**
 * Método que renderiza el componente.
 * @returns {JSX.Element} - Elemento JSX que representa el componente.
*/
    render() {
        let {poll_types, poll_values} = this.state;
        const customer_id = this.props.match.params.id;

        let poll_stuff = '';
        if (poll_types.length > 0) {
            let poll = poll_types[0];
            let config = poll.config;
            poll_stuff = <div>
                {config.map((question, index) => {
                    let question_block = <h1>Loading Question</h1>;
                    if (question.type === 'text') {
                        question_block = <Col lg="12">
                            <label htmlFor="Consumption">{question.title}</label>
                            <FormTextarea
                                type="text"
                                value={poll_values[index]}
                                onChange={(e) => this.update_question(e, index, e.target.value)}
                                required
                            />
                        </Col>
                    } else if (question.type === 'multiple_choice') {
                        question_block = <Col lg="12" className="form-group">
                            <label htmlFor="Consumption">{question.title}</label>
                            <FormSelect
                                value={poll_values[index]}
                                onChange={(e) => this.update_question(e, index, e.target.value)}
                                required
                            >
                                <option value="">{question.help_text}</option>
                                {question.values.map((value, index) => (
                                    <option key={index} value={value[0]}>{value[1]}</option>
                                ))}
                            </FormSelect>
                        </Col>
                    }

                    return <Row key={`key_${index}`}>{question_block}</Row>
                })}
            </div>;
        }

        let container = <><Container fluid className="main-content-container px-4">
            {/* Page Header */}
            <Row>
                {/* New Draft */}
                <Col className="my-4">
                    <Link to={`/clientes/${customer_id}`}>
                        <Button>Regresar</Button>
                    </Link>
                </Col>
                <Col lg="12" className="mb-8">
                    <Card small>
                        {/* Card Header */}
                        <CardHeader className="border-bottom">
                            <h6 className="m-0">Crear Encuesta</h6>
                        </CardHeader>

                        <CardBody className="d-flex flex-column">
                            <Form onSubmit={this.submit_poll}>
                                {poll_stuff}
                                <Row className="my-1">
                                    <Col lg="12" className="my-4">
                                        <Button theme="accent" className="btn btn-primary btn-block">Guardar</Button>
                                    </Col>
                                </Row>
                            </Form>
                            <Row>

                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>

        </Container></>;
        return container
    }

}
/**
 * Función que mapea el estado a las props del componente
 * @param {Object} state - Estado actual de la aplicación
 * @returns {Object} - Objeto que contiene las props mapeadas al estado
*/
const mapStateToProps = state => {
    return {
        polls: state.polls,
    }
};
/**
 * Función que mapea las acciones a las props del componente
 * @param {Function} dispatch - Función de redux para despachar acciones
 * @returns {Object} - Objeto que contiene las props mapeadas a las acciones
*/
const mapDispatchToProps = dispatch => {
    return {
        fetch_poll_types: () => {
            return dispatch(polls.fetch_poll_types())
        },
        create_poll: (data) => {
            return dispatch(polls.create_poll(data))
        },
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(CreatePoll);
