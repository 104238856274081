import React, {Component} from "react";
import {Card, CardBody, CardHeader, Col, Container, Row} from "shards-react";

import PageTitle from "./../components/common/PageTitle";

import {connect} from "react-redux";

/**
 * Componente de React que representa la vista de la sección "Clientes". Esta clase se extiende de la clase "Component" de React y contiene un estado interno con los siguientes atributos:
 * @typedef {Object} CustomersState
 * @property {boolean} existing_installation - Indica si ya existe una instalación previa.
 * @property {string} contract_id - El número de contrato del cliente.
 * @property {string} company_name - El nombre de la compañía del cliente.
 * @property {string} kam - El nombre del key account manager del cliente.
 * @property {string} user_in_charge - El nombre del usuario responsable del cliente.
 * @extends {Component}
*/
class Customers extends Component {
/**
 * El estado inicial de este componente.
 * @type {CustomersState}
*/
    state = {
        existing_installation: false,
        contract_id: "",
        company_name: "",
        kam: "",
        user_in_charge: "",
    };
/**
 * Método del ciclo de vida de React que se ejecuta después de que el componente ha sido montado en el DOM. Este método no hace nada en este componente.
*/
    componentDidMount() {

    }

    render() {

        return <><Container fluid className="main-content-container px-4">
            {/* Page Header */}
            <Row noGutters className="page-header py-4">
                <PageTitle title="Clientes" className="text-sm-left mb-3"/>
            </Row>
                <Row>
                    <Col className="mb-8">
                        <Card>
                            <CardHeader className="border-bottom">
                                <h6 className="m-0">Carga de Archivo</h6>
                            </CardHeader>
                            <CardBody className="d-flex flex-column">

                            </CardBody>
                        </Card>
                    </Col>
                </Row>


        </Container></>;
    }

}
/**
 * Función que mapea el estado a las props del componente
 * @param {Object} state - Estado actual de la aplicación
 * @returns {Object} - Objeto que contiene las props mapeadas al estado
*/
const mapStateToProps = state => {
    return {

    }
};
/**
 * Función que mapea las acciones a las props del componente
 * @param {Function} dispatch - Función de redux para despachar acciones
 * @returns {Object} - Objeto que contiene las props mapeadas a las acciones
*/
const mapDispatchToProps = dispatch => {
    return {

    }
};


export default connect(mapStateToProps, mapDispatchToProps)(Customers);
