import React, {Component} from "react";
import {Button, Card, CardBody, Col, Container, FormInput, Row} from "shards-react";

import {connect} from "react-redux";
import PageTitle from "../components/common/PageTitle";
import {simulations} from "../actions";

/**
 * Clase para la simulación genérica.
 * @extends Component
*/

class GenericSimulation extends Component {
/*
 * Constructor de la clase.
 * @param {Object} props - Propiedades de la clase.
*/
    constructor(props) {
        super(props);
/** 
 * Estado inicial de la simulación.
 * @property {number} management_cost - Costo de gestión.
 * @property {number} interruptible_management_cost - Costo de gestión interrumpible.
 * @property {number} spread - Spread.
 * @property {number} interruptible_spread - Spread interrumpible.
 * @property {Array} daily_values - Valores diarios.
*/
        this.state = {
            management_cost: 0,
            interruptible_management_cost: 0,
            spread: 0,
            interruptible_spread: 0,
            daily_values: [],
        };
        this.update = this.update.bind(this);
        this.updateList = this.updateList.bind(this);
    }
/**
 * Manejador del evento de cambio.
 * @param {Object} e - Evento.
*/
    onChange = e => this.setState({[e.target.name]: e.target.value})
/**
 * Método que se ejecuta después del montaje del componente.
 * @returns {Promise} Promesa que indica que se ha cargado la simulación.
*/
    componentDidMount() {
        this.props.fetch_simulation(this.props.match.params.id).then(() => {
            const {simulation} = this.props;

            this.setState({
                management_cost: simulation.management_cost,
                interruptible_management_cost: simulation.interruptible_management_cost,
                spread: simulation.spread,
                interruptible_spread: simulation.interruptible_spread,
                daily_values: simulation.daily_values,
            })
        });
    }

    update(e) {
        e.preventDefault();
        console.log('UPDATE');
        this.props.update_simulation(this.props.match.params.id, this.state);
    }
/**
 * Método de actualización de la lista.
 * @param {Object} e - Evento.
*/
    updateList(e) {
        let daily_values = this.state.daily_values
        console.log('WHAT', daily_values);
        daily_values[e.target.id][e.target.name] = e.target.value
        this.setState({daily_values})
    }

    render() {
        const {simulation} = this.props;

        if (simulation === null) {
            return <h1>Loading...</h1>
        }
        console.log(this.state);

        return <><Container fluid className="main-content-container px-4">
            <Row noGutters className="page-header py-4">
                <PageTitle title="Simulación Firme e Interrumplible"
                           className="text-sm-left mb-3 col-sm-12"/>
            </Row>
            <Row>
                <Col className="my-2">

                    <Button className="float-right col-md-12 col-lg-2" onClick={this.update}>Guardar</Button>

                </Col>
            </Row>
            <Row>
                <Col md={6}>
                    <Card small>
                        <CardBody className="d-flex flex-column">
                            <table>
                                <tbody>
                                <tr>
                                    <td>Spread</td>
                                    <td><FormInput
                                        id="contract-id"
                                        type="number"
                                        name="wawdmasl"
                                        value={this.state.spread}
                                        onChange={this.updateList}
                                        // onChange={(e) => this.setState({spread: e.target.value})}
                                        required
                                    /></td>
                                </tr>
                                <tr>
                                    <td>C. Gestión</td>
                                    <td><FormInput
                                        id="contract-id"
                                        type="number"
                                        value={this.state.management_cost}
                                        onChange={(e) => this.setState({management_cost: e.target.value})}
                                        required
                                    /></td>
                                </tr>
                                </tbody>
                            </table>

                        </CardBody>
                    </Card>
                </Col>

                <Col md={6}>
                    <Card small>
                        <CardBody className="d-flex flex-column">
                            <table>
                                <tbody>
                                <tr>
                                    <td>Spread</td>
                                    <td><FormInput
                                        id="contract-id"
                                        type="number"
                                        value={this.state.interruptible_spread}
                                        onChange={(e) => this.setState({interruptible_spread: e.target.value})}
                                        required
                                    /></td>
                                </tr>
                                <tr>
                                    <td>C. Gestión</td>
                                    <td><FormInput
                                        id="contract-id"
                                        type="number"
                                        value={this.state.interruptible_management_cost}
                                        onChange={(e) => this.setState({interruptible_management_cost: e.target.value})}
                                        required
                                    /></td>
                                </tr>
                                </tbody>
                            </table>
                        </CardBody>
                    </Card>
                </Col>
            </Row>

            <Card small className="my-4">
                <CardBody className="d-flex flex-column">
                    <table className="table table-sm simulation-data">
                        <tbody>
                        {simulation.simulation.map((data, id) => (
                            <tr key={id}>
                                <td>{data['Day']}</td>
                                <td>{data['Firm']}</td>
                                <td>{data['Consumption']}</td>
                                <td>{data['IConsumption']}</td>
                                <td>{data['Index']}</td>
                                <td>{data['Exchange']}</td>
                                <td>{data['Spread']}</td>
                                <td>{data['Price']}</td>
                                <td>{data['Transport']}</td>
                                <td>{data['Gas Fuel']}</td>
                                <td>{data['Management Cost']}</td>
                                <td>{data['Unit Price']}</td>
                                <td>{data['Daily Price']}</td>
                            </tr>
                        ))}

                        </tbody>
                    </table>
                    <table>
                        <thead>
                        <tr>
                            <th>Día</th>
                            <th>CMD</th>
                            <th>Consumo GJ</th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.daily_values.map((data, id) => (
                            <tr key={id}>
                                <td>{data.Day}</td>
                                <td>
                                    <FormInput
                                        name="firm"
                                        id={id}
                                        type="number"
                                        value={data.firm}
                                        onChange={this.updateList}
                                        required

                                    />
                                </td>
                                <td>
                                    <FormInput
                                        name="monthly_consumption"
                                        type="number"
                                        id={id}
                                        value={data.monthly_consumption}
                                        onChange={this.updateList}
                                        required
                                    />
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>

                </CardBody>
            </Card>

        </Container></>;

    }

}
/**
 * Función que mapea el estado a las props del componente
 * @param {Object} state - Estado actual de la aplicación
 * @returns {Object} - Objeto que contiene las props mapeadas al estado
*/
const mapStateToProps = state => {
    return {
        simulation: state.simulations.detail,
    }
};
/**
 * Función que mapea las acciones a las props del componente
 * @param {Function} dispatch - Función de redux para despachar acciones
 * @returns {Object} - Objeto que contiene las props mapeadas a las acciones
*/
const mapDispatchToProps = dispatch => {
    return {
        fetch_simulation: (id) => {
            return dispatch(simulations.fetchFAISimulation(id))
        },
        update_simulation: (id, data) => {
            return dispatch(simulations.updateFAI(id, data))
        },
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(GenericSimulation);
