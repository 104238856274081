import React from "react";
import ReactQuill from "react-quill";
import { Card, CardBody, Form, FormInput } from "shards-react";

import "react-quill/dist/quill.snow.css";
import "../../assets/quill.css";

/**
 * Componente Editor
 * @component
 * @desc Este componente es una sección de edición de publicaciones.
 * Permite al usuario ingresar un título para la publicación y
 * editar el contenido mediante el uso de ReactQuill.
 * @returns {JSX.Element} Retorna un elemento JSX que contiene un formulario
 * con un campo de entrada para el título de la publicación y un editor de texto.
*/

const Editor = () => (
  <Card small className="mb-3">
    <CardBody>
      <Form className="add-new-post">
        <FormInput size="lg" className="mb-3" placeholder="Your Post Title" />
        <ReactQuill className="add-new-post__editor mb-1" />
      </Form>
    </CardBody>
  </Card>
);

export default Editor;
