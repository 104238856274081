import axios from "axios";

/**
 * Función que obtiene la lista de archivos del servidor
 * @name fetchFiles
 * @returns {Promise} - Promesa que devuelve la lista de archivos
*/

export const fetchFiles = () => {
    return (dispatch, getState) => {
        let headers = {"Content-Type": "application/json"};
        let {token} = getState().auth;

        if (token) {
            headers["Authorization"] = `Token ${token}`;
        }

        return axios.get("/api/files/", {headers}).then(res => {
            return dispatch({type: 'FETCH_FILES', files: res.data})
        }).catch(err => {
            console.log('ERROR');
            console.log(err);
            console.log(err.response.status);
        })
    }
};

/**
 * Función que comparte un archivo con otro usuario
 * @name sharedFile
 * @param {number} file_id - ID del archivo a compartir
 * @param {string} shared_with - Usuario con el que se compartirá el archivo
 * @returns {Promise} - Promesa que devuelve los datos del archivo compartido
*/
export const shareFile = (file_id, shared_with) => {
    return (dispatch, getState) => {
        let headers = {"Content-Type": "application/json"};
        let {token} = getState().auth;

        if (token) {
            headers["Authorization"] = `Token ${token}`;
        }

        let body = JSON.stringify({
            shared_with: shared_with
        });

        return axios.patch(`/api/files/${file_id}/`, body,{headers}).then(res => {
            return dispatch({type: 'SHARE_FILES', file: res.data})
        }).catch(err => {
            console.log('ERROR');
            console.log(err);
            console.log(err.response.status);
        })
    }
};

/**
 * Elimina un archivo de la lista de archivos disponibles del usuario.
 * @name deleteFile
 * @param {number} index - El índice del archivo a eliminar en la lista de archivos del estado global.
 * @returns {function} - Función que toma dos argumentos (dispatch y getState) y devuelve una promesa.
 * @throws {object} - Objeto de error en caso de error en la solicitud.
*/

export const deleteFile = index => {
  return (dispatch, getState) => {

    let headers = {"Content-Type": "application/json"};
    let {token} = getState().auth;

    if (token) {
      headers["Authorization"] = `Token ${token}`;
    }

    let fileId = getState().files[index].id;

    return fetch(`/api/files/${fileId}/`, {headers, method: "DELETE"})
        .then(res => {
          if (res.status === 204) {
            return {status: res.status, data: {}};
          } else if (res.status < 500) {
            return res.json().then(data => {
              return {status: res.status, data};
            })
          } else {
            console.log("Server Error!");
            throw res;
          }
        })
        .then(res => {
          if (res.status === 204) {
            return dispatch({type: 'DELETE_FILE', index});
          } else if (res.status === 401 || res.status === 403) {
            dispatch({type: "AUTHENTICATION_ERROR", data: res.data});
            throw res.data;
          }
        })
  }
};