const initialState = {list: [], sections: [], notifications: {total: 0, pulled: false}, sidebar_links: []};
/**
 * Esta función maneja las acciones relacionadas con el estado de usuarios
 * @param {Object} state - Estado actual de usuarios
 * @param {Object} action - Acción que se está ejecutando en el estado
 * @returns {Object} Nuevo estado de usuarios después de ejecutar la acción
*/

export default function users(state=initialState, action) {
  // let contractList = state.slice();

  switch (action.type) {

    case 'FETCH_USERS':
      return {...state, list: action.users};

    case 'CREATE_USER':
      return {...state, list: [action.user.user, ...state.list]};

    case 'EDIT_USER':
      return {...state};

    case 'FETCH_SECTIONS':
      return {...state, sections: action.sections};

    case 'FETCH_SIDEBAR_LINKS':
      return {...state, sidebar_links: action.data};

    case 'FETCH_NOTIFICATIONS':
      let notifications = action.notifications;
      notifications.pulled = true;
      return {...state, notifications: notifications};

    default:
      return state;
  }
}
