import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";

/**
 * Componente que renderiza una ruta privada.
 * @name PrivateRoute
 * @param {Object} props - Propiedades del componente.
 * @param {Component} props.component - Componente a renderizar si el usuario está autenticado.
 * @param {Object} props.auth - Objeto que contiene información de autenticación.
 * @param {boolean} props.auth.isAuthenticated - Indica si el usuario está autenticado o no.
 * @param {boolean} props.auth.isLoading - Indica si se está cargando la información de autenticación.
 * @param {Object} rest - Resto de las propiedades que se le pasan al componente Route de React Router.
 * @returns {JSX.Element} Componente que renderiza una ruta privada.
*/

const PrivateRoute = ({ component: Component, auth, ...rest }) => (
    <Route
        {...rest}
        render={props => {
            if (auth.isLoading) {
                return <h2>Loading...</h2>;
            } else if (!auth.isAuthenticated) {
                return (
                    <Redirect
                        to={{
                            pathname: '/login',
                            state: { originPath: props.match.path }
                        }}
                    />
                );
            } else {
                return <Component {...props} />;
            }
        }}
    />
);
/**
 * Función que mapea el estado de autenticación a las propiedades del componente.
 * @param {Object} state - Estado de la aplicación.
 * @param {Object} state.auth - Objeto que contiene información de autenticación.
 * @returns {Object} Propiedades que se le pasan al componente.
*/
const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps)(PrivateRoute);