import React from "react";
import { ListGroupItem, Progress } from "shards-react";

/**
 * Componente para mostrar barras de progreso.
 * @name ProgressBars
 * @function
 * @returns {JSX.Element} Componente de barras de progreso.
*/

const ProgressBars = () => (
  <ListGroupItem className="px-3">
    <div className="mb-2">
      <strong className="text-muted d-block mb-3">Progress Bars</strong>
      <Progress style={{ height: "5px" }} value={50} className="mb-3" />
      <Progress
        theme="success"
        style={{ height: "5px" }}
        className="mb-3"
        value={40}
      />
      <Progress
        theme="info"
        style={{ height: "5px" }}
        className="mb-3"
        value={60}
      />
      <Progress
        theme="danger"
        style={{ height: "5px" }}
        className="mb-3"
        value={80}
      />
    </div>
  </ListGroupItem>
);

export default ProgressBars;

/**
 * Renderiza un componente que contiene varias barras de progreso.
 * @typedef {Object} JSX.Element
 * @property {string} className - Clase CSS para dar estilo al elemento.
 * @property {Object} style - Estilos CSS para dar altura a las barras de progreso.
 * @property {number} value - Valor numérico que representa el porcentaje de la barra de progreso.
 * @property {string} theme - Tema de la barra de progreso, puede ser 'success', 'info' o 'danger'.
*/