import axios from "axios";

/**
 * Crea un nuevo informe de cliente
 * @name create_customer_report
 * @param {Object} data - Los datos del informe del cliente
 * @return {Function} - Una función que devuelve una promesa con el resultado de la solicitud
 */

export const create_customer_report = (data) => {
  return (dispatch, getState) => {
    let headers = { "Content-Type": "application/json" };
    /**
     * El token de autorización del estado actual
     * @type {string}
     */
    let { token } = getState().auth;

    if (token) {
      headers["Authorization"] = `Token ${token}`;
    }
    /**
     * El cuerpo de la solicitud
     * @type {Object}
     */
    let body = { ...data };
    
    /**
     * Realiza una solicitud POST para crear un nuevo informe de cliente
     * @type {Promise}
     */
    return axios
      .post("/api/customer_report/", body, { headers })
      .then((res) => {
        return dispatch({ type: "CREATE_REPORT", report: res.data });
      })
      .catch((err) => {
        console.log("ERROR");
        console.log(err.response);
        if (err.response.status === 401) {
          let error = { errors: "Sesión expirada, inicie sesión nuevamente" };
          dispatch({ type: "AUTHENTICATION_ERROR", data: error });
        }
      });
  };
};

/**
 * Obtiene todos los informes de cliente 2
 * @return {Function} - Una función que devuelve una promesa con el resultado de la solicitud
 */

export const fetch_customer_2_reports = () => {
  return (dispatch, getState) => {
    /**
     * Los encabezados de la solicitud
     * @type {Object}
     */
    let headers = { "Content-Type": "application/json" };
    /**
     * El token de autorización del estado actual
     * @type {string}
     */
    let { token } = getState().auth;

    if (token) {
      headers["Authorization"] = `Token ${token}`;
    }
    /**
     * Realiza una solicitud GET para obtener todos los informes de cliente 2
     * @type {Promise}
     */
    return axios
      .get(`/api/customer2_report/`, { headers })
      .then((res) => {
        return dispatch({ type: "CUSTOMER_2_REPORTS", data: res.data });
      })
      .catch((err) => {
        console.log("ERROR");
        console.log(err.response);
        if (err.response.status === 401) {
          let error = { errors: "Sesión expirada, inicie sesión nuevamente" };
          dispatch({ type: "AUTHENTICATION_ERROR", data: error });
        }
      });
  };
};
