import React, {Component} from "react";
import {Card, CardBody, Col, Container, Row} from "shards-react";

import {connect} from "react-redux";

import {Carousel} from 'react-responsive-carousel';

import slide1 from "../images/slide_1.jpg"
import slide2 from "../images/slide_2.jpg"
import slide3 from "../images/slide_3.jpg"

import "react-responsive-carousel/lib/styles/carousel.min.css";

import logo from "../images/_acerca_industrial_l_blanco.png"
import logo_small from "../images/naturgy_blanco.png"

/**
 * Clase para renderizar la página de inicio de la aplicación.
 * @extends Component
*/
class Home extends Component {
/**
 * Renderiza el contenido de la página de inicio.
 * @returns {JSX.Element} Contenido de la página de inicio.
*/
    render() {

        return <><Container fluid className="main-content-container px-4">
            <Row className="py-4 justify-content-center">
                <Col lg={6} className="">
                    <Row>
                        <Col md={12} className="np">
                            <Carousel infiniteLoop={true} showThumbs={false} showStatus={false} autoPlay={true} interval={5000}
                                      showArrows={false}>
                                <div>
                                    <img src={slide1} alt="Slide 1"/>
                                </div>
                                <div>
                                    <img src={slide2} alt="Slide 2"/>
                                </div>
                                <div>
                                    <img src={slide3} alt="Slide 3"/>
                                </div>
                            </Carousel>
                        </Col>
                        <Col md={12} className="np pull-left">
                            <Card small className="h-100">
                                {/* Card Header */}

                                <CardBody className="d-flex flex-column home-slide">
                                    <Row>
                                        <Col className="f-right">
                                            <img src={logo_small} alt="Logo" align="right"/>
                                        </Col>
                                    </Row>
                                    <Row className="my-2">
                                        <Col>
                                            <img src={logo} className="image-fluid" alt="Logo"/>
                                        </Col>
                                    </Row>


                                    <p>
                                        En Acerca Industrial
                                        encontrarás información especializada, normativas, avisos clasificados y control de tus
                                        clientes.
                                    </p>
                                    <p>Bienvenido.</p>

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Col>
            </Row>

        </Container></>;
    }

}
/**
 * Función que mapea el estado a las props del componente
 * @param {Object} state - Estado actual de la aplicación
 * @returns {Object} - Objeto que contiene las props mapeadas al estado
*/
const mapStateToProps = state => {
    return {}
};
/**
 * Función que mapea las acciones a las props del componente
 * @param {Function} dispatch - Función de redux para despachar acciones
 * @returns {Object} - Objeto que contiene las props mapeadas a las acciones
*/
const mapDispatchToProps = dispatch => {
    return {}
};


export default connect(mapStateToProps, mapDispatchToProps)(Home);
