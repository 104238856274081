import React, {Component} from "react";
import {Card, CardBody, Col, Container, Row} from "shards-react";

import {connect} from "react-redux";
import PageTitle from "../components/common/PageTitle";
import {simulations} from "../actions";

/**
 * Componente para la simulación de Swing e Interrumpible.
 * @extends Component
*/

class SimulationSwingAndInterruptible extends Component {
/**
 * Crea una instancia de SimulationSwingAndInterruptible.
 * @param {object} props - Propiedades del componente.
*/
    constructor(props) {
        super(props);
        this.state = {};
    }
/**
 * Ejecuta una acción después de que el componente haya sido montado.
 * Llama a la acción fetch_simulation para obtener la simulación correspondiente al id en las propiedades de la ruta.
*/
    componentDidMount() {
        this.props.fetch_simulation(this.props.match.params.id);
    }
/**
 * Formatea un número al formato de moneda de los Estados Unidos, o devuelve '-' si el número no es válido.
 * @param {number} n - El número a formatear.
 * @returns {string} El número formateado en formato de moneda de los Estados Unidos o '-' si el número no es válido.
*/
    numberFormat = (n) => {
        if (n !== "en-US") {
            return n.toLocaleString("en-US", {minimumFractionDigits: 2, maximumFractionDigits: 2})
        }
        return '-'
    };
/**
 * Renderiza el componente.
 * @returns {JSX.Element} El componente renderizado.
*/
    render() {
        const {simulation} = this.props;

        if (simulation === null) {
            return <h1>Loading...</h1>
        }


        const simulation_values = simulation.simulation;
        const simulation_daily_values = simulation_values.values;
        console.log(simulation_values)
        console.log(simulation_daily_values)

        return <><Container fluid className="main-content-container px-4">
            <Row noGutters className="page-header py-4">
                <PageTitle title="Swing e Interrumpible"
                           className="text-sm-left mb-3 col-sm-12"/>
            </Row>
            <Row>

            </Row>

            <Row>
                <Col>
                    <Card className="my-4">
                        <CardBody className="d-flex flex-column">
                            <table className={"table table-sm"}>
                                <thead>
                                <tr>
                                    <th>GJ</th>
                                    <th>Costo</th>
                                    <th>Precio Unitario</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    {/*<td>{simulation_values['gj']}</td>*/}
                                    {/*<td>{simulation_values['total']}</td>*/}
                                    {/*<td>{simulation_values['unit_price']}</td>*/}
                                </tr>
                                </tbody>
                            </table>
                        </CardBody>
                    </Card>
                </Col>
            </Row>

            <Card small className="my-4">
                <CardBody className="d-flex flex-column">
                    <div className={"table-responsive"}>
                        <table className="table table-sm simulation-data">
                            <thead>
                            <tr>
                                <th/>
                                <th/>
                                <th colSpan={3}>Asignación Final de Volúmenes por Base</th>
                                <th colSpan={2}>Penalización</th>
                                <th colSpan={1}>Consumo</th>
                                <th/>
                                <th colSpan={4}>Costo Modalidad Swing e Interrumpible</th>
                            </tr>
                            <tr>
                                <th>Día</th>
                                <th>Consumo</th>
                                <th>Firme</th>
                                <th>Swing</th>
                                <th>Interrumpible</th>
                                <th>Adicional</th>
                                <th>Importe</th>
                                <th>CMD</th>
                                <th>Índice</th>
                                <th>Swing</th>
                                <th>Interrumpible</th>
                                <th>Gas Fuel Swing</th>
                                <th>Gas Fuel Interrumpible</th>
                            </tr>
                            </thead>
                            <tbody>
                            {simulation_daily_values.map((data, id) => (
                                <tr key={id}>
                                    <td>{data['day']}</td>
                                    <td>{data['consumption']}</td>
                                    <td>{this.numberFormat(data['firm'])}</td>
                                    <td>{this.numberFormat(data['swing'])}</td>
                                    <td>{this.numberFormat(data['interruptible'])}</td>
                                    <td>{this.numberFormat(data['penalization_count'])}</td>
                                    <td>{this.numberFormat(data['penalization_amount'])}</td>
                                    <td>{this.numberFormat(data['over_cmd'])}</td>
                                    <td>{this.numberFormat(data['index'])}</td>
                                    <td>{this.numberFormat(data['swing_total'])}</td>
                                    <td>{this.numberFormat(data['interruptible_total'])}</td>
                                    <td>{this.numberFormat(data['swing_gas_fuel'])}</td>
                                    <td>{this.numberFormat(data['gas_fuel_interruptible'])}</td>
                                </tr>
                            ))}
                            <tr>
                                <td></td>
                                <td>{simulation_values['firm_total']}</td>
                                <td>{simulation_values['swing_gj']}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>

                </CardBody>
            </Card>

        </Container></>;

    }

}
/**
 * Función que mapea el estado a las props del componente
 * @param {Object} state - Estado actual de la aplicación
 * @returns {Object} - Objeto que contiene las props mapeadas al estado
*/
const mapStateToProps = state => {
    return {
        simulation: state.simulations.detail_sai,
    }
};
/**
 * Función que mapea las acciones a las props del componente
 * @param {Function} dispatch - Función de redux para despachar acciones
 * @returns {Object} - Objeto que contiene las props mapeadas a las acciones
*/
const mapDispatchToProps = dispatch => {
    return {
        fetch_simulation: (id) => {
            return dispatch(simulations.fetchSwingAndInterruptible(id))
        },
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(SimulationSwingAndInterruptible);
