import React from "react";
import { Nav } from "shards-react";

import Notifications from "./Notifications";
import UserActions from "./UserActions";
/**
 * Componente funcional que renderiza la barra de navegación con las notificaciones y acciones del usuario.
 * @function
 * @returns {JSX.Element} Elemento JSX que contiene la barra de navegación.
*/

export default () => (
  <Nav navbar className="border-left flex-row">
    <Notifications />
    <UserActions />
  </Nav>
);
