import axios from "axios";


export const fetchCanon = (simulationId) => {
    return (dispatch, getState) => {
        let headers = {"Content-Type": "application/json"};
        let {token} = getState().auth;

        if (token) {
            headers["Authorization"] = `Token ${token}`;
        }
/**
* Realiza una petición al servidor para cerrar la sesión.
* @method POST
* @param {object} headers - Encabezados de la petición.
* @param {string} body - Cuerpo de la petición.
* @param {string} method - Método HTTP utilizado para la petición.
* @returns {Promise} Promesa que resuelve con el resultado de la petición al servidor.
* @throws Error si la petición al servidor no fue exitosa.
*/
        return axios.get(`/api/simulations/${simulationId}/`, {headers}).then(res => {
            return dispatch({type: 'FETCH_CANON', simulation: res.data})
        }).catch(err => {
            console.log('ERROR');
            console.log(err);
            console.log(err.response.status);
        })
    }
};

/**
 * Actualiza una simulación de canon en el servidor.
 * @name updateCanon
 * @param {number} simulation_id - El ID de la simulación a actualizar.
 * @param {object} values - Los valores actualizados de la simulación.
 * @returns {Promise<object>} - Una promesa que se resuelve con los datos actualizados de la simulación.
*/

export const updateCanon = (simulation_id, values) => {
    return (dispatch, getState) => {
        let headers = {"Content-Type": "application/json"};
        let {token} = getState().auth;

        if (token) {
            headers["Authorization"] = `Token ${token}`;
        }

        let data = {
            input: values,
        }

        console.log(data);
/**
* Realiza una petición PUT al servidor para actualizar la simulación de canon.
*
* @param {string} url - La URL de la API del servidor.
* @param {object} data - Los datos a enviar en la petición.
* @param {object} headers - Las cabeceras de la petición.
* @returns {Promise<object>} - Una promesa que se resuelve con los datos actualizados de la simulación.
*/
        return axios.put(`/api/simulations/${simulation_id}/`, data,{headers}).then(res => {
            return dispatch({type: 'UPDATE_CANON', simulation: res.data})
        }).catch(err => {
            console.log('ERROR');
            console.log(err);
            console.log(err.response);
        })
    }
};

// export const fetchOptions = () => {
//     return (dispatch, getState) => {
//         let headers = {"Content-Type": "application/json"};
//         let {token} = getState().auth;
//
//         if (token) {
//             headers["Authorization"] = `Token ${token}`;
//         }
//
//         return axios.get(`/api/options/`, {headers}).then(res => {
//             return dispatch({type: 'FETCH_OPTIONS', options: res.data})
//         }).catch(err => {
//             console.log('ERROR');
//             console.log(err);
//             console.log(err.response.status);
//         })
//     }
// };
