import React from "react";
import {Nav} from "shards-react";

import SidebarNavItem from "./SidebarNavItem";
import {Store} from "../../../flux";
import {connect} from "react-redux";
import {users} from "../../../actions";

/**
 * Componente que representa la barra de navegación lateral con los elementos del menú.
 * @name SidebarNavItems
 * @extends React.Component
 * @prop {function} fetch_sidebar_links - Función que obtiene los links de la barra lateral
 * @prop {object} user - Objeto que contiene información del usuario actual
 * @prop {array} sidebar_links - Arreglo que contiene los links de la barra lateral
*/

class SidebarNavItems extends React.Component {
    /**
 * Crea una instancia del componente SidebarNavItems.
 * @param {object} props - Propiedades para el componente.
*/
    constructor(props) {
        super(props)

        this.state = {
            navItems: Store.getSidebarItems()
        };

        this.onChange = this.onChange.bind(this);
    }
/**
 * Función que se ejecuta al montar el componente.
*/
    componentDidMount() {
        this.props.fetch_sidebar_links();
        Store.addChangeListener(this.onChange);
    }
/**
 * Función que se ejecuta al desmontar el componente.
*/
    componentWillUnmount() {
        Store.removeChangeListener(this.onChange);
    }
/**
 * Función que se ejecuta cuando ocurre un cambio en el store.
*/
    onChange() {
        this.setState({
            ...this.state,
            navItems: Store.getSidebarItems()
        });
    }

    render() {
        const {navItems: items} = this.state;
        const groups = this.props.user.groups;
        console.log(this.props);
        // console.log(this.props.sidebar_links);

        return (
            <div className="nav-wrapper">
                <Nav className="nav--no-borders flex-column">
                    {items.map((item, idx) => {
                        let has_group = false
                        let intersections = item.groups.filter(e => groups.indexOf(e) !== -1);
                        if (item.groups.length === 0 || intersections.length > 0) {
                            has_group = true;
                        }
                        return (
                            has_group ? <SidebarNavItem key={idx} item={item}/> : null
                        )
                    })}
                    <li className="nav-item"><a className="nav-link" href="http://www.naturgy-apps.mx/geovisor"
                                                target="_blank" rel="noopener noreferrer">
                        <div className="d-inline-block item-icon-wrapper"><i className="material-icons">place</i></div>
                        <span>Geovisor</span></a></li>
                    <li className="nav-item"><a className="nav-link"
                                                href="https://app.powerbi.com/groups/86cddd7e-4857-4de0-b8df-1017c01bd587/dashboards/9315e949-c66a-4a4a-8c0f-9d7e35846317"
                                                target="_blank" rel="noopener noreferrer">
                        <div className="d-inline-block item-icon-wrapper"><i className="material-icons">toc</i></div>
                        <span>PowerBI</span></a></li>
                    {this.props.sidebar_links.map((item, idx) => {
                        return (
                            <li className="nav-item"><a className="nav-link"
                                                        href={item.link}
                                                        target="_blank" rel="noopener noreferrer">
                                <div className="d-inline-block item-icon-wrapper"><i className="material-icons">{item.icon}</i>
                                </div>
                                <span>{item.text}</span></a></li>
                        )
                    })}
                </Nav>
            </div>
        )
    }
}


const mapStateToProps = state => {
    return {
        user: state.auth.user,
        sidebar_links: state.users.sidebar_links,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        fetch_sidebar_links: () => {
            dispatch(users.fetch_sidebar_links())
        },
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(SidebarNavItems);