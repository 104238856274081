import React from "react";
import {
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormSelect
} from "shards-react";

/**
 * Componente para crear un select personalizado con opciones y etiquetas personalizadas.
 * @name CustomSelect
 * @function
 * @returns {JSX.Element} - Elemento JSX que contiene dos grupos de input con etiquetas personalizadas.
*/

const CustomSelect = () => (
  <div>
    <InputGroup className="mb-3">
      <InputGroupAddon type="prepend">
        <InputGroupText>Options</InputGroupText>
      </InputGroupAddon>
      <FormSelect>
        <option>Choose</option>
        <option>...</option>
      </FormSelect>
    </InputGroup>

    <InputGroup className="mb-3">
      <FormSelect>
        <option>Choose</option>
        <option>...</option>
      </FormSelect>
      <InputGroupAddon type="append">
        <InputGroupText>Options</InputGroupText>
      </InputGroupAddon>
    </InputGroup>
  </div>
);

export default CustomSelect;

/**
 * @typedef {Object} InputGroupAddonProps
 * @property {string} type - Tipo de addon a agregar al input group.
 * @property {JSX.Element} children - Elemento hijo que contendrá el addon.
*/

/**
 * @typedef {Object} InputGroupTextProps
 * @property {string} children - Texto que se mostrará en el input group.
*/

/**
 * @typedef {Object} FormSelectProps
 * @property {JSX.Element[]} children - Elementos hijo que contendrá el select.
*/

/**
 * @typedef {Object} InputGroupProps
 * @property {string} className - Clase para dar estilo al input group.
 * @property {JSX.Element} children - Elemento hijo que contendrá el input group.
*/