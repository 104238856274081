/**
 * Objeto que contiene dos constantes para ser usadas como acciones en Redux.
 * @typedef {Object} AccionesRedux
 * @property {string} CHANGE - Acción para indicar que se ha producido un cambio.
 * @property {string} TOGGLE_SIDEBAR - Acción para indicar que se ha activado/desactivado la barra lateral.
*/

/**
 * Constante que exporta un objeto que contiene dos acciones para ser usadas en Redux.
 * @type {AccionesRedux}
*/

export default {
  CHANGE: "CHANGE",
  TOGGLE_SIDEBAR: "TOGGLE_SIDEBAR"
};
