import React, {Component} from "react";
import {Card, CardBody, CardHeader, Col} from "shards-react";

import {connect} from "react-redux";

import CustomTable from "../components/CustomTable"

/**
 * La clase Contracts extiende de Component y representa un componente que renderiza contratos.
*/
class Contracts extends Component {
/**
 * Crea una instancia de Contracts.
 * @param {object} props - Propiedades opcionales que pueden ser pasadas a Contracts.
*/
    constructor(props) {
        super(props);
/**
 * El estado de Contracts.
 * @type {object}
 * @property {string|null} error - El error que se puede producir al cargar los contratos.
 * @property {boolean} isLoaded - Indica si los contratos han sido cargados correctamente.
 * @property {Array.<Array.<string>>} items - Los contratos cargados en un array multidimensional.
*/
        this.state = {
            error: null,
            isLoaded: true,
            items: [["A", "B", "C"]]
        };
/**
 * El encabezado de Contracts. 
 * @type {Array.<{title: string, prop: string, sortable: boolean, filterable: boolean}>}
*/
        this.header = [
            { title: "Name", prop: "name", sortable: true, filterable: true },
            {
                title: "User Name",
                prop: "username",
                sortable: true,
                filterable: true
            },
            { title: "Phone", prop: "phone", sortable: true, filterable: true },
            { title: "Website", prop: "website", sortable: true, filterable: true }
        ];
    }

    render() {
        const header = [
            { title: 'Username', prop: 'username', filterable: true, sortable:true },
            { title: 'Name', prop: 'realname' },
            { title: 'Location', prop: 'location' },
            { title: 'Last Updated', prop: 'date' }
        ];
/**
 * Las etiquetas personalizadas de la paginación de Contracts.
 * @type {object}
*/
        const customLabels = {
            first: '<<',
            last: '>>',
            prev: '<',
            next: '>',
            show: 'Display',
            entries: 'rows',
            noResults: '',
            filterPlaceholder: 'Buscar',
        };
/**
 * El cuerpo de Contracts.
 * @type {Array.<object>}
*/
        const body = Array.from(new Array(57), () => {
            const rd = (Math.random() * 10).toFixed(1);

            if (rd > 0.5) {
                return {
                    username: 'i-am-billy',
                    realname: `Billy ${rd}`,
                    location: 'Mars',
                    date: 'AA'
                };
            }

            return {
                username: 'john-nhoj',
                realname: `John ${rd}`,
                location: 'Saturn',
                date: 'AA'
            };
        });
        return (

            <Col className="mb-8">
                <Card>
                    <CardHeader className="border-bottom">
                        <h6 className="m-0">Transferencias Pendientes</h6>
                    </CardHeader>
                    <CardBody className="d-flex flex-column">
                        <CustomTable
                            tableHeaders={header}
                            tableBody={body}
                            tableClass="table-striped hover responsive"
                            rowsPerPage={5}
                            initialSort={{ prop: 'username', isAscending: true }}
                            labels={customLabels}
                            filterClearButton={"!!!!!"}
                        />

                    </CardBody>
                </Card>
            </Col>

        );
    }

}
/**
 * Función que mapea el estado a las props del componente
 * @param {Object} state - Estado actual de la aplicación
 * @returns {Object} - Objeto que contiene las props mapeadas al estado
*/
const mapStateToProps = state => {
    return {
    }
};
/**
 * Función que mapea las acciones a las props del componente
 * @param {Function} dispatch - Función de redux para despachar acciones
 * @returns {Object} - Objeto que contiene las props mapeadas a las acciones
*/
const mapDispatchToProps = dispatch => {
    return {
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(Contracts);
