const initialState = [];
/**
 * Función que maneja el estado de los archivos.
 * @name files
 * @param {Array} state - Estado inicial, un array vacío.
 * @param {Object} action - Acción a realizar en el estado.
 * @returns {Array} - Nuevo estado modificado.
*/

export default function files(state=initialState, action) {
  let fileList = state.slice();

  switch (action.type) {

    case 'FETCH_FILES':
      return [...action.files];

    case 'DELETE_FILE':
      fileList.splice(action.index, 1);
      return fileList;

    case 'SHARE_FILES':
      return [...state];

    default:
      return state;
  }
}