import React, { Component } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormInput,
  FormSelect,
  Row,
} from "shards-react";

import PageTitle from "./../components/common/PageTitle";

import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { quoteSimulations } from "../actions";

/**
Componente de la página de simulación de cotización.
 * @class
 * @extends Component
 * @property {number} monthly_consumption - Consumo mensual de energía.
 * @property {number} cmd - Cargo por capacidad máxima demandada.
 * @property {number} spread - Diferencia entre el precio de venta y el costo de compra.
 * @property {number} management_cost - Costo de gestión de la energía.
 * @property {string} month - Mes de la cotización.
 * @property {string} tariff - Tarifa eléctrica seleccionada.
 * @property {string} region - Región seleccionada.
 * @property {string} index - Índice de referencia seleccionado.
 * @property {array} additional_fields - Campos adicionales de la cotización.
 * @property {number|null} customer_id - ID del cliente asociado a la cotización.
 * @property {boolean} canon - Indica si se ha seleccionado el canon.
 * @property {number} canon_meters - Metros de la propiedad asociada al canon.
 * @property {boolean} saving - Indica si se está guardando la cotización.
 * @property {function} update_additional_field - Actualiza el valor de un campo adicional.
 * @property {function} numberFormat - Formatea un número en formato local (en-US).
 * @property {function} submitSimulation - Envía la cotización a la API.
 * @property {function} submitCanon - Envía los datos del canon a la API.
 * @property {function} deleteCanon - Elimina el canon asociado a la cotización.
 * @property {function} createCanon - Crea un nuevo canon asociado a la cotización.
*/

class Simulation extends Component {
  state = {
    monthly_consumption: 0,
    cmd: 0,
    spread: 0,
    management_cost: 0,
    month: "",
    tariff: "",
    region: "",
    index: "",
    additional_fields: [],
    customer_id: null,
    canon: false,
    canon_meters: 0,
    saving: false,
  };

  update_additional_field(e, index, value) {
    let additional_fields = this.state.additional_fields;
    additional_fields[index].amount = value;
    this.setState({ poll_values: additional_fields });
  }

  componentDidMount() {
    let simulationId = this.props.match.params.id;
    this.props.fetchQuoteSimulation(simulationId).then(() => {
      let simulation = this.props.quoteSimulations.simulation;
      let canon = this.props.quoteSimulations.canon;
      let canon_meters = 0;
      let canon_state = false;
      if (canon) {
        canon_state = true;
        canon_meters = canon.meters;
      }
      let additional_fields = [];
      simulation.additional_fields.map(
        (field, index) => (additional_fields[index] = field)
      );
      this.setState({
        tariff: simulation.tariff,
        month: simulation.month,
        region: simulation.region,
        cmd: simulation.cmd,
        monthly_consumption: simulation.monthly_consumption,
        spread: simulation.spread,
        management_cost: simulation.management_cost,
        index: simulation.index,
        additional_fields: additional_fields,
        customer_id: simulation.customer_id,
        canon: canon_state,
        canon_meters: canon_meters,
      });
    });
    this.props.fetchOptions();
  }

  numberFormat = (n) => {
    if (n !== "en-US") {
      return n.toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    }
    return "-";
  };

  submitSimulation = (e) => {
    e.preventDefault();
    if (this.state.saving) {
      return;
    }

    this.setState({ saving: true });

    let values = {
      monthly_consumption: this.state.monthly_consumption,
      cmd: this.state.cmd,
      month: this.state.month,
      tariff: this.state.tariff,
      region: this.state.region,
      spread: this.state.spread,
      management_cost: this.state.management_cost,
      index: this.state.index,
      // additional_fields: [{code: "os", amount: 1000, name: "One Shot"}],
      additional_fields: this.state.additional_fields,
    };
    this.props.updateSimulation(this.props.match.params.id, values).then(() => {
      this.setState({ saving: false });
    });
  };

  submitCanon = (e) => {
    e.preventDefault();

    let canon = this.props.quoteSimulations.canon;

    let canon_values = {
      meters: this.state.canon_meters,
    };
    console.log("Update Canon");
    this.props.updateCanon(canon.id, canon_values);
  };

  deleteCanon = (e) => {
    e.preventDefault();
    let canon = this.props.quoteSimulations.canon;
    this.props.deleteCanon(canon.id).then(() => {
      this.setState({
        canon: false,
        canon_meters: 0,
      });
    });
  };

  createCanon = (e) => {
    e.preventDefault();
    let simulationId = this.props.match.params.id;
    this.props.createCanon(simulationId).then(() => {
      let canon = this.props.quoteSimulations.canon;
      this.setState({
        canon: true,
        canon_meters: canon.meters,
      });
    });
  };

  componentWillUnmount() {}

  render() {
    let simulation = null;
    let simulation_options = this.props.quoteSimulations.simulation_options;
    let content = "";
    simulation = this.props.quoteSimulations.simulation;
    let type = simulation.customer_type;
    let customer_name = "";
    let spread_name = "Spread";
    // const additional_fields = this.state.additional_fields;
    const { additional_fields, canon } = this.state;
    const canon_data = this.props.quoteSimulations.canon;
    const sim_id = this.props.match.params.id;
    if (this.state.index === "PEMEX") {
      spread_name = "Descuento";
    }
    const quote_additional_fields =
      this.props.quoteSimulations.simulation.additional_fields;
    const month_formatter = new Intl.DateTimeFormat("es", { month: "long" });

    let month_note = `* El precio de adquisición del mes de ${month_formatter.format(
      new Date()
    )} es estimado`;

    if (Object.entries(simulation).length > 0) {
      customer_name = simulation.customer_name;
      content = (
        <Col lg="8" className="">
          {canon === true && (
            <Card small>
              <CardBody>
                <h6>Canon de Conexión</h6>
                <div className="table-responsive">
                  <table className="table simulation-results">
                    <thead>
                      <tr>
                        <th>[m] Totales</th>
                        <th>[m] cxn estándar</th>
                        <th>[m] cxn no estándar</th>
                        <th>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{canon_data && canon_data.meters}</td>
                        <td>{canon_data && canon_data.standard}</td>
                        <td>{canon_data && canon_data.non_standard}</td>
                        <td>
                          $ {canon_data && this.numberFormat(canon_data.total)}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </CardBody>
            </Card>
          )}
          <Card small className="my-4">
            <CardBody className="d-flex flex-column">
              <Row>
                <Col lg={12} className="note">
                  {month_note}
                </Col>
                <Col lg={12}>
                  <div className="table-responsive">
                    <table className="table simulation-results">
                      <thead className="bg-light">
                        <tr>
                          <th>Simulación de Factura</th>
                          <th>Gj</th>
                          <th>Unitario</th>
                          <th>Subtotal</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Cargo por Servicio</td>
                          <td>1</td>
                          <td>
                            {simulation.region_tariff_price.toLocaleString(
                              "en-US",
                              {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }
                            )}
                          </td>
                          <td>
                            {simulation.region_tariff_price.toLocaleString(
                              "en-US",
                              {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }
                            )}
                          </td>
                        </tr>
                        {simulation.use_block.map(
                          (block, id) =>
                            block.consumption > 0 && (
                              <tr key={`use_block_${id}`}>
                                <td>
                                  D Simple cargo por uso{" "}
                                  {block.block_pricing.block.name}
                                </td>
                                <td>
                                  {block.consumption.toLocaleString(
                                    "en-US",
                                    {}
                                  )}
                                </td>
                                <td>
                                  {block.block_pricing.price.toLocaleString(
                                    "en-US",
                                    {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }
                                  )}
                                </td>
                                <td>
                                  {block.total.toLocaleString("en-US", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}
                                </td>
                              </tr>
                            )
                        )}
                        {simulation.cap_block.map(
                          (block, id) =>
                            block.consumption > 0 && (
                              <tr key={`use_block_${id}`}>
                                <td>
                                  D Simple cargo por capacidad{" "}
                                  {block.block_pricing.block.name}
                                </td>
                                <td>
                                  {block.consumption.toLocaleString(
                                    "en-US",
                                    {}
                                  )}
                                </td>
                                <td>
                                  {block.block_pricing.price.toLocaleString(
                                    "en-US",
                                    {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }
                                  )}
                                </td>
                                <td>
                                  {block.total.toLocaleString("en-US", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}
                                </td>
                              </tr>
                            )
                        )}
                        {simulation.com_block.map(
                          (block, id) =>
                            block.consumption > 0 && (
                              <tr key={`use_block_${id}`}>
                                <td>
                                  Distribución con Comercialización {simulation.com_block.length > 1 ? ` ${block.block_pricing.block.name}` : ''}
                                </td>
                                <td>
                                  {block.consumption.toLocaleString(
                                    "en-US",
                                    {}
                                  )}
                                </td>
                                <td>
                                  {block.block_pricing.price.toLocaleString(
                                    "en-US",
                                    {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }
                                  )}
                                </td>
                                <td>
                                  {block.total.toLocaleString("en-US", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}
                                </td>
                              </tr>
                            )
                        )}
                        <tr>
                          <td>Precio de Adquisición</td>
                          <td>
                            {simulation.monthly_consumption.toLocaleString(
                              "en-US",
                              {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }
                            )}
                          </td>
                          <td>
                            {simulation.acquisition_price.toLocaleString(
                              "en-US",
                              {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }
                            )}
                          </td>
                          <td>
                            {simulation.acquisition_total.toLocaleString(
                              "en-US",
                              {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>Ajuste Operativo</td>
                          <td>
                            {simulation.monthly_consumption.toLocaleString(
                              "en-US",
                              {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }
                            )}
                          </td>
                          <td>
                            {simulation.operational_adjustment.toLocaleString(
                              "en-US",
                              {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }
                            )}
                          </td>
                          <td>
                            {simulation.operational_adjustment_total.toLocaleString(
                              "en-US",
                              {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }
                            )}
                          </td>
                        </tr>
                        {quote_additional_fields.map((field, index) => (
                          <tr key={`quote_additional_fields_${index}`}>
                            <td>{field.readable_name}</td>
                            <td></td>
                            <td></td>
                            <td>
                              {field.amount.toLocaleString("en-US", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            </td>
                          </tr>
                        ))}
                        <tr className="top-em">
                          <td>Total factura sin IVA</td>
                          <td></td>
                          <td></td>
                          <td>
                            {simulation.quote_total.toLocaleString("en-US", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </td>
                        </tr>
                        <tr className="">
                          <td>Total factura con IVA</td>
                          <td></td>
                          <td></td>
                          <td>
                            {simulation.quote_total_tax.toLocaleString(
                              "en-US",
                              {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>

          <Card small className="my-4">
            <CardBody>
              <h6>Comparativo vs Combustibles Alternos</h6>
              <div className="table-responsive">
                <table className="table simulation-results">
                  <thead>
                    <tr>
                      <th>Combustible</th>
                      <th>Subtotal</th>
                      <th>Competitividad</th>
                      <th>Ahorro</th>
                    </tr>
                  </thead>
                  <tbody>
                    {simulation.alternate_fuels.map((fuel, id) => (
                      <tr key={`fuel_${id}`}>
                        <td>{fuel.name}</td>
                        <td>{this.numberFormat(fuel.value)}</td>
                        <td>{this.numberFormat(fuel.competitivity)}%</td>
                        <td>{this.numberFormat(fuel.savings)}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </CardBody>
          </Card>
        </Col>
      );
    }

    let container = (
      <>
        <Container fluid className="main-content-container px-4">
          {/* Page Header */}

          <Row noGutters className="page-header py-4">
            <PageTitle
              title={`Simulación de Factura para ${customer_name}`}
              className="text-sm-left mb-3 col-sm-12"
            />
          </Row>
          <Row>
            <Col className="my-2">
              <Link to={`/clientes/${this.state.customer_id}`}>
                <Button className="my-4 my-md-2 col-md-12 col-lg-2">
                  Regresar
                </Button>
              </Link>
              <Link
                to={`/pdf/${sim_id}/download/`}
                target="_blank"
                rel="noopener noreferrer"
                download
              >
                <Button className="float-right col-md-12 col-lg-2">
                  Descargar
                </Button>
              </Link>
            </Col>
          </Row>

          <Row>
            {/* New Draft */}
            {content}

            <Col lg="4" className="mb-8">
              <Row>
                <Col md="12 my-4 my-lg-0">
                  <Card small className="">
                    {/* Card Header */}

                    <CardBody className="d-flex flex-column">
                      <Row>
                        {canon === true ? (
                          <Col>
                            <Form onSubmit={this.submitCanon}>
                              <Button type="submit" className="my-2 col-lg-12">
                                Actualizar
                              </Button>

                              <table className="table table-sm simulation-data">
                                <tbody>
                                  <tr>
                                    <td>[m] Totales</td>
                                    <td>
                                      <FormInput
                                        id="canon-meters"
                                        type="number"
                                        value={this.state.canon_meters}
                                        onChange={(e) =>
                                          this.setState({
                                            canon_meters: e.target.value,
                                          })
                                        }
                                        required
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>[m] cxn estándar</td>
                                    <td>{canon_data && canon_data.standard}</td>
                                  </tr>
                                  <tr>
                                    <td>[m] cxn no estándar</td>
                                    <td>
                                      {canon_data && canon_data.non_standard}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Total</td>
                                    <td>
                                      {canon_data &&
                                        this.numberFormat(canon_data.total)}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>

                              <Button
                                className="my-2 col-lg-12"
                                theme="danger"
                                onClick={this.deleteCanon}
                              >
                                Eliminar Canon
                              </Button>
                            </Form>
                          </Col>
                        ) : (
                          <Col>
                            <Button
                              className="my-2 col-lg-12"
                              onClick={this.createCanon}
                            >
                              Agergar Canon
                            </Button>
                          </Col>
                        )}
                      </Row>
                    </CardBody>
                  </Card>
                </Col>

                <Col md="12">
                  <Card small className="my-4">
                    {/* Card Header */}

                    <CardBody className="d-flex flex-column">
                      <Row>
                        <Col>
                          <Form onSubmit={this.submitSimulation}>
                            <Button
                              type="submit"
                              className="my-2 col-lg-12"
                              disabled={this.state.saving}
                            >
                              Actualizar
                            </Button>

                            <table className="table table-sm simulation-data">
                              <tbody>
                                <tr>
                                  <td>
                                    <span className="ng-blue">Mes</span>
                                  </td>
                                  <td>
                                    <FormSelect
                                      value={this.state.month}
                                      onChange={(e) =>
                                        this.setState({ month: e.target.value })
                                      }
                                    >
                                      <option value="">---</option>
                                      {simulation_options.months.map(
                                        (tariff, id) => (
                                          <option key={id} value={tariff[0]}>
                                            {tariff[1]}
                                          </option>
                                        )
                                      )}
                                    </FormSelect>
                                  </td>
                                </tr>
                                <tr>
                                  <td>Zona</td>
                                  <td>
                                    <FormSelect
                                      value={this.state.region}
                                      onChange={(e) =>
                                        this.setState({
                                          region: e.target.value,
                                        })
                                      }
                                    >
                                      <option value="">---</option>
                                      {simulation_options.regions.map(
                                        (tariff, id) => (
                                          <option key={id} value={tariff[0]}>
                                            {tariff[1]}
                                          </option>
                                        )
                                      )}
                                    </FormSelect>
                                  </td>
                                </tr>
                                <tr>
                                  <td>Tipo de tarifa</td>
                                  <td>
                                    <FormSelect
                                      value={this.state.tariff}
                                      onChange={(e) =>
                                        this.setState({
                                          tariff: e.target.value,
                                        })
                                      }
                                    >
                                      <option value="">---</option>
                                      {simulation_options.tariffs.map(
                                        (tariff, id) => (
                                          <option key={id} value={tariff[0]}>
                                            {tariff[1]}
                                          </option>
                                        )
                                      )}
                                    </FormSelect>
                                  </td>
                                </tr>
                                <tr>
                                  <td>Consumo mensual</td>
                                  <td>
                                    <FormInput
                                      id="contract-id"
                                      type="number"
                                      value={this.state.monthly_consumption}
                                      onChange={(e) =>
                                        this.setState({
                                          monthly_consumption: e.target.value,
                                        })
                                      }
                                      required
                                    />
                                  </td>
                                </tr>
                                {type === "g" && (
                                  <tr>
                                    <td>CMD</td>
                                    <td>
                                      <FormInput
                                        id="contract-id"
                                        type="number"
                                        value={this.state.cmd}
                                        onChange={(e) =>
                                          this.setState({ cmd: e.target.value })
                                        }
                                        required
                                      />
                                    </td>
                                  </tr>
                                )}
                                {type === "g" && (
                                  <tr>
                                    <td>Capacidad Mensual</td>
                                    <td>
                                      {simulation.monthly_capacity.toLocaleString(
                                        "en-US",
                                        {
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2,
                                        }
                                      )}
                                    </td>
                                  </tr>
                                )}

                                {additional_fields.map((field, index) => (
                                  <tr key={`additional_fields_${index}`}>
                                    <td>{field.readable_name}</td>
                                    <td>
                                      <FormInput
                                        id="contract-id"
                                        type="number"
                                        value={
                                          this.state.additional_fields[index][
                                            "amount"
                                          ]
                                        }
                                        onChange={(e) =>
                                          this.update_additional_field(
                                            e,
                                            index,
                                            e.target.value
                                          )
                                        }
                                        required
                                      />
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </Form>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>

                {type === "g" && (
                  <Col lg="12" className="">
                    <Card small className="">
                      <CardBody className="d-flex flex-column">
                        <Row>
                          <Col>
                            <Form onSubmit={this.submitSimulation}>
                              <table className="table table-sm simulation-data">
                                <tbody>
                                  <tr>
                                    <td>Índice</td>
                                    <td>
                                      <FormSelect
                                        value={this.state.index}
                                        onChange={(e) =>
                                          this.setState({
                                            index: e.target.value,
                                          })
                                        }
                                      >
                                        <option value="">---</option>
                                        {simulation_options.indexes.map(
                                          (tariff, id) => (
                                            <option key={id} value={tariff[0]}>
                                              {tariff[1]}
                                            </option>
                                          )
                                        )}
                                      </FormSelect>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>{spread_name}</td>
                                    <td>
                                      <FormInput
                                        id="contract-id"
                                        type="number"
                                        value={this.state.spread}
                                        onChange={(e) =>
                                          this.setState({
                                            spread: e.target.value,
                                          })
                                        }
                                        required
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Molécula</td>
                                    <td>
                                      {this.numberFormat(simulation.molecule)}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>T SISTRANGAS</td>
                                    <td>
                                      {this.numberFormat(
                                        simulation.t_sistrangas
                                      )}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>T. Tans. Priv.</td>
                                    <td>{this.numberFormat(simulation.ttp)}</td>
                                  </tr>
                                  <tr>
                                    <td>Gas Fuel</td>
                                    <td>
                                      {this.numberFormat(simulation.gas_fuel)}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>C. gestión [MXN]</td>
                                    <td>
                                      <FormInput
                                        id="contract-id"
                                        type="number"
                                        value={this.state.management_cost}
                                        onChange={(e) =>
                                          this.setState({
                                            management_cost: e.target.value,
                                          })
                                        }
                                        required
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Total</td>
                                    <td>
                                      {this.numberFormat(
                                        simulation.acquisition_price
                                      )}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </Form>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                )}
              </Row>
            </Col>
          </Row>
        </Container>
      </>
    );
    return container;
  }
}
/**
 * Función que mapea el estado a las props del componente
 * @param {Object} state - Estado actual de la aplicación
 * @returns {Object} - Objeto que contiene las props mapeadas al estado
 */
const mapStateToProps = (state) => {
  return {
    contracts: state.contracts,
    quoteSimulations: state.quoteSimulations,
  };
};
/**
 * Función que mapea las acciones a las props del componente
 * @param {Function} dispatch - Función de redux para despachar acciones
 * @returns {Object} - Objeto que contiene las props mapeadas a las acciones
 */
const mapDispatchToProps = (dispatch) => {
  return {
    fetchQuoteSimulation: (simulationId) => {
      return dispatch(quoteSimulations.fetchSimulation(simulationId));
    },
    fetchOptions: () => {
      dispatch(quoteSimulations.fetchOptions());
    },
    updateSimulation: (simulation_id, values) => {
      return dispatch(quoteSimulations.updateSimulation(simulation_id, values));
    },
    deleteCanon: (canon_id) => {
      return dispatch(quoteSimulations.deleteCanon(canon_id));
    },
    createCanon: (simulation_id) => {
      return dispatch(quoteSimulations.createCanon(simulation_id));
    },
    updateCanon: (canon_id, data) => {
      dispatch(quoteSimulations.updateCannon(canon_id, data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Simulation);
