import React, {Component} from "react";
import {Button, Card, CardBody, CardHeader, Col, Container, Row} from "shards-react";

import PageTitle from "./../components/common/PageTitle";

import {connect} from "react-redux";
import {customersV2} from "../actions";
import CurrentStatus from "../components/customer/CurrentStatus";


class CustomerDetail2 extends Component {

    constructor(props) {
        super(props);
        this.state = {};

    }

    componentDidMount() {
        let id = this.props.match.params.id;
        this.props.fetch_customer(id);
    }

    redirectCustomerPrevious = () => {
        const {customer} = this.props;
        console.log('REDIRECT')
        this.props.fetch_customer(customer.previous);
        this.props.history.push(`/clients/${customer.previous}`)
    }

    redirectCustomerNext = () => {
        const {customer} = this.props;
        console.log('REDIRECT')
        this.props.fetch_customer(customer.next);
        this.props.history.push(`/clients/${customer.next}`)
    }

    render() {
        const {customer, user} = this.props;
        let current_status = null;
        let status_list = [];

        if (customer === null) {
            return <h3>Cargando...</h3>
        }
        console.log('CUSTOMER', customer)

        current_status = customer.status_tree[customer.current_status.priority]
        // let count = Object.keys(customer.status_tree).length
        for (let i = 0; i < customer.current_status.priority; i++) {
            status_list.push(customer.status_tree[i])
        }


        return <Container fluid className="main-content-container px-4">
            <Row noGutters className="page-header py-4">
                <PageTitle title={customer.name} className="text-sm-left mb-3"/>
            </Row>
            <Row noGutters className="page-header py-4">
                <PageTitle title={customer.contract_id} className="text-sm-left mb-3"/>
            </Row>
            <Row>
                {customer.has_previous &&
                    <Col md={2} className={'my-2'}>
                        <Button onClick={this.redirectCustomerPrevious}>Folio Anterior</Button>
                    </Col>
                }
                {customer.has_next &&
                    <Col md={2} className={'my-2'}>
                        <Button onClick={this.redirectCustomerNext}>Siguiente Folio</Button>
                    </Col>
                }
            </Row>
            <Row>
                <Col lg="12" className="mb-8 my-4">
                    <Card small className="">
                        <CardHeader className="border-bottom">
                            <i className="icon icon-altacliente header-icon"></i>
                            <h6 className="m-0">Estado Actual: {current_status.name}</h6>
                        </CardHeader>
                        <CardBody className="d-flex flex-column">
                            <CurrentStatus status={current_status} customer={customer.id} specific={user.specific}
                                           groups={user.groups} rejected={customer.rejected}/>
                        </CardBody>
                    </Card>
                </Col>

                {!user.specific && status_list.map((status, key) => (
                    <Col lg="12" className="mb-8 my-4" key={key}>
                        <Card small className="">
                            <CardHeader className="border-bottom">
                                <i className="icon icon-altacliente header-icon"></i>
                                <h6 className="m-0">{status.name}</h6>
                            </CardHeader>
                            <CardBody className="d-flex flex-column">
                                <CurrentStatus status={status} customer={customer.id} user={user}/>
                            </CardBody>
                        </Card>
                    </Col>

                ))}

            </Row>

        </Container>;

    }

}
/**
 * Función que mapea el estado a las props del componente
 * @param {Object} state - Estado actual de la aplicación
 * @returns {Object} - Objeto que contiene las props mapeadas al estado
*/
const mapStateToProps = state => {
    return {
        customer: state.customersV2.detail,
        user: state.auth.user,
    }
};
/**
 * Función que mapea las acciones a las props del componente
 * @param {Function} dispatch - Función de redux para despachar acciones
 * @returns {Object} - Objeto que contiene las props mapeadas a las acciones
*/
const mapDispatchToProps = dispatch => {
    return {
        fetch_customer: (id) => {
            dispatch(customersV2.fetch_customer(id))
        },
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(CustomerDetail2);
