import React, {Component} from "react";
import {applyMiddleware, createStore} from "redux";
import thunk from "redux-thunk";

import "bootstrap/dist/css/bootstrap.min.css";
import "./shards-dashboard/styles/shards-dashboards.1.1.0.min.css";

import ponyApp from "./reducers";
import {Provider} from "react-redux";
import {loadUser} from "./actions/auth";

import "./App.css"
import "./assets/ng_font/styles.css"
import Routes from "./components/Routes";

/**
 * Crea una tienda (store) que contiene el estado de la aplicación y recibe el "ponyApp" como argumento.
 * Además, se utiliza el middleware "thunk" para manejar las acciones asíncronas.
 * @type {Store}
*/

let store = createStore(ponyApp, applyMiddleware(thunk));

/**
 * Clase que representa la aplicación principal.
 * @class App
 * @extends Component
*/
class App extends Component {
/**
 * Método que se ejecuta después de que el componente se monta.
 * Se utiliza para enviar una acción de carga de usuario (loadUser) a la tienda.
 * @memberof App
 * @method componentDidMount
*/
    componentDidMount() {
        store.dispatch(loadUser());
    }
/**
 * Método que renderiza el componente de la aplicación.
 * @memberof App
 * @method render
 * @returns {ReactElement} Componente de la aplicación envuelto en el Provider de Redux.
*/
    render () {
        return (
            <Provider store={store}>
                <Routes />
            </Provider>
        )
    }
}

export default App;