import axios from "axios";

/**
 * Crea un nuevo cliente en la base de datos.
 * @name create_customer
 * @param {Object} data - Objeto que contiene la información del nuevo cliente a crear.
 * @param {string} data.name - Nombre del nuevo cliente.
 * @param {string} data.email - Correo electrónico del nuevo cliente.
 * @param {string} data.phone - Número de teléfono del nuevo cliente.
 * @returns {Promise<Object>} - Una promesa que resuelve en un objeto que contiene información del nuevo cliente.
 * @throws {Error} - Lanza un error si hay un problema en la solicitud.
*/

export const create_customer = (data) => {
    return (dispatch, getState) => {
        let headers = {"Content-Type": "application/json"};
        let {token} = getState().auth;

        if (token) {
            headers["Authorization"] = `Token ${token}`;
        }

        return axios.post(`/api/v2/customers/`, data, {headers}).then(res => {
            return dispatch({type: 'CREATE_CUSTOMER', customer: res.data})
        }).catch(err => {
            console.log('ERROR');
            console.log(err.response);
            if (err.response.status === 401) {
                let error = {errors: 'Sesión expirada, inicie sesión nuevamente'};
                dispatch({type: "AUTHENTICATION_ERROR", data: error});
            }
        })
    }
};

/**
 * Función que realiza una petición GET a la API para obtener los clientes.
 * @function
 * @name fetch_customers
 * @returns {Promise} Una promesa que se resuelve con un objeto de respuesta si la solicitud es exitosa, o rechaza con un error si la solicitud falla.
 * @throws {Error} Si se produce un error al realizar la solicitud.
*/

export const fetch_customers = () => {
    return (dispatch, getState) => {
        let headers = {"Content-Type": "application/json"};
        let {token} = getState().auth;

        if (token) {
            headers["Authorization"] = `Token ${token}`;
        }

        return axios.get("/api/v2/customers/", {headers}).then(res => {
            return dispatch({type: 'FETCH_CUSTOMERS_V2', customers: res.data})
        }).catch(err => {
            console.log('ERROR');
            console.log(err);
            console.log(err.response.status);
            if (err.response.status === 401) {
                let error = {errors: 'Sesión expirada, inicie sesión nuevamente'};
                dispatch({type: "AUTHENTICATION_ERROR", data: error});
            }
        })
    }
};

/**
 * Función que realiza una petición GET a la API para obtener el estado de los clientes.
 * @function
 * @name fetch_customers_status
 * @returns {Promise} Una promesa que se resuelve con un objeto de respuesta si la solicitud es exitosa, o rechaza con un error si la solicitud falla.
 * @throws {Error} Si se produce un error al realizar la solicitud.
*/

export const fetch_customers_status = () => {
    return (dispatch, getState) => {
        let headers = {"Content-Type": "application/json"};
        let {token} = getState().auth;

        if (token) {
            headers["Authorization"] = `Token ${token}`;
        }

        return axios.get("/api/v2/status_company/", {headers}).then(res => {
            return dispatch({type: 'FETCH_CUSTOMERS_V2_STATUS', customers: res.data})
        }).catch(err => {
            console.log('ERROR');
            console.log(err);
            console.log(err.response.status);
            if (err.response.status === 401) {
                let error = {errors: 'Sesión expirada, inicie sesión nuevamente'};
                dispatch({type: "AUTHENTICATION_ERROR", data: error});
            }
        })
    }
};

/**
 * Función que realiza una petición GET a la API para obtener todos los clientes que coinciden con una cadena de búsqueda.
 * @function 
 * @name fetch_all_customers
 * @param {string} search - La cadena de búsqueda para encontrar clientes.
 * @returns {Promise} Una promesa que se resuelve con un objeto de respuesta si la solicitud es exitosa, o rechaza con un error si la solicitud falla.
 * @throws {Error} Si se produce un error al realizar la solicitud.
*/

export const fetch_all_customers = (search) => {
    return (dispatch, getState) => {
        let headers = {"Content-Type": "application/json"};
        let {token} = getState().auth;

        if (token) {
            headers["Authorization"] = `Token ${token}`;
        }

        return axios.get(`/api/v2/customers_all/?search=${search}`, {headers}).then(res => {
            return dispatch({type: 'FETCH_ALL_CUSTOMERS', customers: res.data})
        }).catch(err => {
            console.log('ERROR');
            console.log(err);
            console.log(err.response.status);
            if (err.response.status === 401) {
                let error = {errors: 'Sesión expirada, inicie sesión nuevamente'};
                dispatch({type: "AUTHENTICATION_ERROR", data: error});
            }
        })
    }
};

/**
 * Función que realiza una petición GET a la API para obtener un cliente específico.
 * @function
 * @name fetch_customer
 * @param {number} id - El ID del cliente a buscar.
 * @returns {Promise} Una promesa que se resuelve con un objeto de respuesta si la solicitud es exitosa, o rechaza con un error si la solicitud falla.
 * @throws {Error} Si se produce un error al realizar la solicitud.
*/

export const fetch_customer = (id) => {
    return (dispatch, getState) => {
        let headers = {"Content-Type": "application/json"};
        let {token} = getState().auth;

        if (token) {
            headers["Authorization"] = `Token ${token}`;
        }

        return axios.get(`/api/v2/customers/${id}/`, {headers}).then(res => {
            return dispatch({type: 'FETCH_CUSTOMER_V2', customer: res.data})
        }).catch(err => {
            console.log('ERROR');
            console.log(err.response);
            if (err.response.status === 401) {
                let error = {errors: 'Sesión expirada, inicie sesión nuevamente'};
                dispatch({type: "AUTHENTICATION_ERROR", data: error});
            }
        })
    }
};

/**
 * Actualiza el almacenamiento de un cliente en el servidor.
 * @param {number} id - El ID del cliente que se va a actualizar.
 * @param {File} file - El archivo que se va a enviar al servidor.
 * @param {string} type - El tipo de archivo que se está enviando al servidor.
 * @returns {Promise} Promesa que resuelve con la respuesta del servidor si la petición fue exitosa.
 * @throws {Error} Error si la petición no fue exitosa o si la sesión expiró.
*/

export const update_customer_storage = (id, file, type) => {
    return (dispatch, getState) => {
        console.log('IN UPDATE')
        let headers = {"Content-Type": "application/json"};
        let {token} = getState().auth;

        if (token) {
            headers["Authorization"] = `Token ${token}`;
        }

        let body = new FormData();
        body.append(type, file);
        console.log('HERE', type, file);

        return axios.patch(`/api/v2/customer_status_storage/${id}/`, body, {headers}).then(res => {
            return dispatch({type: 'UPDATE_CUSTOMER_STORAGE', customer_storage: res.data})
        }).catch(err => {
            console.log('ERROR');
            console.log(err);
            if (err.response.status === 401) {
                let error = {errors: 'Sesión expirada, inicie sesión nuevamente'};
                dispatch({type: "AUTHENTICATION_ERROR", data: error});
            }
        })
    }
};