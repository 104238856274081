import React, {Component} from "react";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    FormCheckbox,
    Modal,
    ModalBody,
    ModalHeader,
    Row,
} from "shards-react";

import {Link} from "react-router-dom";
import PageTitle from "./../components/common/PageTitle";
import 'react-dropzone-uploader/dist/styles.css'
import Dropzone from 'react-dropzone-uploader'
import {files} from "../actions"
import {connect} from "react-redux";
import CustomTable from "../components/CustomTable";

/**
 * Componente que muestra una lista de archivos con opciones para subir, compartir y eliminar archivos.
 * @class
 * @name Files
 * @extends React.Component
 * @property {boolean} state.open - Indica si el modal de compartir archivo está abierto o cerrado.
 * @property {string} state.current_file - Nombre del archivo seleccionado.
 * @property {Array} state.can_share_with - Lista de usuarios que pueden compartir el archivo seleccionado.
 * @param {object} props - Propiedades del componente.
 * @param {Array} props.files - Lista de archivos.
 * @param {function} props.fetchFiles - Función para obtener la lista de archivos.
 * @param {function} props.shareFile - Función para compartir un archivo.
 *@example
 * <Files files={files} fetchFiles={fetchFiles} shareFile={shareFile} />
*/

class Files extends Component {

    constructor(props) {
        super(props);
        this.state = {open: false, current_file: null, can_share_with: []};
        this.toggle = this.toggle.bind(this);
    }
/**
 * Función para abrir o cerrar el modal de compartir archivo.
 * @function
 * @name toggle
 * @param {object} data - Datos del archivo seleccionado.
 * @example
 * toggle(data);
 */
    toggle = (data) => {
        this.setState({
            open: !this.state.open
        });
    };
/**
 * Función para manejar el evento de selección de archivo.
 * @function
 * @name handleInput
 * @param {object} e - Evento de selección de archivo.
 * @example
 * handleInput(e);
 */
    handleInput(e) {
        this.setState({
            open: !this.state.open,
            current_file: e.target.value,
            can_share_with: this.props.files[e.target.value].can_share_with,
        });
    }
/**
 * Función para obtener los parámetros de subida de archivo.
 * @function
 * @name getUploadParams
 * @param {object} file - Archivo a subir.
 * @param {object} meta - Metadatos del archivo a subir.
 * @returns {object} - Objeto con la url, body y headers para subir el archivo.
 * @example
 * getUploadParams({file, meta});
 */
    getUploadParams = ({file, meta}) => {
        const body = new FormData();
        body.append('file', file);
        body.append('file_name', file.name);
        let headers = {"Authorization": ""};
        let token = localStorage.getItem('token');

        if (token) {
            headers["Authorization"] = `Token ${token}`;
        }

        return {url: '/api/files/', body, headers}
    };
/**
 * Función para manejar el cambio de estado de la subida de archivo.
 * @function
 * @name handleChangeStatus
 * @param {object} meta - Metadatos del archivo que se está subiendo.
 * @param {object} file - Archivo que se está subiendo.
 * @param {function} remove - Función para eliminar el archivo que se está subiendo.
 * @param {string} status - Estado de la subida del archivo.
 * @example
 * handleChangeStatus({meta, file, remove}, status);
 */
    handleChangeStatus = ({meta, file, remove}, status) => {
        if (status === 'done') {
            remove();
            this.props.fetchFiles();
        }
    };
/**
 * Maneja el evento de cambio de estado del checkbox que indica si un archivo se compartirá con un usuario determinado.
 * @param {Object} e - El evento del mouse que disparó la función.
 * @param {number} index - El índice del usuario dentro del arreglo can_share_with en el estado actual.
*/
    handleChecked = (e, index) => {
        e.preventDefault();

        let can_share_with = this.state.can_share_with;
        let currently_shared = [];
        let file = this.props.files[this.state.current_file];
        can_share_with[index].shared = !can_share_with[index].shared;
        this.setState({can_share_with: can_share_with});
        can_share_with.map((user, index) => {
            if (user.shared === true)
                return currently_shared.push(user.id);
            return []
        });
        this.props.shareFile(file.id, currently_shared);
    };
/**
 * Maneja el evento de envío del formulario de carga de archivos.
 * @param {Array} files - Los archivos seleccionados para cargar.
 * @param {Array} allFiles - Todos los archivos seleccionados, incluyendo aquellos que ya se están cargando.
*/
    handleSubmit = (files, allFiles) => {
        allFiles.forEach(f => f.remove());
    };
/**
 * Se ejecuta después de que el componente haya sido montado en el DOM.
 * Llama a la función fetchFiles() del objeto props.
*/
    componentDidMount() {
        this.props.fetchFiles();

    }
/**
 * Se ejecuta antes de que el componente sea eliminado del DOM.
 * Limpia cualquier intervalo creado para actualizar la lista de archivos.
*/
    componentWillUnmount() {
        clearInterval(this.getFilesInterval);
    }

    render() {
        const {open} = this.state;
        let modal_content = '';


        const customLabels = {
            first: '<<',
            last: '>>',
            prev: '<',
            next: '>',
            show: 'Display',
            entries: 'rows',
            noResults: 'No hay resultados',
            filterPlaceholder: 'Buscar',
        };


        const files_header = [
            { title: 'Archivo', prop: 'file', filterable:true},
            { title: 'Descargar', prop: 'download'},
            { title: 'Compartir', prop: 'share'},
            { title: 'Eliminar', prop: 'delete' },
        ];

        let user_id = this.props.user.id;

        const files_body = this.props.files.map((file, id) => {
            if (file.owner === user_id)
                return {
                    file: file.file_name,
                    download: <Link to={`/files/download/${file.id}/`} target='_blank' className="btn btn-primary"
                                    rel="noopener noreferrer" download>Descargar</Link>,
                    share: <Button value={id} onClick={e => this.handleInput(e, "value")}>Compartir</Button>,
                    delete: <Button onClick={() => this.props.deleteFile(id)}>Eliminar</Button>
                };
            return {
                file: file.file_name,
                download: <Link to={`/files/download/${file.id}/`} target='_blank' className="btn btn-primary"
                                rel="noopener noreferrer" download>Descargar</Link>,
                share: '---',
                delete: '---'
            }
        })

        if (this.state.current_file !== null) {
            const share_header = [
                { title: 'Compartir', prop: 'share'},
                { title: 'Nombre', prop: 'name', filterable:true},
                { title: 'Correo', prop: 'email', filterable:true},
            ];
            const share_body = this.state.can_share_with.map((user, index) => ({
                share: <FormCheckbox
                    checked={user.shared}
                    id="new-contract"
                    onChange={e => this.handleChecked(e, index)}
                    // onClick={(e) => this.setState({newContract: !this.state.newContract})}
                >
                </FormCheckbox>,
                name: user.name,
                email: user.email,
            }));
            let current_file = this.props.files[this.state.current_file];
            modal_content = <div>
                <ModalHeader>Share {current_file.file_name}</ModalHeader>
                <ModalBody>
                    <Row>
                        <div className="table-responsive">
                            <Col lg="12">
                                <CustomTable
                                    tableHeaders={share_header}
                                    tableBody={share_body}
                                    tableClass="table-striped hover responsive"
                                    rowsPerPage={5}
                                    labels={customLabels}
                                    // currentPage={2}
                                    on
                                />
                            </Col>
                        </div>
                    </Row>
                </ModalBody>
            </div>;
        }

        return (
            <Container fluid className="main-content-container px-4">
                {/* Page Header */}
                <Row noGutters className="page-header py-4">
                    <PageTitle title="Archivos" subtitle="" className="text-sm-left mb-3"/>
                </Row>

                <Row>
                    {/* New Draft */}
                    <Col lg="12" className="mb-8">
                        <Card small className="h-100">
                            {/* Card Header */}
                            <CardHeader className="border-bottom">
                                {/*<i className="material-icons header-icon">folder</i>*/}
                                <i className="icon icon-repositorio-personal header-icon"></i>
                                <h6 className="m-0">Archivos Cargados</h6>
                            </CardHeader>

                            <CardBody className="d-flex flex-column">

                                <Dropzone
                                    getUploadParams={this.getUploadParams}
                                    onChangeStatus={this.handleChangeStatus}
                                    onSubmit={this.handleSubmit}
                                    inputContent="Arrastra archivos o da clic para buscar"
                                />

                                <i className="material-icons my-1" onClick={this.props.fetchFiles}>cached</i>

                                <Row>
                                    <div className="table-responsive">
                                        <Col lg="12">
                                            <CustomTable
                                                tableHeaders={files_header}
                                                tableBody={files_body}
                                                tableClass="table-striped hover responsive"
                                                rowsPerPage={5}
                                                labels={customLabels}
                                            />
                                        </Col>
                                    </div>
                                </Row>
                                {/*{files_table}*/}

                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <Modal open={open} toggle={this.toggle} size="lg">
                    {modal_content}
                </Modal>

            </Container>
        )
    }

}
/**
 * Función que mapea el estado a las props del componente
 * @param {Object} state - Estado actual de la aplicación
 * @returns {Object} - Objeto que contiene las props mapeadas al estado
*/
const mapStateToProps = state => {
    return {
        files: state.files,
        user: state.auth.user,
    }
};
/**
 * Función que mapea las acciones a las props del componente
 * @param {Function} dispatch - Función de redux para despachar acciones
 * @returns {Object} - Objeto que contiene las props mapeadas a las acciones
*/
const mapDispatchToProps = dispatch => {
    return {
        fetchFiles: () => {
            dispatch(files.fetchFiles());
        },
        deleteFile: (id) => {
            dispatch(files.deleteFile(id));
        },
        shareFile: (id, shared_with) => {
            dispatch(files.shareFile(id, shared_with));
        }
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(Files);
