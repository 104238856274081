import React, {Component} from "react";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Form, FormFeedback,
    FormInput,
    FormSelect,
    Modal,
    ModalBody,
    ModalHeader,
    Row
} from "shards-react";

import {connect} from "react-redux";
import {users} from "../actions";
import CustomTable from "../components/CustomTable";

/**
 * Componente para la administración de usuarios en el panel de control.
 * @name AdminUsers
 * @class
 * @extends Component
*/

class AdminUsers extends Component {
/**
 * Constructor del componente. Establece el estado inicial con variables necesarias.
 * @constructor
 * @param {Object} props - Propiedades del componente.
*/
    constructor(props) {
        super(props);
        this.state = {
            filtered_users: [],
            new_user_open: false,
            new_email: '',
            new_password_1: '',
            new_password_2: '',
            new_role: '1',
            new_section: '',
            new_first_name: '',
            new_last_name: '',
            edit_id: null,
            edit_role: '',
            edit_section: '',
            edit_first_name: '',
            edit_last_name: '',
            edit_active: true,
            edit_user_open: false,
            password_error: false,
            password_error_message: '',
        };
        this.toggle = this.toggle.bind(this);
        this.toggle_edit = this.toggle_edit.bind(this);
    }
/**
 * Función que se encarga de abrir y cerrar el formulario de nuevo usuario.
 * @function
*/
    toggle = () => {
        this.setState({
            new_user_open: !this.state.new_user_open
        });
    };
/**
 * Función que se encarga de abrir y cerrar el formulario de edición de usuario.
 * @function
 * @param {Object} e - Evento del botón que disparó la función.
*/
    toggle_edit = (e) => {
        if (e !== undefined) {
            let user = this.props.users.list[e.target.value];
            this.setState({
                edit_id: user.id,
                edit_role: user.role,
                edit_section: user.section,
                edit_first_name: user.first_name,
                edit_last_name: user.last_name,
                edit_user_open: !this.state.edit_user_open,
            });
        } else {
            this.setState({
                edit_id: null,
                edit_role: '',
                edit_section: '',
                edit_first_name: '',
                edit_last_name: '',
                edit_user_open: !this.state.edit_user_open,
            })
        }
    };
/**
 * Función que se encarga de limpiar el formulario de nuevo usuario.
 * @function
*/
    clear_new_user_form = () => {
        this.setState({
            new_user_open: false,
            new_email: '',
            new_password_1: '',
            new_password_2: '',
            new_role: '1',
            new_section: '',
            new_first_name: '',
            new_last_name: '',
            edit_id: null,
            edit_role: '',
            edit_section: '',
            edit_first_name: '',
            edit_last_name: '',
            edit_user_open: false,
            password_error: false,
            password_error_message: '',
        })

    };
/**
 * Función que se encarga de enviar la información del nuevo usuario al servidor para crearlo.
 * @function
 * @param {Object} e - Evento del botón que disparó la función.
*/
    submit_new_user = (e) => {
        e.preventDefault();
        this.setState({password_error: false, password_error_message: ''});
        if (this.state.new_password_1 === this.state.new_password_2) {
            let user_data = {
                "username": this.state.new_email,
                "password": this.state.new_password_1,
                "role": this.state.new_role,
                "section": this.state.new_section,
                "first_name": this.state.new_first_name,
                "last_name": this.state.new_last_name,
            };
            this.props.create_user(user_data).then(this.clear_new_user_form);
        } else {
            this.setState({
                password_error: true,
                password_error_message: 'Las contraseñas no coinciden',
            })
        }
    }
/**
 * Función para editar un usuario.
 * @param {Event} e - El evento de click.
 * @return {void}
*/
    edit_user = (e) => {
        e.preventDefault();
        let user_data = {
            "role": this.state.edit_role,
            "section": this.state.edit_section,
            "is_active": this.state.edit_active,
            "first_name": this.state.edit_first_name,
            "last_name": this.state.edit_last_name,
        };
        this.props.update_user(this.state.edit_id, user_data).then(this.props.fetch_users).then(this.clear_new_user_form)
    };
/**
 * Función que se ejecuta después de que el componente haya sido montado.
 * @return {void}
*/
    componentDidMount() {
        this.props.fetch_users();
        this.props.fetch_sections();
    }
/**
 * Función que retorna un ícono de 'check' o 'close' según el valor booleano que recibe.
 * @param {boolean} bool - Valor booleano a mapear.
 * @return {JSX.Element} - Elemento JSX que contiene el ícono.
*/
    mapBool(bool) {
        if (bool) {
            return <i className="material-icons mr-1">check</i>
        } else {
            return <i className="material-icons mr-1">close</i>
        }
    }
/**
 * Método de renderizado del componente.
 * @return {JSX.Element} - Elemento JSX que representa el componente.
*/
    render() {
        const {users} = this.props;

        const customer_header = [
            { title: 'Correo', prop: 'email', filterable:true},
            { title: 'Nombre', prop: 'name', filterable:true },
            { title: 'Sección', prop: 'section', filterable:true },
            { title: 'Editar', prop: 'edit' },
        ];

        const customLabels = {
            first: '<<',
            last: '>>',
            prev: '<',
            next: '>',
            show: 'Display',
            entries: 'rows',
            noResults: 'No hay resultados',
            filterPlaceholder: 'Buscar',
        };

        const users_body = users.list.map((user, index) => (
            {email: user.username, name: `${user.first_name} ${user.last_name}` , section: user.section, edit: <Button value={index} onClick={e => this.toggle_edit(e, "value")}>Editar</Button>}
        ));

        return <><Container fluid className="main-content-container px-4">
            <Row>

                <Col lg="12">
                    <Card small className="my-4">
                        {/* Card Header */}
                        <CardHeader className="border-bottom">
                            <h6 className="m-0">
                                <i className="icon icon-clienteactual header-icon"></i>
                                Usuarios Activos
                                <i className="material-icons add-elements"
                                   onClick={this.toggle}>add</i></h6>
                        </CardHeader>

                        <CardBody className="d-flex flex-column">
                            <Row>
                                <div className="table-responsive">
                                    <Col lg="12">

                                        <CustomTable
                                            tableHeaders={customer_header}
                                            tableBody={users_body}
                                            tableClass="table-striped hover responsive"
                                            rowsPerPage={10}
                                            initialSort={{ prop: 'email', isAscending: true }}
                                            labels={customLabels}
                                        />

                                    </Col>
                                </div>

                            </Row>
                        </CardBody>
                    </Card>
                </Col>

            </Row>

            <Modal open={this.state.new_user_open} toggle={this.toggle} size="lg">
                <ModalHeader>Agregar Usuario</ModalHeader>
                <ModalBody>
                    <Form onSubmit={this.submit_new_user}>
                        <Row>
                            <Col lg="12" className="form-group">
                                <label htmlFor="Consumption">Correo</label>
                                <FormInput
                                    type="text"
                                    value={this.state.new_email}
                                    onChange={(e) => this.setState({new_email: e.target.value})}
                                    required
                                />
                            </Col>
                            <Col lg="6" className="form-group">
                                <label htmlFor="Consumption">Contraseña</label>
                                <FormInput
                                    type="password"
                                    value={this.state.new_password_1}
                                    onChange={(e) => this.setState({new_password_1: e.target.value})}
                                    required
                                    invalid={this.state.password_error}
                                />
                                <FormFeedback>{this.state.password_error_message}</FormFeedback>
                            </Col>
                            <Col lg="6" className="form-group">
                                <label htmlFor="Consumption">Repetir Contraseña</label>
                                <FormInput
                                    type="password"
                                    value={this.state.new_password_2}
                                    onChange={(e) => this.setState({new_password_2: e.target.value})}
                                    required
                                    invalid={this.state.password_error}
                                />
                            </Col>
                            <Col lg="6" className="form-group">
                                <label htmlFor="Consumption">Nombre</label>
                                <FormInput
                                    type="text"
                                    value={this.state.new_first_name}
                                    onChange={(e) => this.setState({new_first_name: e.target.value})}
                                    required
                                />
                            </Col>
                            <Col lg="6" className="form-group">
                                <label htmlFor="Consumption">Apellido</label>
                                <FormInput
                                    type="text"
                                    value={this.state.new_last_name}
                                    onChange={(e) => this.setState({new_last_name: e.target.value})}
                                    required
                                />
                            </Col>
                            <Col lg="12" className="form-group">
                                <label htmlFor="Consumption">Rol de Usuario</label>
                                <FormSelect
                                    value={this.state.new_role}
                                    onChange={(e) => this.setState({new_role: e.target.value})}
                                    required
                                >
                                    <option value="1">Usuario Normal</option>
                                    <option value="2">Supervisor</option>
                                    <option value="3">Administrador</option>
                                </FormSelect>
                            </Col>
                            <Col lg="12" className="form-group">
                                <label htmlFor="Consumption">Sección</label>
                                <FormSelect
                                    value={this.state.new_section}
                                    onChange={(e) => this.setState({new_section: e.target.value})}
                                    required
                                >
                                    <option value="">---</option>
                                    {users.sections.map((section, index) => (
                                        <option key={`user_${section.name}`}
                                                value={section.name}>{section.name}</option>
                                    ))}
                                </FormSelect>

                            </Col>
                            <Col lg="12" className="my-4">
                                <Button type="submit" className="col-lg-12">Crear Usuario</Button>
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>
            </Modal>

            <Modal open={this.state.edit_user_open} toggle={this.toggle_edit} size="lg">
                <ModalHeader>Editar Usuario</ModalHeader>
                <ModalBody>
                    <Form onSubmit={this.edit_user}>
                        <Row>
                            <Col lg="6" className="form-group">
                                <label htmlFor="Consumption">Nombre</label>
                                <FormInput
                                    type="text"
                                    value={this.state.edit_first_name}
                                    onChange={(e) => this.setState({edit_first_name: e.target.value})}
                                    required
                                />
                            </Col>
                            <Col lg="6" className="form-group">
                                <label htmlFor="Consumption">Apellido</label>
                                <FormInput
                                    type="text"
                                    value={this.state.edit_last_name}
                                    onChange={(e) => this.setState({edit_last_name: e.target.value})}
                                    required
                                />
                            </Col>
                            <Col lg="12" className="form-group">
                                <label htmlFor="Consumption">Rol de Usuario</label>
                                <FormSelect
                                    value={this.state.edit_role}
                                    onChange={(e) => this.setState({edit_role: e.target.value})}
                                    required
                                >
                                    <option value="1">Usuario Normal</option>
                                    <option value="2">Supervisor</option>
                                    <option value="3">Administrador</option>
                                </FormSelect>
                            </Col>
                            <Col lg="12" className="form-group">
                                <label htmlFor="Consumption">Sección</label>
                                <FormSelect
                                    value={this.state.edit_section}
                                    onChange={(e) => this.setState({edit_section: e.target.value})}
                                    required
                                >
                                    <option value="">---</option>
                                    {users.sections.map((section, index) => (
                                        <option key={`user_${section.name}`}
                                                value={section.name}>{section.name}</option>
                                    ))}
                                </FormSelect>
                            </Col>
                            <Col lg="12" className="form-group">
                                <label htmlFor="Consumption">Activo</label>
                                <FormSelect
                                    value={this.state.edit_active}
                                    onChange={(e) => this.setState({edit_active: e.target.value})}
                                    required
                                >
                                    <option value="true">Activo</option>
                                    <option value="false">Desactivar</option>
                                </FormSelect>
                            </Col>
                            <Col lg="12" className="my-4">
                                <Button type="submit" className="col-lg-12">Guardar</Button>
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>
            </Modal>

        </Container></>;

    }

}
/**
 * Función que mapea el estado a las props del componente
 * @param {Object} state - Estado actual de la aplicación
 * @returns {Object} - Objeto que contiene las props mapeadas al estado
*/
const mapStateToProps = state => {
    return {
        users: state.users,
    }
};
/**
 * Función que mapea las acciones a las props del componente
 * @param {Function} dispatch - Función de redux para despachar acciones
 * @returns {Object} - Objeto que contiene las props mapeadas a las acciones
*/
const mapDispatchToProps = dispatch => {
    return {
        fetch_users: () => {
            dispatch(users.fetch_users())
        },
        fetch_sections: () => {
            dispatch(users.fetch_sections())
        },
        create_user: (data) => {
            return dispatch(users.create_user(data))
        },
        update_user: (id, data) => {
            return dispatch(users.update_user(id, data))
        },
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(AdminUsers);
