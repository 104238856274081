import { EventEmitter } from "events";

import Dispatcher from "./dispatcher";
import Constants from "./constants";
import getSidebarNavItems from "../data/sidebar-nav-items";

let _store = {
  menuVisible: false,
  navItems: getSidebarNavItems()
};

/**
 * La clase Store es una clase que extiende de EventEmitter para manejar eventos.
 * @name Store
 * Representa un almacenamiento que maneja un estado compartido entre los componentes.
*/
class Store extends EventEmitter {
  /**
 * Constructor de la clase Store.
 * Registra los métodos para los eventos y se suscribe a Dispatcher.
*/
  constructor() {
    super();
/**
 * Registra la función registerToActions en el contexto de Store.
 * @type {Function}
*/
    this.registerToActions = this.registerToActions.bind(this);
/**
 * Registra la función toggleSidebar en el contexto de Store.
 * @type {Function}
*/
    this.toggleSidebar = this.toggleSidebar.bind(this);

    Dispatcher.register(this.registerToActions.bind(this));
  }
/**
 * Función que maneja la lógica de los eventos.
 * @param {Object} payload - Un objeto que contiene la acción que se debe tomar y la información relacionada.
 * @param {string} payload.actionType - Tipo de acción a tomar.
 * @param {Object} payload.payload - La información relacionada con la acción.
*/
  registerToActions({ actionType, payload }) {
    switch (actionType) {
      case Constants.TOGGLE_SIDEBAR:
        this.toggleSidebar();
        break;
      default:
    }
  }
/**
 * Función que cambia el estado de la barra lateral.
*/
  toggleSidebar() {
    _store.menuVisible = !_store.menuVisible;
    this.emit(Constants.CHANGE);
  }
/**
 * Función que devuelve el estado de la barra lateral.
 * @return {boolean} - True si la barra lateral está visible, False si está oculta.
*/
  getMenuState() {
    return _store.menuVisible;
  }
/**
 * Función que devuelve los elementos de la barra lateral.
 * @return {Object[]} - Un array de objetos que representan los elementos de la barra lateral.
*/
  getSidebarItems() {
    return _store.navItems;
  }
/**
 * Registra un callback que se ejecutará cada vez que el estado cambie.
 * @param {Function} callback - El callback que se debe ejecutar cuando el estado cambie.
*/
  addChangeListener(callback) {
    this.on(Constants.CHANGE, callback);
  }
/**
 * Elimina un callback que se registró previamente con addChangeListener.
 * @param {Function} callback - El callback que se debe eliminar.
*/
  removeChangeListener(callback) {
    this.removeListener(Constants.CHANGE, callback);
  }
}

export default new Store();
