import React from "react";

/**
 * Componente de React que muestra un botón para cargar archivos personalizado.
 * @function
 * @returns {JSX.Element} Elemento JSX que muestra un botón de carga de archivo personalizado.
*/

const CustomFileUpload = () => (
  <div className="custom-file mb-3">
    <input type="file" className="custom-file-input" id="customFile2" />
    <label className="custom-file-label" htmlFor="customFile2">
      Choose file...
    </label>
  </div>
);

export default CustomFileUpload;
