import axios from "axios";

/**
 * Función que realiza una petición GET a la API para obtener los usuarios.
 * @name fetch_users
 * @return {Promise} Promesa que devuelve el resultado de la petición.
*/

export const fetch_users = () => {
    return (dispatch, getState) => {
        let headers = {"Content-Type": "application/json"};
        let {token} = getState().auth;

        if (token) {
            headers["Authorization"] = `Token ${token}`;
        }

        return axios.get("/api/users/", {headers}).then(res => {
            return dispatch({type: 'FETCH_USERS', users: res.data})
        }).catch(err => {
            console.log('ERROR');
            console.log(err);
            console.log(err.response.status);
        })
    }
};

/**
 * Función que realiza una petición GET a la API para obtener las secciones.
 * @name fetch_sections
 * @return {Promise} Promesa que devuelve el resultado de la petición.
*/

export const fetch_sections = () => {
    return (dispatch, getState) => {
        let headers = {"Content-Type": "application/json"};
        let {token} = getState().auth;

        if (token) {
            headers["Authorization"] = `Token ${token}`;
        }

        return axios.get("/api/sections/", {headers}).then(res => {
            return dispatch({type: 'FETCH_SECTIONS', sections: res.data})
        }).catch(err => {
            console.log('ERROR');
            console.log(err);
            console.log(err.response.status);
        })
    }
};

/**
 * Función que realiza una petición POST a la API para crear un nuevo usuario.
 * @name create_user
 * @param {Object} data - Datos del nuevo usuario a crear.
 * @return {Promise} Promesa que devuelve el resultado de la petición.
*/

export const create_user = (data) => {
    return (dispatch, getState) => {
        let headers = {"Content-Type": "application/json"};
        let {token} = getState().auth;

        if (token) {
            headers["Authorization"] = `Token ${token}`;
        }

        let body = {...data};

        console.log(body);

        return axios.post("/api/auth/register/", body, {headers}).then(res => {
            return dispatch({type: 'CREATE_USER', user: res.data})
        }).catch(err => {
            console.log('ERROR');
            console.log(err);
            console.log(err.response.status);
        })
    }
};

/**
 * Función que realiza una petición PUT a la API para actualizar los datos de un usuario.
 * @name update_user
 * @param {Number} id - Identificador del usuario a actualizar.
 * @param {Object} data - Nuevos datos del usuario.
 * @return {Promise} Promesa que devuelve el resultado de la petición.
*/

export const update_user = (id, data) => {
    return (dispatch, getState) => {
        let headers = {"Content-Type": "application/json"};
        let {token} = getState().auth;

        if (token) {
            headers["Authorization"] = `Token ${token}`;
        }

        let body = {...data};

        return axios.put(`/api/users/${id}/`, body, {headers}).then(res => {
            return dispatch({type: 'EDIT_USER', user: res.data})
        }).catch(err => {
            console.log('ERROR');
            console.log(err);
            console.log(err.response.status);
        })
    }
};

/**
 * Función para obtener las notificaciones del usuario
 * @function fetch_notifications
 * @returns {Function} - Función con dispatch y getState como argumentos
*/

export const fetch_notifications = () => {
    return (dispatch, getState) => {
        let headers = {"Content-Type": "application/json"};
        let {token} = getState().auth;

        if (token) {
            headers["Authorization"] = `Token ${token}`;
        }

        return axios.get(`/api/user/notifications/`, {headers}).then(res => {
            return dispatch({type: 'FETCH_NOTIFICATIONS', notifications: res.data})
        }).catch(err => {
            console.log('ERROR');
            console.log(err);
            console.log(err.response.status);
        })
    }
};

/**
 * Función para obtener los enlaces de la barra lateral
 * @name fetch_sidebar_links
 * @function fetch_sidebar_links
 * @returns {Function} - Función con dispatch y getState como argumentos
*/

export const fetch_sidebar_links = () => {
    return (dispatch, getState) => {
        let headers = {"Content-Type": "application/json"};
        let {token} = getState().auth;

        if (token) {
            headers["Authorization"] = `Token ${token}`;
        }

        return axios.get(`/api/sidebar_links/`, {headers}).then(res => {
            return dispatch({type: 'FETCH_SIDEBAR_LINKS', data: res.data})
        }).catch(err => {
            console.log('ERROR');
            console.log(err);
            console.log(err.response.status);
        })
    }
};