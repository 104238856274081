import React from "react";
import {Collapse, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, NavItem, NavLink} from "shards-react";
import {auth} from "../../../../actions";
import {connect} from "react-redux";

/**
 * Representa el componente de acciones de usuario, como el botón de cierre de sesión.
 * @name UserActions
 * @extends React.Component
*/

class UserActions extends React.Component {
  /**
 * Crea una instancia del componente de acciones de usuario.
 * @param {Object} props - Las propiedades del componente.
*/
  constructor(props) {
    super(props);
/**
 * El estado del componente.
 * @type {Object}
 * @property {boolean} visible - Si el menú de acciones de usuario está visible o no.
*/
    this.state = {
      visible: false
    };

    this.toggleUserActions = this.toggleUserActions.bind(this);
  }
/**
 * Alterna la visibilidad del menú de acciones de usuario.
*/
  toggleUserActions() {
    this.setState({
      visible: !this.state.visible
    });
  }
/**
 * Renderiza el componente.
 * @returns {JSX.Element} - El componente renderizado.
*/
  render() {
    return (
        <NavItem tag={Dropdown} caret toggle={this.toggleUserActions}>
          <DropdownToggle caret tag={NavLink} className="text-nowrap px-3 user-menu">
            <span className="d-md-inline-block user-menu">{this.props.user.username}</span>
          </DropdownToggle>
          <Collapse tag={DropdownMenu} right small open={this.state.visible}>
            <DropdownItem className="text-danger" onClick={this.props.logout}>
              <i className="material-icons text-danger">&#xE879;</i> Cerrar Sesión
            </DropdownItem>
          </Collapse>
        </NavItem>
    );
  }
}
/**
 * Función que mapea el estado a las props del componente
 * @param {Object} state - Estado actual de la aplicación
 * @returns {Object} - Objeto que contiene las props mapeadas al estado
*/
const mapStateToProps = state => {
  return {
    user: state.auth.user,
  }
};
/**
 * Función que mapea las acciones a las props del componente
 * @param {Function} dispatch - Función de redux para despachar acciones
 * @returns {Object} - Objeto que contiene las props mapeadas a las acciones
*/
const mapDispatchToProps = dispatch => {
  return {
    logout: () => dispatch(auth.logout()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserActions)