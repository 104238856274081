import React, {Component} from "react";
import {Card, CardBody, Col, Container, Row} from "shards-react";

import {connect} from "react-redux";
import PageTitle from "../components/common/PageTitle";
import {simulations} from "../actions";

/**
 * Componente que representa una simulación de gas natural firme e interrumpible.
 * @extends Component
 * @param {Object} props - Las propiedades que recibe el componente.
 * @param {function} props.fetch_simulation - Función para obtener los datos de la simulación.
 * @param {Object} props.match.params.id - El ID de la simulación a mostrar.
 * @param {Object} simulation - Los datos de la simulación a mostrar.
 * @return {JSX.Element} - El componente que representa la simulación de gas natural firme e interrumpible.
*/
class SimulationFAI extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.props.fetch_simulation(this.props.match.params.id);
    }

    render() {
        const {simulation} = this.props;

        if (simulation === null) {
            return <h1>Loading...</h1>
        }

        const simulation_daily_values = simulation.simulation.simulation
        const simulation_totals = simulation.simulation

        return <><Container fluid className="main-content-container px-4">
            <Row noGutters className="page-header py-4">
                <PageTitle title="Simulación Firme e Interrumpible"
                           className="text-sm-left mb-3 col-sm-12"/>
            </Row>
            <Row>

            </Row>

            <Row>
                <Col>
                    <Card className="my-4">
                        <CardBody className="d-flex flex-column">
                            <table className={"table table-sm"}>
                                <thead>
                                <th>Subtotal Firme</th>
                                <th>Subtotal Interrumpible</th>
                                <th>Subtotal</th>
                                <th>Precio de adquisición por GJ</th>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>{simulation_totals['firm_subtotal']}</td>
                                    <td>{simulation_totals['interruptible_subtotal']}</td>
                                    <td>{simulation_totals['subtotal']}</td>
                                    <td>{simulation_totals['acquisition_price']}</td>
                                </tr>
                                </tbody>
                            </table>
                        </CardBody>
                    </Card>
                </Col>
            </Row>

            <Card small className="my-4">
                <CardBody className="d-flex flex-column">
                    <div className={"table-responsive"}>
                        <table className="table table-sm simulation-data">
                            <thead>
                            <th>Día</th>
                            <th>Firme</th>
                            <th>Consumo</th>
                            <th>Consumo Interrumpible</th>
                            <th>Índice</th>
                            <th>Tipo de Cambio</th>
                            <th>Spread</th>
                            <th>Precio</th>
                            <th>Transporte</th>
                            <th>Gas Fuel</th>
                            <th>Costo Gestión</th>
                            <th>Precio Unitario</th>
                            <th>Precio Diario</th>
                            </thead>
                            <tbody>
                            {simulation_daily_values.map((data, id) => (
                                <tr key={id}>
                                    <td>{data['Day']}</td>
                                    <td>{data['Firm']}</td>
                                    <td>{data['Consumption']}</td>
                                    <td>{data['IConsumption']}</td>
                                    <td>{data['Index']}</td>
                                    <td>{data['Exchange_Rate']}</td>
                                    <td>{data['Spread']}</td>
                                    <td>{data['Price']}</td>
                                    <td>{data['Transport']}</td>
                                    <td>{data['Gas_Fuel']}</td>
                                    <td>{data['Management_Cost']}</td>
                                    <td>{data['Unit_Price']}</td>
                                    <td>{data['Daily_Price']}</td>
                                </tr>
                            ))}
                            <tr>
                                <td/>
                                <td>{simulation_totals['firm_count']}</td>
                                <td>{simulation_totals['consumption_total']}</td>
                                <td>{simulation_totals['interruptible_consumption']}</td>
                                <td/>
                                <td/>
                                <td/>
                                <td/>
                                <td/>
                                <td/>
                                <td/>
                                <td/>
                                <td>{simulation_totals['interruptible_subtotal']}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>

                </CardBody>
            </Card>

        </Container></>;

    }

}
/**
 * Función que mapea el estado a las props del componente
 * @param {Object} state - Estado actual de la aplicación
 * @returns {Object} - Objeto que contiene las props mapeadas al estado
*/
const mapStateToProps = state => {
    return {
        simulation: state.simulations.detail_fai,
    }
};
/**
 * Función que mapea las acciones a las props del componente
 * @param {Function} dispatch - Función de redux para despachar acciones
 * @returns {Object} - Objeto que contiene las props mapeadas a las acciones
*/
const mapDispatchToProps = dispatch => {
    return {
        fetch_simulation: (id) => {
            return dispatch(simulations.fetchFAISimulation(id))
        },
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(SimulationFAI);
