/**
 * Retorna un array con objetos que representan las rutas de la aplicación.
 * @returns {Object[]} Un array de objetos con los siguientes campos:
 * title: el título de la ruta
 * to: la URL de la ruta
 * htmlBefore: un string con HTML que aparece antes del título
 * htmlAfter: un string con HTML que aparece después del título
 * groups: un array con los grupos de usuarios que tienen acceso a la ruta
 * admin: un booleano que indica si solo los usuarios administradores pueden acceder a la ruta
*/
export default function () {
    return [
        {
            title: "Inicio",
            to: "/inicio",
            htmlBefore: '<i class="icon icon-home ng-icon"></i>',
            htmlAfter: "",
            groups: [],
        },
        {
            title: "Repositorio Personal",
            to: "/files",
            htmlBefore: '<i class="icon icon-repositorio-personal ng-icon"></i>',
            htmlAfter: "",
            groups: [],
        },
        {
            title: "CRM Clientes",
            to: "/crm_clientes",
            htmlBefore: '<i class="icon icon-cliente ng-icon"></i>',
            htmlAfter: "",
            admin: true,
            groups: ["beta_clients",],
        },
        {
            title: "Clientes",
            to: "/clientes",
            htmlBefore: '<i class="icon icon-cliente ng-icon"></i>',
            htmlAfter: "",
            groups: [],
        },
        {
            title: "Materiales Recurrentes",
            to: "/materiales_recurrentes",
            htmlBefore: '<i class="icon icon-materialesrecurrentes ng-icon"></i>',
            htmlAfter: "",
            groups: [],
        },
        {
            title: "Avisos",
            to: "/announcements",
            htmlBefore: '<i class="icon icon-avisos ng-icon"></i>',
            htmlAfter: "",
            groups: [],
        },
        {
            title: "Usuarios",
            to: "/administracion/usuarios",
            htmlBefore: '<i class="icon icon-altacliente ng-icon"></i>',
            htmlAfter: "",
            admin: true,
            groups: ["users",],
        },
        {
            title: "Reportes",
            to: "/reportes",
            htmlBefore: '<i class="material-icons">summarize</i>',
            htmlAfter: "",
            admin: true,
            groups: ["reports",],
        },
        {
            title: "Búsqueda de clientes",
            to: "/all_customers",
            htmlBefore: '<i class="icon icon-cliente ng-icon"></i>',
            htmlAfter: "",
            admin: true,
            groups: ["beta_clients",],
        },
        {
            title: "Actualizar Datos",
            to: "/actualizar_datos",
            htmlBefore: '<i class="material-icons">summarize</i>',
            htmlAfter: "",
            admin: true,
            groups: ["reports",],
        }
    ];
}
