import React, { Component } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormCheckbox,
  FormInput,
  FormSelect,
  FormTextarea,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "shards-react";

import PageTitle from "./../components/common/PageTitle";

import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { customers, quoteSimulations, simulations, users } from "../actions";
import CustomTable from "../components/CustomTable";

/**
 * Clase que representa el detalle de un cliente.
 * @name CustomerDetail
 * @class
 * @extends Component
 */

class CustomerDetail extends Component {
  /**
   * Constructor de la clase CustomerDetail.
   * @constructor
   * @param {Object} props - Propiedades que se pasan al componente.
   */
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      contract_id: "",
      existing_installation: false,
      company_name: "",
      created_at: "",
      kam: "",
      user_in_charge: "",
      created_by: "",
      disabled: true,
      visit_open: false,
      file_open: false,
      description: "",
      reason_for_visit: "",
      file: "",
      file_name: "",
      files: [],
      transfer_open: false,
      transfer_reason: "",
      transfer_kam: "",
      type: "",
      commercial_business: "",
      contact_open: false,
      contact_name: "",
      email: "",
      position: "",
      phone: "",
      customer_description: "",
      current_status: "",

      contact_edit_open: false,
      edit_contact_index: null,
      edit_contact_name: "",
      edit_email: "",
      edit_position: "",
      edit_phone: "",
      edit_contact_id: null,
    };
    this.toggle = this.toggle.bind(this);
    this.toggle_file = this.toggle_file.bind(this);
    this.toggle_contact = this.toggle_contact.bind(this);
  }
  /**
   * Función que limpia los estados de los elementos que se han abierto para edición.
   * @function
   */
  cleanup = () => {
    this.setState({
      visit_open: false,
      file_open: false,
      contact_open: false,
      transfer_open: false,
      contact_edit_open: false,
    });
  };
  /**
   * Función que alterna el estado de la variable "visit_open" entre verdadero y falso.
   * @function
   */
  toggle = () => {
    this.setState({
      visit_open: !this.state.visit_open,
    });
  };
  /**
   * Función que alterna el estado de la variable "file_open" entre verdadero y falso.
   * @function
   */
  toggle_file = () => {
    this.setState({
      file_open: !this.state.file_open,
    });
  };
  /**
   * Función que alterna el estado de la variable "contact_open" entre verdadero y falso.
   * @function
   */
  toggle_contact = () => {
    this.setState({
      contact_open: !this.state.contact_open,
    });
  };
  /**
   * Función que alterna el estado de la variable "contact_edit_open" entre verdadero y falso, y
   * establece los valores de los estados para la edición de un contacto en particular.
   * @function
   * @param {Object} e - Evento de clic en el botón de editar contacto.
   */
  toggle_edit_contact = (e) => {
    if (e !== undefined) {
      let index = e.target.value;
      let contact = this.props.customers.contacts[index];
      this.setState({
        contact_edit_open: !this.state.contact_edit_open,
        edit_contact_index: index,
        edit_contact_name: contact.contact_name,
        edit_email: contact.email,
        edit_position: contact.position,
        edit_phone: contact.phone,
        edit_contact_id: contact.id,
      });
    } else {
      this.setState({
        contact_edit_open: !this.state.contact_edit_open,
        edit_contact_index: null,
        edit_contact_name: "",
        edit_email: "",
        edit_position: "",
        edit_phone: "",
        edit_contact_id: null,
      });
    }
  };
  /**
   * Función que alterna entre abrir y cerrar una sección de transferencia
   * @function
   */
  toggle_transfer = () => {
    this.setState({
      transfer_open: !this.state.transfer_open,
    });
  };
  /**
   * Función que formatea una cadena de fecha en un formato de fecha legible en México
   * @function
   * @param {string} date_string - Cadena de fecha en formato ISO 8601
   * @returns {string} - Fecha formateada en formato legible en México
   */
  format_date(date_string) {
    let date = new Date(date_string);
    let config = new Intl.DateTimeFormat("es-MX");
    return config.format(date);
  }
  /**
   * Función que envía los datos de una visita al servidor
   * @function
   * @param {Event} e - Evento de formulario enviado
   */
  submit_visit = (e) => {
    e.preventDefault();
    let data = {
      reason_for_visit: this.state.reason_for_visit,
      description: this.state.description,
      customer: this.state.id,
    };
    this.props.create_visit(data).then(this.clear_all());
  };
  /**
   * Función que envía los datos de una transferencia de un cliente al servidor
   * @function
   * @param {Event} e - Evento de formulario enviado
   */
  submit_transfer = (e) => {
    e.preventDefault();
    let data = {
      transfer_reason: this.state.transfer_reason,
      customer: this.props.match.params.id,
      transferred_to: this.state.transfer_kam,
    };
    this.props.transfer_customer(data).then(this.clear_all());
  };
  /**
   * Función que actualiza los datos de un cliente en el servidor
   * @function
   * @param {Event} e - Evento de formulario enviado
   */
  update_customer = (e) => {
    e.preventDefault();
    let id = this.props.match.params.id;

    let data = {
      company_name: this.state.company_name,
      existing_installation: this.state.existing_installation,
      contract_id: this.state.contract_id,
      commercial_business: this.state.commercial_business,
      description: this.state.customer_description,
      current_status: this.state.current_status,
      type: this.state.type,
    };
    this.props
      .update_customer(id, data)
      .then(this.setState({ disabled: true }));
  };
  /**
   * Función que envía los datos de un archivo relacionado con un cliente al servidor
   * @function
   * @param {Event} e - Evento de formulario enviado
   */
  submit_file = (e) => {
    e.preventDefault();
    let data = {
      file: this.state.file,
      customer: this.state.id,
      file_name: this.state.file_name,
    };
    this.props.create_file(data).then(this.clear_all());
  };
  /**
   * Función que envía los datos de un contacto relacionado con un cliente al servidor
   * @function
   * @param {Event} e - Evento de formulario enviado
   */
  submit_contact = (e) => {
    e.preventDefault();
    let data = {
      contact_name: this.state.contact_name,
      email: this.state.email,
      position: this.state.position,
      phone: this.state.phone,
      customer: this.state.id,
    };
    this.props.submit_contact(data).then(this.clear_all());
  };
  /**
   * Función que actualiza los datos de un contacto relacionado con un cliente en el servidor
   * @function
   * @param {Event} e - Evento de formulario enviado
   */
  submit_edit_contact = (e) => {
    e.preventDefault();
    let data = {
      contact_name: this.state.edit_contact_name,
      email: this.state.edit_email,
      position: this.state.edit_position,
      phone: this.state.edit_phone,
    };
    this.props
      .update_contact(
        this.state.edit_contact_id,
        this.state.edit_contact_index,
        data
      )
      .then(this.clear_all());
  };
  /**
   * @function get_customer
   * @description Función que obtiene los datos de un cliente.
   * @returns {Promise} Promesa que se resuelve cuando los datos del cliente se han cargado correctamente.
   */
  get_customer = () => {
    let id = this.props.match.params.id;
    return this.props.fetch_customer(id).then(() => {
      let customer = this.props.customers.detail;
      this.setState({
        id: customer.id,
        contract_id: customer.contract_id,
        existing_installation: customer.existing_installation,
        company_name: customer.company_name,
        created_at: this.format_date(customer.created_at),
        kam: customer.kam,
        user_in_charge: customer.user_in_charge,
        created_by: customer.created_by,
        files: customer.files,
        type: customer.type,
        commercial_business: customer.commercial_business,
        contacts: customer.contacts,
        customer_description: customer.description,
        current_status: customer.current_status,
      });
    });
  };
  /**
   * @function componentDidMount
   * @description Función que se ejecuta después de que el componente sea montado en el DOM.
   */
  componentDidMount() {
    let customer_id = this.props.match.params.id;
    this.get_customer().then(this.props.fetchQuoteSimulations(customer_id));
    this.props.fetchCmdtSimulations(customer_id);
    this.props.fetch_users();
  }
  /**
   * @function createSimulation
   * @description Función que crea una nueva simulación para un cliente.
   * @param {Object} e - Evento de click.
   */
  createSimulation = (e) => {
    e.preventDefault();
    let customer_id = this.props.match.params.id;
    this.props.createSimulation(customer_id);
  };

  deleteSimulation = (simulation_id) => {
    const result = window.confirm(
      "Esta seguro de que desea eliminar esta simulación?"
    );
    if (result) {
      this.props.deleteSimulation(simulation_id);
    }
    return;
  };

  createCmdtSimulation = (e) => {
    e.preventDefault();
    let customer_id = this.props.match.params.id;
    this.props.createCmdtSimulation(customer_id);
  };

  deleteCmdtSimulation = (simulation_id) => {
    const result = window.confirm(
      "Esta seguro de que desea eliminar esta simulación?"
    );
    if (result) {
      this.props.deleteCmdtSimulation(simulation_id);
    }
    return;
  };

  /**
   * @function fileChangeHandler
   * @description Función que se ejecuta cuando se selecciona un archivo.
   * @param {Object} e - Evento de cambio.
   */
  fileChangeHandler = (e) => {
    this.setState({
      file: e.target.files[0],
    });
  };
  /**
   * @function clear_all
   * @description Función que limpia los campos de la pantalla.
   */
  clear_all = () => {
    this.setState({
      visit_open: false,
      file_open: false,
      file: "",
      transfer_open: false,
      contact_open: false,
      contact_name: "",
      email: "",
      position: "",
      phone: "",

      contact_edit_open: false,
    });
  };

  render() {
    let { contacts, files } = this.props.customers;
    const customer_types = [
      ["lc", "<5,000 Distribución con comercialización"],
      [
        "lcr",
        "<5,000 Distribución con comercialización. + IRI + Calibraciones",
      ],
      [
        "lcse",
        "<5,000 Distribución con comercialización. + Solución Energetica",
      ],
      ["gs", ">5,000 Distribución Simple"],
      ["gsr", ">5,000 Distribución Simple  + IRI + Calibraciones"],
      ["gsse", ">5,000 Distribución Simple + SE"],
      ["gsbe", ">5,000 Distribución Simple + VE"],
      ["gsc", ">5,000 Distribución Simple + Coberturas"],
      ["gsg", ">5000 Distribución Simple + GNV"],
    ];
    const status_types = [
      ["detectado", "Detectado"],
      ["potencial", "Potencial"],
      ["oferta", "Oferta"],
      ["contratado", "Contratado"],
      ["construccion", "Construcción"],
      ["en servicio", "En Servicio"],
      ["cancelado", "Cancelado"],
      ["cortado", "Cortado"],
    ];
    const contact_header = [
      { title: "Nombre", prop: "name", filterable: true },
      { title: "Correo", prop: "email", filterable: true },
      { title: "Posición", prop: "position", filterable: true },
      { title: "Teléfono", prop: "phone" },
      { title: "Editar", prop: "edit" },
    ];
    const document_header = [
      { title: "Archivo", prop: "file", filterable: true },
      { title: "Descargar", prop: "download", filterable: true },
    ];
    const simulation_header = [
      { title: "Mes", prop: "month", filterable: true },
      { title: "Total de Factura", prop: "total", filterable: true },
      { title: "Editar", prop: "edit", filterable: true },
      { title: "Eliminar", prop: "delete", filterable: false },
    ];
    const visit_header = [
      { title: "Razón de Visita", prop: "reason", filterable: true },
      { title: "Fecha de Creación", prop: "date", filterable: true },
      { title: "Descripción", prop: "description", filterable: true },
    ];
    const polls_header = [
      { title: "Nombre de encuesta", prop: "name", filterable: true },
      { title: "Fecha de Creación", prop: "date", filterable: true },
      { title: "Ver", prop: "view", filterable: true },
    ];

    const customLabels = {
      first: "<<",
      last: ">>",
      prev: "<",
      next: ">",
      show: "Display",
      entries: "rows",
      noResults: "No hay resultados",
      filterPlaceholder: "Buscar",
    };

    const contacts_body = contacts.map((contact, id) => ({
      name: contact.contact_name,
      email: <a href={"mailto:" + contact.email}>{contact.email}</a>,
      position: contact.position,
      phone: <a href={"tel:" + contact.phone}>{contact.phone}</a>,
      // editar: <i className="material-icons add-elements" onClick={(e) => this.setState({disabled: !this.state.disabled})}>
      edit: (
        <Button
          onClick={(e) => this.toggle_edit_contact(e, "value")}
          value={id}
        >
          Editar
        </Button>
      ),
    }));

    const documents_body = files.map((file, id) => ({
      file: file.file_name,
      download: (
        <Link
          to={`/files/download/${file.id}/`}
          target="_blank"
          rel="noopener noreferrer"
          download
        >
          <Button>Descargar</Button>
        </Link>
      ),
    }));
    const simulations_body = this.props.quoteSimulations.simulations.map(
      (simulation, id) => ({
        month: simulation.month,
        total: simulation.quote_total.toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
        edit: (
          <Link to={`/simulations/${simulation.id}`}>
            <Button>Editar</Button>
          </Link>
        ),
        delete: (
          <Button onClick={(e) => this.deleteSimulation(simulation.id)}>
            Eliminar
          </Button>
        ),
      })
    );

    const cmdt_simulations_body = this.props.cmdtSimulations.simulations.map(
      (simulation, id) => ({
        month: simulation.month,
        total: simulation.quote_total.toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
        edit: (
          <Link to={`/simulacion/cmdt/${simulation.id}`}>
            <Button>Editar</Button>
          </Link>
        ),
        delete: (
          <Button onClick={(e) => this.deleteCmdtSimulation(simulation.id)}>
            Eliminar
          </Button>
        ),
      })
    );

    const visits_body = this.props.customers.visits.map((visit, index) => ({
      reason: visit.reason_for_visit,
      date: this.format_date(visit.created_at),
      description: visit.description,
    }));
    const polls_body = this.props.customers.polls.map((poll, index) => ({
      name: poll.name,
      date: this.format_date(poll.created_at),
      view: (
        <Link to={`/clientes/${this.state.id}/encuestas/${poll.id}`}>
          <Button>Ver</Button>
        </Link>
      ),
    }));

    let container = (
      <>
        <Container fluid className="main-content-container px-4">
          {/* Page Header */}
          <Row noGutters className="page-header py-4">
            <PageTitle
              title={`${this.props.customers.detail.company_name}`}
              subtitle=""
              className="text-sm-left mb-3"
            />
          </Row>
          <Row>
            <Col className="my-2">
              <Link to={`/clientes/`}>
                <Button>Regresar</Button>
              </Link>
            </Col>
          </Row>

          <Row>
            {/* New Draft */}

            <Col lg="6" className="mb-8 my-4 my-lg-0">
              <Card small>
                {/* Card Header */}
                <CardHeader className="border-bottom">
                  <i className="icon icon-clienteactual header-icon"></i>
                  <h6 className="m-0">
                    Detalles del Cliente{" "}
                    <i
                      className="material-icons add-elements"
                      onClick={(e) =>
                        this.setState({ disabled: !this.state.disabled })
                      }
                    >
                      edit
                    </i>
                  </h6>
                </CardHeader>

                <CardBody className="d-flex flex-column">
                  <Form onSubmit={this.update_customer}>
                    <Row>
                      <Col>
                        <Row form>
                          <Col md="12" className="form-group">
                            <FormCheckbox
                              checked={this.state.existing_installation}
                              id="new-contract"
                              // onClick={(e) => this.setState({newContract: !e.target.value})}
                              onClick={(e) =>
                                this.setState({
                                  existing_installation:
                                    !this.state.existing_installation,
                                })
                              }
                              disabled={this.state.disabled}
                            >
                              Punto de Suministro Existente
                            </FormCheckbox>
                          </Col>

                          <Col md="12" className="form-group">
                            <label htmlFor="Consumption">Razón Social</label>
                            <FormInput
                              id="company-name"
                              type="text"
                              placeholder="Razón Social"
                              value={this.state.company_name}
                              onChange={(e) =>
                                this.setState({ company_name: e.target.value })
                              }
                              required
                              disabled={this.state.disabled}
                            />
                          </Col>
                          <Col md="12" className="form-group">
                            <label htmlFor="Consumption">Descripción</label>
                            <FormTextarea
                              value={this.state.customer_description}
                              onChange={(e) =>
                                this.setState({
                                  customer_description: e.target.value,
                                })
                              }
                              disabled={this.state.disabled}
                            ></FormTextarea>
                          </Col>
                          <Col md="6" className="form-group">
                            <label htmlFor="Consumption">Tipo de Cliente</label>
                            <FormSelect
                              value={this.state.type}
                              onChange={(e) =>
                                this.setState({ type: e.target.value })
                              }
                              required
                              disabled={this.state.disabled}
                            >
                              <option value="">---</option>
                              {customer_types.map((type, index) => (
                                <option key={index} value={type[0]}>
                                  {type[1]}
                                </option>
                              ))}
                            </FormSelect>
                          </Col>
                          <Col md="6">
                            <label htmlFor="Consumption">Giro Comercial</label>
                            <FormSelect
                              value={this.state.commercial_business}
                              onChange={(e) =>
                                this.setState({
                                  commercial_business: e.target.value,
                                })
                              }
                              required
                              disabled={this.state.disabled}
                            >
                              <option value="Manufactura">Manufactura</option>
                              <option value="Servicios">Servicios</option>
                              <option value="Consumo">Consumo</option>
                              <option value="Energía">Energía</option>
                              <option value="Energía Eléctrica">
                                Energía Eléctrica
                              </option>
                              <option value="Gobierno">Gobierno</option>
                              <option value="GNV">GNV</option>
                              <option value="Papel">Papel</option>
                              <option value="Vidrio">Vidrio</option>
                              <option value="Alimentos">Alimentos</option>
                              <option value="Fundición / Acero">
                                Fundición / Acero
                              </option>
                              <option value="Química">Química</option>
                              <option value="Farmacéutica">Farmacéutica</option>
                              <option value="Hotelería">Hotelería</option>
                              <option value="Restaurante">Restaurante</option>
                              <option value="Escuela">Escuela</option>
                              <option value="Industria">Industria</option>
                              <option value="Industria Química">
                                Industria Química
                              </option>
                              <option value="Servicios y Restaurantes">
                                Servicios y Restaurantes
                              </option>
                              <option value="Hospitales">Hospitales</option>
                              <option value="Cerámica">Cerámica</option>
                              <option value="Autopartes">Autopartes</option>
                              <option value="Automotriz">Automotriz</option>
                              <option value="Construcción">Construcción</option>
                              <option value="Servicios Funerarios">
                                Servicios Funerarios
                              </option>
                              <option value="Otros">Otros</option>
                            </FormSelect>
                          </Col>
                          <Col md="6" className="form-group">
                            <label htmlFor="Consumption">Folio Acerca</label>
                            <FormInput
                              id="acerca-folio-number"
                              type="text"
                              placeholder="Folio Acerca"
                              value={this.state.id}
                              required
                              disabled
                            />
                          </Col>
                          <Col md="6" className="form-group">
                            <label htmlFor="Consumption">ID de Contrato</label>
                            <FormInput
                              id="contract-id"
                              type="text"
                              placeholder="ID de Contrato"
                              value={this.state.contract_id}
                              onChange={(e) =>
                                this.setState({ contract_id: e.target.value })
                              }
                              required
                              disabled={this.state.disabled}
                            />
                          </Col>
                          <Col md="6" className="form-group">
                            <label htmlFor="Consumption">
                              Fecha de Creación
                            </label>
                            <FormInput
                              id="acerca-folio-number"
                              type="text"
                              placeholder="Folio Acerca"
                              value={this.state.created_at}
                              onChange={(e) =>
                                this.setState({ created_at: e.target.value })
                              }
                              required
                              disabled
                            />
                          </Col>

                          <Col md="12">
                            <label htmlFor="Consumption">Estado</label>
                            <FormSelect
                              value={this.state.current_status}
                              onChange={(e) =>
                                this.setState({
                                  current_status: e.target.value,
                                })
                              }
                              required
                              disabled={this.state.disabled}
                            >
                              <option value="">---</option>
                              {status_types.map((status, id) => (
                                <option key={id} value={status[0]}>
                                  {status[1]}
                                </option>
                              ))}
                            </FormSelect>
                          </Col>
                        </Row>

                        <Row>
                          <Col md="12" className="form-group">
                            <label htmlFor="Consumption">KAM Asignado</label>
                            <FormSelect
                              value={this.state.kam}
                              onChange={(e) =>
                                this.setState({ kam: e.target.value })
                              }
                              disabled
                            >
                              <option value="">---</option>
                              {this.props.users.list.map((user, id) => (
                                <option key={id} value={user.id}>
                                  {user.username}
                                </option>
                              ))}
                            </FormSelect>
                          </Col>
                        </Row>

                        {!this.state.disabled ? (
                          <Row>
                            <Col className="my-4">
                              <Button
                                className="btn-block"
                                onClick={this.toggle_transfer}
                              >
                                Transferir
                              </Button>
                            </Col>
                            <Col className="my-4">
                              <Button type="submit" className="btn-block">
                                Actualizar
                              </Button>
                            </Col>
                          </Row>
                        ) : null}
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>

            <Col lg="6" className="mb-8 my-4 my-lg-0">
              <Card small>
                {/* Card Header */}
                <CardHeader className="border-bottom">
                  <i className="icon icon-sharedfile header-icon"></i>
                  <h6 className="m-0">
                    Contactos{" "}
                    <i
                      className="material-icons add-elements"
                      onClick={this.toggle_contact}
                    >
                      add
                    </i>
                  </h6>
                </CardHeader>

                <CardBody className="d-flex flex-column">
                  <div className="table-responsive">
                    <Col lg="12">
                      <CustomTable
                        tableHeaders={contact_header}
                        tableBody={contacts_body}
                        tableClass="table-striped hover responsive"
                        rowsPerPage={5}
                        labels={customLabels}
                      />
                    </Col>
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col lg="6" className="mb-8 my-4">
              <Card small>
                {/* Card Header */}
                <CardHeader className="border-bottom">
                  <i className="icon icon-sharedfile header-icon"></i>
                  <h6 className="m-0">
                    Documentos{" "}
                    <i
                      className="material-icons add-elements"
                      onClick={this.toggle_file}
                    >
                      add
                    </i>
                  </h6>
                </CardHeader>

                <CardBody className="d-flex flex-column">
                  <div className="table-responsive">
                    <Col lg="12">
                      <CustomTable
                        tableHeaders={document_header}
                        tableBody={documents_body}
                        tableClass="table-striped hover responsive"
                        rowsPerPage={5}
                        labels={customLabels}
                      />
                    </Col>
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col lg="6" className="mb-8 my-4">
              <Card small>
                {/* Card Header */}
                <CardHeader className="border-bottom">
                  <i className="material-icons header-icon">assessment</i>
                  <h6 className="m-0">
                    Simuladores Firme Volumetrico
                    <i
                      className="material-icons add-elements"
                      onClick={this.createSimulation}
                    >
                      add
                    </i>
                  </h6>
                </CardHeader>

                <CardBody className="d-flex flex-column">
                  <div className="table-responsive">
                    <Col lg="12">
                      <CustomTable
                        tableHeaders={simulation_header}
                        tableBody={simulations_body}
                        tableClass="table-striped hover responsive"
                        rowsPerPage={5}
                        labels={customLabels}
                      />
                    </Col>
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col lg="6" className="mb-8 my-4">
              <Card small>
                {/* Card Header */}
                <CardHeader className="border-bottom">
                  <i className="material-icons header-icon">event</i>
                  <h6 className="m-0">
                    Visitas{" "}
                    <i
                      className="material-icons add-elements"
                      onClick={this.toggle}
                    >
                      add
                    </i>
                  </h6>
                </CardHeader>

                <CardBody className="d-flex flex-column">
                  <div className="table-responsive">
                    <Col lg="12">
                      <CustomTable
                        tableHeaders={visit_header}
                        tableBody={visits_body}
                        tableClass="table-striped hover responsive"
                        rowsPerPage={5}
                        labels={customLabels}
                      />
                    </Col>
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col lg={6} className="mb-8 my-4">
              <Card small>
                {/* Card Header */}
                <CardHeader className="border-bottom">
                  <i className="material-icons header-icon">assessment</i>
                  <h6 className="m-0">
                    Simulaciones CMDT
                    <i
                      className="material-icons add-elements"
                      onClick={this.createCmdtSimulation}
                    >
                      add
                    </i>
                  </h6>
                </CardHeader>

                <CardBody className="d-flex flex-column">
                  <div className="table-responsive">
                    <Col lg="12">
                      <CustomTable
                        tableHeaders={simulation_header}
                        tableBody={cmdt_simulations_body}
                        tableClass="table-striped hover responsive"
                        rowsPerPage={5}
                        labels={customLabels}
                      />
                    </Col>
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col lg="6" className="mb-8 my-4">
              <Card small>
                {/* Card Header */}
                <CardHeader className="border-bottom">
                  <i className="material-icons header-icon">help_outline</i>
                  <h6 className="m-0">
                    Encuestas{" "}
                    <Link to={`/clientes/encuestas/crear/${this.state.id}`}>
                      <i className="material-icons add-elements">add</i>
                    </Link>
                  </h6>
                </CardHeader>

                <CardBody className="d-flex flex-column">
                  <div className="table-responsive">
                    <div className="table-responsive">
                      <Col lg="12">
                        <CustomTable
                          tableHeaders={polls_header}
                          tableBody={polls_body}
                          tableClass="table-striped hover responsive"
                          rowsPerPage={5}
                          labels={customLabels}
                        />
                      </Col>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Modal open={this.state.visit_open} toggle={this.toggle} size="lg">
            <ModalHeader>Agregar Visita</ModalHeader>
            <ModalBody>
              <Form onSubmit={this.submit_visit}>
                <Row>
                  <Col lg="12" className="form-group">
                    <label htmlFor="Consumption">Razón de Visita</label>
                    <FormSelect
                      value={this.state.reason_for_visit}
                      onChange={(e) =>
                        this.setState({ reason_for_visit: e.target.value })
                      }
                    >
                      <option value="">---</option>
                      <option value={"Visita de Reconocimiento"}>
                        Visita de Reconocimiento
                      </option>
                      <option value={"1a Visita Cliente"}>
                        1a Visita Cliente
                      </option>
                      <option value={"1a Visita Técnica"}>
                        1a Visita Técnica
                      </option>
                      <option value={"Solicitud de Factibilidad"}>
                        Solicitud de Factibilidad
                      </option>
                      <option value={"Envió Oferta"}>Envió Oferta</option>
                      <option value={"Explicación Oferta"}>
                        Explicación Oferta
                      </option>
                      <option value={"Aprobación de oferta"}>
                        Aprobación de oferta
                      </option>
                      <option value={"Contrato de Suministro"}>
                        Contrato de Suministro
                      </option>
                      <option value={"Celebración Contrato"}>
                        Celebración Contrato
                      </option>
                      <option value={"Seguimiento licencias"}>
                        Seguimiento licencias
                      </option>
                      <option value={"Seguimiento Construcción"}>
                        Seguimiento Construcción
                      </option>
                      <option value={"Facturas"}>Facturas</option>
                      <option value={"Alta como proveedor"}>
                        Alta como proveedor
                      </option>
                      <option value={"Cambio de razón social"}>
                        Cambio de razón social
                      </option>
                      <option value={"Cambio de domicilio fiscal/Cuenta"}>
                        Cambio de domicilio fiscal/Cuenta
                      </option>
                      <option value={"Otros"}>Otros</option>
                    </FormSelect>
                  </Col>
                  <Col lg="12">
                    <label htmlFor="Consumption">Descripción</label>
                    <FormTextarea
                      type="text"
                      value={this.state.description}
                      onChange={(e) =>
                        this.setState({ description: e.target.value })
                      }
                      required
                    />
                  </Col>
                  <Col lg="12" className="my-4">
                    <Button type="submit" className="col-lg-12">
                      Crear Visita
                    </Button>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal>

          <Modal
            open={this.state.transfer_open}
            toggle={this.toggle_transfer}
            size="lg"
          >
            <ModalHeader>Transferir Cliente</ModalHeader>
            <ModalBody>
              <Form onSubmit={this.submit_transfer}>
                <Row>
                  <Col lg="12" className="form-group">
                    <label htmlFor="Consumption">Razón de Transferencia</label>
                    {/*<FormTextarea*/}
                    {/*    type="text"*/}
                    {/*    value={this.state.transfer_reason}*/}
                    {/*    onChange={(e) => this.setState({transfer_reason: e.target.value})}*/}
                    {/*    required*/}
                    {/*/>*/}
                    <FormSelect
                      value={this.state.transfer_reason}
                      onChange={(e) =>
                        this.setState({ transfer_reason: e.target.value })
                      }
                      required
                    >
                      <option value="">---</option>
                      <option value="Oportunidad de servicio de valor agregado">
                        Oportunidad de Servicio de Valor Agregado
                      </option>
                      <option value="Cambio de Front Comercial">
                        Cambio de Front Comercial
                      </option>
                      <option value="Suministro regulado">
                        Suministro Regulado
                      </option>
                      <option value="Suministro mercado libre y otros">
                        Suministro Mercado Libre y Otros
                      </option>
                    </FormSelect>
                  </Col>
                  <Col lg="12" className="form-group">
                    <label htmlFor="Consumption">Transferir a</label>
                    <FormSelect
                      value={this.state.transfer_kam}
                      onChange={(e) =>
                        this.setState({ transfer_kam: e.target.value })
                      }
                      required
                    >
                      <option value="">---</option>
                      {this.props.users.list.map((user, id) => (
                        <option key={id} value={user.id}>
                          {user.username}
                        </option>
                      ))}
                    </FormSelect>
                  </Col>
                  <Col lg="12" className="form-group">
                    <Button type="submit" className="col-lg-12">
                      Solicitar Transferencia
                    </Button>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal>

          <Modal open={this.state.file_open} toggle={this.toggle_file}>
            <ModalHeader>Agregar Documento</ModalHeader>
            <ModalBody>
              <Form onSubmit={this.submit_file}>
                <Row>
                  <Col lg="12" className="form-group">
                    <label>Archivo</label>
                    <FormInput
                      type="file"
                      onChange={this.fileChangeHandler}
                      required
                    />
                    <label htmlFor="Consumption">Nombre del archivo</label>
                    <FormInput
                      type="text"
                      value={this.state.file_name}
                      onChange={(e) =>
                        this.setState({ file_name: e.target.value })
                      }
                      required
                    />
                  </Col>
                  <Col lg="12" className="my-4">
                    <Button type="submit" className="col-lg-12">
                      Agregar Archivo
                    </Button>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal>

          <Modal open={this.state.contact_open} toggle={this.toggle_contact}>
            <ModalHeader>Agregar Contacto</ModalHeader>
            <ModalBody>
              <Form onSubmit={this.submit_contact}>
                <Row>
                  <Col md="12" className="form-group">
                    <label htmlFor="Consumption">Nombre</label>
                    <FormInput
                      id="company-name"
                      type="text"
                      value={this.state.contact_name}
                      onChange={(e) =>
                        this.setState({ contact_name: e.target.value })
                      }
                      required
                    />
                  </Col>
                  <Col md="12" className="form-group">
                    <label htmlFor="Consumption">Correo</label>
                    <FormInput
                      id="company-name"
                      type="text"
                      value={this.state.email}
                      onChange={(e) => this.setState({ email: e.target.value })}
                      required
                    />
                  </Col>
                  <Col md="12" className="form-group">
                    <label htmlFor="Consumption">Posición</label>
                    <FormInput
                      id="company-name"
                      type="text"
                      value={this.state.position}
                      onChange={(e) =>
                        this.setState({ position: e.target.value })
                      }
                      required
                    />
                  </Col>
                  <Col md="12" className="form-group">
                    <label htmlFor="Consumption">Teléfono</label>
                    <FormInput
                      id="company-name"
                      type="text"
                      value={this.state.phone}
                      onChange={(e) => this.setState({ phone: e.target.value })}
                      required
                    />
                  </Col>
                  <Col lg="12" className="my-4">
                    <Button type="submit" className="col-lg-12">
                      Crear Contacto
                    </Button>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal>

          <Modal
            open={this.state.contact_edit_open}
            toggle={this.toggle_edit_contact}
          >
            <ModalHeader>Editar Contacto</ModalHeader>
            <ModalBody>
              <Form onSubmit={this.submit_edit_contact}>
                <Row>
                  <Col md="12" className="form-group">
                    <label htmlFor="Consumption">Nombre</label>
                    <FormInput
                      id="company-name"
                      type="text"
                      value={this.state.edit_contact_name}
                      onChange={(e) =>
                        this.setState({ edit_contact_name: e.target.value })
                      }
                      required
                    />
                  </Col>
                  <Col md="12" className="form-group">
                    <label htmlFor="Consumption">Correo</label>
                    <FormInput
                      id="company-name"
                      type="text"
                      value={this.state.edit_email}
                      onChange={(e) =>
                        this.setState({ edit_email: e.target.value })
                      }
                      required
                    />
                  </Col>
                  <Col md="12" className="form-group">
                    <label htmlFor="Consumption">Posición</label>
                    <FormInput
                      id="company-name"
                      type="text"
                      value={this.state.edit_position}
                      onChange={(e) =>
                        this.setState({ edit_position: e.target.value })
                      }
                      required
                    />
                  </Col>
                  <Col md="12" className="form-group">
                    <label htmlFor="Consumption">Teléfono</label>
                    <FormInput
                      id="company-name"
                      type="text"
                      value={this.state.edit_phone}
                      onChange={(e) =>
                        this.setState({ edit_phone: e.target.value })
                      }
                      required
                    />
                  </Col>
                  <Col lg="12" className="my-4">
                    <Button type="submit" className="col-lg-12">
                      Guardar Contacto
                    </Button>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal>
        </Container>
      </>
    );
    return container;
  }
}
/**
 * Función que mapea el estado a las props del componente
 * @param {Object} state - Estado actual de la aplicación
 * @returns {Object} - Objeto que contiene las props mapeadas al estado
 */
const mapStateToProps = (state) => {
  return {
    customers: state.customers,
    quoteSimulations: state.quoteSimulations,
    cmdtSimulations: state.simulations,
    users: state.users,
  };
};
/**
 * Función que mapea las acciones a las props del componente
 * @param {Function} dispatch - Función de redux para despachar acciones
 * @returns {Object} - Objeto que contiene las props mapeadas a las acciones
 */
const mapDispatchToProps = (dispatch) => {
  return {
    fetch_customer: (id) => {
      return dispatch(customers.fetch_customer(id));
    },
    update_customer: (id, data) => {
      return dispatch(customers.update_customer(id, data));
    },
    fetch_users: () => {
      dispatch(users.fetch_users());
    },
    create_visit: (data) => {
      return dispatch(customers.create_visit(data));
    },
    create_file: (data) => {
      return dispatch(customers.create_file(data));
    },
    transfer_customer: (data) => {
      return dispatch(customers.transfer_customer(data));
    },
    fetchQuoteSimulations: (contractId) => {
      dispatch(quoteSimulations.fetchSimulations(contractId));
    },
    createSimulation: (contractId) => {
      dispatch(quoteSimulations.createSimulation(contractId));
    },
    deleteSimulation: (simulation_id) => {
      dispatch(quoteSimulations.deleteSimulation(simulation_id));
    },
    fetchCmdtSimulations: (contractId) => {
      dispatch(simulations.fetchSimulations(contractId));
    },
    createCmdtSimulation: (contractId) => {
      dispatch(simulations.createSimulation(contractId));
    },
    deleteCmdtSimulation: (simulation_id) => {
      dispatch(simulations.deleteSimulation(simulation_id));
    },
    submit_contact: (data) => {
      return dispatch(customers.submit_contact(data));
    },
    update_contact: (id, index, data) => {
      return dispatch(customers.update_contact(id, index, data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerDetail);
