import React from "react";
import {Route, Redirect} from "react-router-dom";
import {connect} from "react-redux";

/**
 * Componente GroupRoute que renderiza una ruta solo si el usuario autenticado pertenece a uno de los grupos especificados.
 * @name GroupRoute
 * @param {object} props - Props del componente
 * @param {React.Component} props.component - Componente a renderizar
 * @param {object} props.auth - Estado de autenticación
 * @param {array} props.groups - Grupos permitidos para el acceso a la ruta
 * @param {string} props.g - Grupo permitido para el acceso a la ruta (obsoleto)
 * @param {object} rest - Props adicionales a pasar a la ruta
 * @returns {React.Component} - Componente de la ruta
*/

const GroupRoute = ({component: Component, auth, groups, g, ...rest}) => (
    <Route
        {...rest}
        render={props => {
            if (auth.isLoading) {
                return <h2>Loading...</h2>;
            } else if (auth.isAuthenticated) {
                console.log('????');
                console.log(auth.user.groups);
                console.log(groups);
                console.log(rest)
                let intersections = groups.filter(e => auth.user.groups.indexOf(e) !== -1);

                if (groups.length === 0 || intersections.length > 0) {
                    return <Component {...props} />;
                } else {
                    return (
                        <Redirect
                            to={{
                                pathname: '/',
                                state: {originPath: props.match.path}
                            }}
                        />
                    );
                }

            } else {
                return (
                    <Redirect
                        to={{
                            pathname: '/login',
                            state: {originPath: props.match.path}
                        }}
                    />
                );
            }
        }}
    />
);

/**
 * Función que mapea el estado de autenticación del store de Redux a las props del componente.
 * @param {object} state - Estado global de la aplicación en Redux
 * @returns {object} - Props del componente
*/

const mapStateToProps = state => ({
    auth: state.auth,
});

export default connect(mapStateToProps)(GroupRoute);