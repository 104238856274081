import React, {Component} from "react";
import {
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    FormInput, InputGroup,
    InputGroupAddon,
    InputGroupText,
    Row
} from "shards-react";

import PageTitle from "./../components/common/PageTitle";

import {connect} from "react-redux";
import {recurrent} from "../actions"
import {Link} from "react-router-dom";
import UpdateRecurrentMaterial from "../components/recurrent-materials/UpdateRecurrentMaterial";

/**
 * Componente React que muestra los materiales recurrentes y permite filtrarlos por nombre.
 * @class
 * @extends Component
 * @property {Object} props - Propiedades para el componente.
 * @property {Function} props.fetch_recurrent_files - Función que obtiene los materiales recurrentes.
 * @property {Array} props.recurrent_files - Arreglo de objetos que representan los materiales recurrentes.
 * @property {Object} props.user - Objeto que representa el usuario actual.
 * @property {Object} state - Estado del componente.
 * @property {String} state.search - Texto de búsqueda para filtrar los materiales recurrentes.
 * @property {Array} state.sections - Arreglo de objetos que representan las secciones de materiales recurrentes.
 * @property {Array} state.original_sections - Arreglo de objetos que representan las secciones de materiales recurrentes sin filtrar.
 * @function constructor
 * @param {Object} props - Propiedades para el componente.
 * @returns {void}
 * @function componentDidMount
 * @returns {void}
 * @function filter_files
 * @param {String} value - Texto de búsqueda para filtrar los materiales recurrentes.
 * @returns {void}
 * @function render
 * @returns {JSX.Element} Elemento JSX que representa el componente.
*/

class RecurrentFiles extends Component {

    constructor(props) {
        super(props);
        this.state = {
            search: "",
            sections: [],
            original_sections: []
        };
    }

    componentDidMount() {
        this.props.fetch_recurrent_files().then(() => {
            let sections = [];
            this.props.recurrent_files.map((section, index) => {
                sections[index] = {
                    id: section.id,
                    name: section.name,
                    files: section.recurrent_materials,
                    filtered_files: section.recurrent_materials,
                };
                return null
            });
            this.setState({sections: sections, original_sections: sections})
        });
    }
/**
 * Función que filtra los materiales recurrentes según el texto de búsqueda.
 * 
 * @function
 * @param {String} value - Texto de búsqueda para filtrar los materiales recurrentes.
 * @returns {void}
*/
    filter_files = (value) => {
        const search_text = value.toLowerCase();
        let current_sections = this.state.original_sections;
        let new_sections = [];
        current_sections.map((section, index) => {
            let new_section = section;
            let filtered_files = [];
            section.files.map((file, index) => {
                if (file.file_name.toLowerCase().includes(search_text)) {
                    filtered_files.push(file);
                }
                return null
            })
            new_section.filtered_files = filtered_files;
            if (new_section.filtered_files.length > 0) {
                new_sections[index] = new_section;
            }

            return null
        });
        this.setState({sections: new_sections});
    };
/**
 * Función que renderiza el componente.
 * 
 * @function
 * @returns {JSX.Element} Elemento JSX que representa el componente.
*/
    render() {
        const {sections} = this.state;
        console.log('USER', this.props.user.groups);
        const hasGroup = this.props.user.groups.includes('update_materials');
        console.log('HAS GROUP', hasGroup);

        let container = <><Container fluid className="main-content-container px-4">
            {/* Page Header */}
            <Row noGutters className="page-header py-4">
                <PageTitle title="Materiales Recurrentes" className="text-sm-left mb-3"/>
            </Row>
            {(() => {
                if (hasGroup) {
                    return <Row>
                        <Col>
                            <UpdateRecurrentMaterial/>
                        </Col>
                    </Row>
                }
            })()}


            <Row className="my-4">
                <Col>
                    <InputGroup seamless className="mb-3">
                        <FormInput
                            type="text"
                            onChange={(e) => {
                                this.filter_files(e.target.value)
                            }}
                        />
                        <InputGroupAddon type="append">
                            <InputGroupText>
                                <i className="material-icons">search</i>
                            </InputGroupText>
                        </InputGroupAddon>
                    </InputGroup>
                </Col>
            </Row>
            <Row>
                {sections.map((section, index) => (
                    <Col lg="6" className="my-4" key={`section_${section.id}`}>
                        <Card small className="">
                            <CardHeader className="border-bottom">
                                <i className={`icon icon-${section.name.toLowerCase()} header-icon`}></i>
                                <h6 className="m-0">{section.name}</h6>
                            </CardHeader>
                            <CardBody className="d-flex flex-column">
                                <div className="table-responsive">
                                    <table className="table mb-0 simulation_results">
                                        <thead className="bg-light">
                                        <tr>
                                            <th>Archivo</th>
                                            <th>Descargar</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {section.filtered_files.map((file, id) => (
                                            <tr key={`file_${file.id}`}>
                                                <td>{file.file_name}</td>
                                                <td><Link to={`/files/download/${file.id}/`} className="btn btn-primary"
                                                          target="_blank" rel="noopener noreferrer"
                                                          download>Descargar</Link></td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                ))}

            </Row>
        </Container></>;
        return container
    }

}
/**
 * Función que mapea el estado a las props del componente
 * @param {Object} state - Estado actual de la aplicación
 * @returns {Object} - Objeto que contiene las props mapeadas al estado
*/
const mapStateToProps = state => {
    return {
        recurrent_files: state.recurrent,
        user: state.auth.user,
    }
};
/**
 * Función que mapea las acciones a las props del componente
 * @param {Function} dispatch - Función de redux para despachar acciones
 * @returns {Object} - Objeto que contiene las props mapeadas a las acciones
*/
const mapDispatchToProps = dispatch => {
    return {
        fetch_recurrent_files: () => {
            return dispatch(recurrent.fetch_recurrent_files())
        },
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(RecurrentFiles);
