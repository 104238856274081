import React from "react";
import classNames from "classnames";
import {
  InputGroup,
  DatePicker,
  InputGroupAddon,
  InputGroupText
} from "shards-react";

import "../../assets/range-date-picker.css";

/**
 * Componente que muestra un rango de fechas seleccionables mediante datepickers.
 * @name RangeDatePicker
 * @extends React.Component
*/

class RangeDatePicker extends React.Component {
/**
 * Crea una instancia del componente RangeDatePicker.
 * @param {Object} props - Propiedades del componente.
 * @param {string} props.className - Clases CSS para el componente.
*/
  constructor(props) {
    super(props);
/**
 * Estado del componente.
 * @type {Object}
 * @property {Date|undefined} startDate - Fecha de inicio del rango seleccionado.
 * @property {Date|undefined} endDate - Fecha de fin del rango seleccionado.
*/
    this.state = {
      startDate: undefined,
      endDate: undefined
    };

    this.handleStartDateChange = this.handleStartDateChange.bind(this);
    this.handleEndDateChange = this.handleEndDateChange.bind(this);
  }
/**
 * Actualiza el estado del componente con la fecha de inicio del rango seleccionado.
 * @param {string} value - Valor seleccionado en el datepicker.
*/
  handleStartDateChange(value) {
    this.setState({
      ...this.state,
      ...{ startDate: new Date(value) }
    });
  }
/**
 * Actualiza el estado del componente con la fecha de fin del rango seleccionado.
 * @param {string} value - Valor seleccionado en el datepicker.
*/
  handleEndDateChange(value) {
    this.setState({
      ...this.state,
      ...{ endDate: new Date(value) }
    });
  }
/**
 * Renderiza el componente.
 * @returns {JSX.Element} Componente de datepickers para seleccionar un rango de fechas.
*/
  render() {
    const { className } = this.props;
    const classes = classNames(className, "d-flex", "my-auto", "date-range");

    return (
      <InputGroup className={classes}>
        <DatePicker
          size="sm"
          selected={this.state.startDate}
          onChange={this.handleStartDateChange}
          placeholderText="Start Date"
          dropdownMode="select"
          className="text-center"
        />
        <DatePicker
          size="sm"
          selected={this.state.endDate}
          onChange={this.handleEndDateChange}
          placeholderText="End Date"
          dropdownMode="select"
          className="text-center"
        />
        <InputGroupAddon type="append">
          <InputGroupText>
            <i className="material-icons">&#xE916;</i>
          </InputGroupText>
        </InputGroupAddon>
      </InputGroup>
    );
  }
}

export default RangeDatePicker;
