const initialState = {file: null, customer2_reports: []};

/**
 * Función que maneja los cambios en el estado de los informes.
 * @param {Object} state - El estado actual de los informes.
 * @param {Object} action - La acción que se va a realizar.
 * @param {string} action.type - El tipo de acción a realizar.
 * @param {Object} action.report - El informe a crear.
 * @param {Object} action.data - Los informes del cliente 2.
 * @returns {Object} El nuevo estado de los informes.
*/

export default function customers(state=initialState, action) {
  // let contractList = state.slice();

  switch (action.type) {

    case 'CREATE_REPORT':
      return {...state, file: action.report};

    case 'CUSTOMER_2_REPORTS':
      return {...state, customer2_reports: action.data}
    default:
      return state;
  }
}
