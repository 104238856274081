import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import {Col} from "shards-react";

import SidebarMainNavbar from "./SidebarMainNavbar";
import SidebarNavItems from "./SidebarNavItems";

import {Store} from "../../../flux";

/**
 * Componente de la barra lateral principal.
 * @name MainSidebar
 * @component
 * @param {Object} props - Las props de este componente.
 * @param {boolean} [props.hideLogoText=false] - Indica si se debe ocultar el texto del logotipo o no.
 * @returns {JSX.Element} El componente de la barra lateral principal.
*/

class MainSidebar extends React.Component {
  /**
 * Crea una instancia del componente MainSidebar.
 * @constructor
 * @param {Object} props - Las props de este componente.
*/
  constructor(props) {
    super(props);

    this.state = {
      menuVisible: false,
      sidebarNavItems: Store.getSidebarItems()
    };

    this.onChange = this.onChange.bind(this);
  }
/**
 * Se llama cuando el componente ha sido montado.
*/
  componentDidMount() {
    Store.addChangeListener(this.onChange);
  }
/**
 * Se llama cuando el componente está a punto de ser desmontado.
*/
  componentWillUnmount() {
    Store.removeChangeListener(this.onChange);
  }
/**
 * Actualiza el estado del componente cuando cambia la tienda.
*/
  onChange() {
    this.setState({
      ...this.state,
      menuVisible: Store.getMenuState(),
      sidebarNavItems: Store.getSidebarItems()
    });
  }
/**
 * Renderiza el componente MainSidebar.
 * @returns {JSX.Element} El componente de la barra lateral principal.
*/
  render() {
    const classes = classNames(
        "main-sidebar",
        "px-0",
        "col-12",
        this.state.menuVisible && "open"
    );

    return (
        <Col
            tag="aside"
            className={classes}
            lg={{ size: 2 }}
            md={{ size: 3 }}
        >
          <SidebarMainNavbar hideLogoText={this.props.hideLogoText} />
          <SidebarNavItems />
        </Col>
    );
  }
}

MainSidebar.propTypes = {
  /**
   * Whether to hide the logo text, or not.
   */
  hideLogoText: PropTypes.bool
};

MainSidebar.defaultProps = {
  hideLogoText: false
};

export default MainSidebar;
