const initialState = {list: [], detail: {company_name: ""}, customer: null, visits: [], files: [], polls: [], pending_transfers: [], transferred_customers: [], contacts: []};

/**
 * La función representa el reducer de customers, que maneja el estado relacionado con los clientes.
 * @name customers
 * @param {Object} state - El estado actual de la aplicación.
 * @param {Object} action - La acción que se va a realizar sobre el estado.
*/

export default function customers(state=initialState, action) {
  // let contractList = state.slice();

  switch (action.type) {

    case 'FETCH_CUSTOMERS':
      return {...state, list: action.customers};

    case 'FETCH_TRANSFERRED_CUSTOMERS':
      return {...state, transferred_customers: action.transferred_customers};
    case 'FETCH_CUSTOMER':
      return {...state, detail: action.customer, visits: action.customer.visits, files: action.customer.files,
        polls: action.customer.polls, contacts: action.customer.contacts};

    case 'UPDATE_CUSTOMER':
      return {...state, detail: action.customer};

    case 'CREATE_CUSTOMER':
      return {...state, list: [...state.list, action.customer]};

    case 'CREATE_VISIT':
      return {...state, visits: [...state.visits, action.visit]};
    case 'SUBMIT_CUSTOMER_FILE':

      return {...state, files: [...state.files, action.file]};

    case 'TRANSFER_CUSTOMER':
      return {...state};

    case 'UPDATE_TRANSFER_CUSTOMER':
      return {...state};

    case 'FETCH_PENDING_TRANSFERS':
      return {...state, pending_transfers: action.pending_transfers};

    case 'SUBMIT_CONTACT':
      return {...state, contacts: [...state.contacts, action.contact]};

    case 'UPDATE_CONTACT':
      const index = parseInt(action.index);
      let contacts = [...state.contacts];
      contacts[index] = action.contact;
      return {...state, contacts: contacts};
      // return {...state, contacts: [...state.contacts, action.contact]};
    default:
      return state;
  }
}
