import React from "react";
import {
  InputGroup,
  FormInput,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "shards-react";

/**
 * Clase para un componente React que muestra un conjunto de inputs con dropdowns asociados.
 * @name DropdownInputGroups
 * @extends React.Component
*/

class DropdownInputGroups extends React.Component {
  /**
 * Constructor de la clase.
 * @param {object} props - Propiedades del componente.
*/
  constructor(props) {
    super(props);
/**
 * Método para cambiar el estado de un dropdown.
 * @param {string} which - Indica qué dropdown cambiar.
*/
    this.toggle = this.toggle.bind(this);
/**
 * Estado inicial del componente.
 * @type {object}
 * @property {boolean} dropdown1 - Indica si el primer dropdown está abierto o cerrado.
 * @property {boolean} dropdown2 - Indica si el segundo dropdown está abierto o cerrado.
*/
    this.state = {
      dropdown1: false,
      dropdown2: false
    };
  }
/**
 * Método que cambia el estado de un dropdown.
 * @param {string} which - Indica qué dropdown cambiar.
*/
  toggle(which) {
    const newState = { ...this.state };
    newState[which] = !this.state[which];
    this.setState(newState);
  }
/**
 * Método que renderiza el componente.
 * @returns {ReactElement} Elemento de React que representa el componente.
*/
  render() {
    return (
      <div>
        <InputGroup className="mb-3">
          <FormInput />
          <Dropdown
            open={this.state.dropdown1}
            toggle={() => this.toggle("dropdown1")}
            addonType="append"
          >
            <DropdownToggle caret>Dropdown</DropdownToggle>
            <DropdownMenu small right>
              <DropdownItem>Action</DropdownItem>
              <DropdownItem>Another action</DropdownItem>
              <DropdownItem>Something else here</DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </InputGroup>

        <InputGroup className="mb-3">
          <Dropdown
            open={this.state.dropdown2}
            toggle={() => this.toggle("dropdown2")}
            addonType="prepend"
          >
            <DropdownToggle caret>Dropdown</DropdownToggle>
            <DropdownMenu small>
              <DropdownItem>Action</DropdownItem>
              <DropdownItem>Another action</DropdownItem>
              <DropdownItem>Something else here</DropdownItem>
            </DropdownMenu>
          </Dropdown>
          <FormInput />
        </InputGroup>
      </div>
    );
  }
}

export default DropdownInputGroups;
