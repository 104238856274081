import axios from "axios";

/**
 * Función que realiza una petición GET para obtener todas las simulaciones de un cliente.
 * @name fetchSimulation
 * @param {number} customer_id - Identificador del cliente.
 * @returns {Function} - Función que realiza la petición GET.
 */

export const fetchSimulations = (customerId) => {
    return (dispatch, getState) => {
        let headers = {"Content-Type": "application/json"};
        let {token} = getState().auth;

        if (token) {
            headers["Authorization"] = `Token ${token}`;
        }

        return axios
            .get(`/api/simulating/?customer=${customerId}`, {headers})
            .then((res) => {
                return dispatch({
                    type: "FETCH_CMDT_SIMULATIONS",
                    simulations: res.data,
                });
            })
            .catch((err) => {
                console.log("ERROR");
                console.log(err);
                console.log(err.response.status);
            });
    };
};

/**
 * Función que realiza una petición GET para obtener una simulación de FAI específica.
 * @name fetchFAISimulation
 * @param {number} simulation_id - Identificador de la simulación FAI.
 * @returns {Function} - Función que realiza la petición GET.
 */

export const fetchFAISimulation = (simulation_id) => {
    return (dispatch, getState) => {
        let headers = {"Content-Type": "application/json"};
        let {token} = getState().auth;

        if (token) {
            headers["Authorization"] = `Token ${token}`;
        }

        return axios
            .get(`/api/v2/simulation/fai/${simulation_id}/`, {headers})
            .then((res) => {
                return dispatch({type: "FETCH_FAI_SIMULATION", simulation: res.data});
            })
            .catch((err) => {
                console.log("ERROR");
                console.log(err);
                console.log(err.response.status);
            });
    };
};

export const createSimulation = (contract_id) => {
    return (dispatch, getState) => {
        let headers = {"Content-Type": "application/json"};
        let {token} = getState().auth;

        if (token) {
            headers["Authorization"] = `Token ${token}`;
        }

        let body = JSON.stringify({
            customer: contract_id,
        });

        return axios
            .post(`/api/simulating/`, body, {headers})
            .then((res) => {
                return dispatch({
                    type: "CREATE_CMDT_SIMULATION",
                    simulation: res.data,
                });
            })
            .catch((err) => {
                console.log("ERROR");
                console.log(err);
                console.log(err.response.status);
            });
    };
};

export const updateSimulation = (simulation_id, values) => {
    return (dispatch, getState) => {
        let headers = {"Content-Type": "application/json"};
        let {token} = getState().auth;

        if (token) {
            headers["Authorization"] = `Token ${token}`;
        }

        let body = JSON.stringify({
            month: values.month,
            monthly_consumption: values.monthly_consumption,
            cmd: values.cmd,
            spread: values.spread,
            management_cost: values.management_cost,
            region: values.region,
            index: values.index,
            tariff: values.tariff,
            additional_fields: values.additional_fields,
        });

        return axios
            .patch(`/api/simulating/${simulation_id}/`, body, {headers})
            .then((res) => {
                return dispatch({
                    type: "UPDATE_CMDT_SIMULATION",
                    simulation: res.data,
                });
            })
            .catch((err) => {
                console.log("ERROR");
                console.log(err);
                console.log(err.response);
            });
    };
};

export const deleteSimulation = (simulation_id) => {
    return (dispatch, getState) => {
        let headers = {"Content-Type": "application/json"};
        let {token} = getState().auth;

        if (token) {
            headers["Authorization"] = `Token ${token}`;
        }

        return axios
            .delete(`/api/simulating/${simulation_id}/`, {headers})
            .then((res) => {
                return dispatch({
                    type: "DELETE_CMDT_SIMULATION",
                    simulation: simulation_id,
                });
            })
            .catch((err) => {
                console.log("ERROR");
                console.log(err);
                console.log(err.response.status);
            });
    };
};

/**
 * Función que realiza una petición GET para obtener los datos de simulación de CMDT.
 * @name fetchCMDTimulation
 * @function
 * @param {number} simulation_id - ID de la simulación.
 * @returns {Promise} Promesa que resuelve con los datos de simulación de CMDT.
 */

export const fetchCMDTSimulation = (simulation_id) => {
    return (dispatch, getState) => {
        let headers = {"Content-Type": "application/json"};
        let {token} = getState().auth;

        if (token) {
            headers["Authorization"] = `Token ${token}`;
        }

        return axios
            .get(`/api/simulating/${simulation_id}`, {headers})
            .then((res) => {
                console.log("Simulacion", res);
                return dispatch({
                    type: "FETCH_CMDT_SIMULATION",
                    simulation: res.data,
                });
            })
            .catch((err) => {
                console.log("ERROR");
                console.log(err);
                console.log(err.response.status);
            });
    };
};

/**
 * Función que realiza una petición GET para obtener los datos de simulación de Pricing Steps.
 * @name fetchPricingStepssimulation
 * @function
 * @param {number} simulation_id - ID de la simulación.
 * @returns {Promise} Promesa que resuelve con los datos de simulación de Pricing Steps.
 */

export const fetchPricingStepssimulation = (simulation_id) => {
    return (dispatch, getState) => {
        let headers = {"Content-Type": "application/json"};
        let {token} = getState().auth;

        if (token) {
            headers["Authorization"] = `Token ${token}`;
        }

        return axios
            .get(`/api/v2/simulation/pss/${simulation_id}/`, {headers})
            .then((res) => {
                return dispatch({type: "FETCH_PSS_SIMULATION", simulation: res.data});
            })
            .catch((err) => {
                console.log("ERROR");
                console.log(err);
                console.log(err.response.status);
            });
    };
};

/**
 * Realiza una solicitud GET a la API para obtener la información de una simulación.
 * con los parámetros de swing e interruptibilidad dados.
 * @name fetchSwingAndInterruptible
 * @param {string} simulation_id - El id de la simulación deseada.
 * @returns {Promise} - Retorna una promesa que se resuelve en un objeto de acción
 * para actualizar la información de la simulación en el estado.
 */

export const fetchSwingAndInterruptible = (simulation_id) => {
    return (dispatch, getState) => {
        let headers = {"Content-Type": "application/json"};
        let {token} = getState().auth;

        if (token) {
            headers["Authorization"] = `Token ${token}`;
        }

        return axios
            .get(`/api/v2/simulation/sai/${simulation_id}/`, {headers})
            .then((res) => {
                return dispatch({type: "FETCH_SAI_SIMULATION", simulation: res.data});
            })
            .catch((err) => {
                console.log("ERROR");
                console.log(err);
                console.log(err.response.status);
            });
    };
};

/**
 * Realiza una solicitud PATCH a la API para actualizar la información de una simulación
 * con los nuevos datos proporcionados.
 * @name updateFAI
 * @param {string} simulation_id - El id de la simulación a actualizar.
 * @param {Object} data - El objeto de datos a enviar en la solicitud de actualización.
 * @returns {Promise} - Retorna una promesa que se resuelve en un objeto de acción
 * para actualizar la información de la simulación en el estado.
 */

export const updateFAI = (simulation_id, data) => {
    return (dispatch, getState) => {
        let headers = {"Content-Type": "application/json"};
        let {token} = getState().auth;

        if (token) {
            headers["Authorization"] = `Token ${token}`;
        }

        return axios
            .patch(`/api/v2/simulation/fai/${simulation_id}/`, data, {headers})
            .then((res) => {
                return dispatch({
                    type: "UPDATE_FAI_SIMULATION",
                    simulation: res.data,
                });
            })
            .catch((err) => {
                console.log("ERROR");
                console.log(err);
                console.log(err.response.status);
            });
    };
};

export const fetchOptions = () => {
    return (dispatch, getState) => {
        let headers = {"Content-Type": "application/json"};
        let {token} = getState().auth;

        if (token) {
            headers["Authorization"] = `Token ${token}`;
        }

        return axios
            .get(`/api/options/`, {headers})
            .then((res) => {
                return dispatch({type: "FETCH_CMDT_OPTIONS", options: res.data});
            })
            .catch((err) => {
                console.log("ERROR");
                console.log(err);
                console.log(err.response.status);
            });
    };
};

/**
 * Función que actualiza los valores diarios.
 * @function
 * @name updateDailyValues
 * @returns {Function} - Retorna una función que dispara una acción de Redux.
 * @param {Function} dispatch - Función dispatch de Redux.
 * @param {Function} getState - Función getState de Redux.
 * @throws {Error} - Lanza un error si no se puede actualizar los valores diarios.
 */

export const updateDailyValues = (simulation_id, cmd, file) => {
    return (dispatch, getState) => {
        let headers = {"Content-Type": "multipart/form-data"};
        let {token} = getState().auth;

        if (token) {
            headers["Authorization"] = `Token ${token}`;
        }

        console.log(file);

        let body = new FormData();
        body.append("simulations_simulator", simulation_id);
        body.append("cmd", cmd);
        body.set("file", file);

        return axios
            .post(`/api/upload_simulation_days/`, body, {
                headers: {...headers},
            })
            .then((res) => {
                return dispatch({
                    type: "UPDATE_DAILY_VALUES_CMDT",
                    day: [],
                });
            })
            .catch((err) => {
                console.log("ERROR");
                console.log(err);
                console.log(err.response);
            });
    };
};

/**
 * Función que actualiza los datos a partir de un archivo.
 * @function
 * @name update_from_file
 * @param {Object} data - Objeto que contiene la información del archivo a actualizar.
 * @param {Object} data.file - Archivo a actualizar.
 * @returns {Function} - Retorna una función que dispara una acción de Redux.
 * @param {Function} dispatch - Función dispatch de Redux.
 * @param {Function} getState - Función getState de Redux.
 * @throws {Error} - Lanza un error si no se puede actualizar los datos a partir del archivo.
 */

export const update_from_file = (data) => {
    return (dispatch, getState) => {
        let headers = {};
        let {token} = getState().auth;

        if (token) {
            headers["Authorization"] = `Token ${token}`;
        }

        let body = new FormData();
        // let extension = data.file.name.split('.').pop();
        body.append("file", data.file);

        return fetch("/api/update_data/", {headers, body, method: "POST"})
            .then(res => {
                if (res.status < 500) {
                    return res.json().then(data => {
                        return {status: res.status, data};
                    })
                } else {
                    console.log("Server Error!");
                    throw res;
                }
            })
            .then(res => {
                if (res.status === 200 || res.status === 201) {
                    dispatch({type: 'UPDATE_FROM_FILE', data: res.data});
                    return res.data;
                } else if (res.status === 403 || res.status === 401) {
                    dispatch({type: "AUTHENTICATION_ERROR", data: res.data});
                    throw res.data;
                } else {
                    dispatch({type: "UPDATE_FROM_FILE_FAILED", data: res.data});
                    throw res.data;
                }
            })

        /*
                return axios
                    .post("/api/update_data/", body, {headers})
                    .then((res) => {
                        return dispatch({type: "UPDATE_FROM_FILE", file: res.data});
                    })
                    .catch((err) => {
                        console.log("ERROR");
                        console.log(err);
                        console.log(err.response.status);
                        if (err.response.status === 401) {
                            let error = {errors: "Sesión expirada, inicie sesión nuevamente"};
                            dispatch({type: "AUTHENTICATION_ERROR", data: error});
                        }
                    });
                    */
    };
};
