import React from "react";
import PropTypes from "prop-types";
import { NavLink as RouteNavLink } from "react-router-dom";
import { NavItem, NavLink } from "shards-react";

/**
 * Componente que representa un elemento de navegación en la barra lateral de la aplicación.
 * @name SidebarNavItem
 * @param {Object} item - Objeto que contiene información del item de navegación.
 * @param {string} item.to - Ruta hacia la que se dirige el enlace del item.
 * @param {string} item.htmlBefore - Contenido HTML que se renderiza antes del título del item.
 * @param {string} item.title - Título del item.
 * @param {string} item.htmlAfter - Contenido HTML que se renderiza después del título del item.
 * @returns {JSX.Element} Elemento de React que representa el componente.
*/

const SidebarNavItem = ({ item }) => (
  <NavItem>
    <NavLink tag={RouteNavLink} to={item.to}>
      {item.htmlBefore && (
        <div
          className="d-inline-block item-icon-wrapper"
          dangerouslySetInnerHTML={{ __html: item.htmlBefore }}
        />
      )}
      {item.title && <span>{item.title}</span>}
      {item.htmlAfter && (
        <div
          className="d-inline-block item-icon-wrapper"
          dangerouslySetInnerHTML={{ __html: item.htmlAfter }}
        />
      )}
    </NavLink>
  </NavItem>
);

SidebarNavItem.propTypes = {
  /**
   * The item object.
   */
  item: PropTypes.object
};

export default SidebarNavItem;
