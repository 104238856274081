/**
 * Objeto con el estado inicial del estado de clientesV2
 * @typedef {Object} InitialCustomersV2State
 * @property {Array} list - Lista de clientes
 * @property {Object|null} detail - Detalles de un cliente específico
 * @property {Array} by_status - Lista de clientes filtrada por estado
 * @property {Array|null} all - Lista de todos los clientes
*/
const initialState = {list: [], detail: null, by_status: [], all: null};

/**
 * Función reducer que maneja el estado de clientesV2
 * @name customersV2
 * @param {InitialCustomersV2State} state - Estado actual de clientesV2
 * @param {Object} action - Objeto que contiene la acción y los datos asociados
 * @returns {InitialCustomersV2State} Nuevo estado de clientesV2
*/
export default function customersV2(state = initialState, action) {

    switch (action.type) {

        case 'FETCH_CUSTOMERS_V2':
            return {...state, list: action.customers};
        case 'FETCH_ALL_CUSTOMERS':
            return {...state, all: action.customers};
        case 'FETCH_CUSTOMERS_V2_STATUS':
            return {...state, by_status: action.customers};
        case 'FETCH_CUSTOMER_V2':
            return {...state, detail: action.customer};

        case 'UPDATE_CUSTOMER_STORAGE':
            return {...state, detail: action.customer_storage.customer}
        case 'CREATE_CUSTOMER':
            return {...state, list: [action.customer, ...state.list]}
        default:
            return state;
    }
}
