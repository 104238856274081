import React, {Component} from "react";
import {Button, Card, CardBody, CardHeader} from "shards-react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";

/**
 * Clase que representa una tarjeta que muestra el estado de un cliente.
 * @name CustomerStatusCard
 * @extends Component
*/
class CustomerStatusCard extends Component {
/**
 * Renderiza la tarjeta con la información del estado del cliente.
 * @returns {JSX.Element} Elemento JSX que representa la tarjeta del estado del cliente.
*/

    render() {
        let {status} = this.props;
        console.log('IN CSS', status)
        return (
            <Card small className="">
                <CardHeader className="border-bottom">
                    <i className="icon icon-altacliente header-icon"></i>
                    <h6 className="m-0">{status.name}</h6>
                </CardHeader>
                <CardBody className="d-flex flex-column">
                    <table className="table mb-0">
                        <thead className="bg-light">
                        <tr>
                            <th scope="col" className="border-0">
                                Nombre
                            </th>
                            <th scope="col" className="border-0">
                                Ver
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {status.customers.map((customer, id) => (
                            <tr key={customer.id}>
                                <td>{customer.name}</td>
                                <td><Link to={`/clients/${customer.id}`}><Button>Ver</Button></Link></td>
                            </tr>
                        ))}

                        </tbody>
                    </table>
                </CardBody>
            </Card>

        )
    }
}
/**
 * Función que mapea el estado a las props del componente
 * @param {Object} state - Estado actual de la aplicación
 * @returns {Object} - Objeto que contiene las props mapeadas al estado
*/
const mapStateToProps = state => {
    return {};
};
/**
 * Función que mapea las acciones a las props del componente
 * @param {Function} dispatch - Función de redux para despachar acciones
 * @returns {Object} - Objeto que contiene las props mapeadas a las acciones
*/
const mapDispatchToProps = dispatch => {
    return {}
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerStatusCard);
