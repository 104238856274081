/**
 * Objeto con el estado inicial del estado de contratos
 * @typedef {Object} InitialContractsState
 * @property {Array} list - Lista de contratos
 * @property {Object|null} detail - Detalles de un contrato específico
*/
const initialState = {list: [], detail: null};
/**
 * Función reducer que maneja el estado de contratos
 * @name contracts
 * @param {InitialContractsState} state - Estado actual de contratos
 * @param {Object} action - Objeto que contiene la acción y los datos asociados
 * @returns {InitialContractsState} Nuevo estado de contratos
*/

export default function contracts(state=initialState, action) {
  // let contractList = state.slice();

  switch (action.type) {

    case 'FETCH_CONTRACTS':
      return {...state, list: action.contracts};

    case 'CREATE_CONTRACT':
      return {...state, list: [...state.list, action.contract]};

    case 'FETCH_CONTRACT':
      return {...state, detail: action.contract};

    case 'CLEAR_CONTRACT':
      return {...state, detail: null};

    default:
      return state;
  }
}
