/**
 * Objeto con el estado inicial del estado de autenticación
 * @typedef {Object} InitialAuthState
 * @property {string} token - Token de autenticación almacenado en el almacenamiento local del navegador
 * @property {boolean} isAuthenticated - Indica si el usuario está autenticado o no
 * @property {boolean} isLoading - Indica si la aplicación está cargando o no
 * @property {Object|null} user - Objeto que contiene información del usuario autenticado
 * @property {Object} errors - Objeto que contiene los errores de autenticación
*/

const initialState = {
  token: localStorage.getItem("token"),
  isAuthenticated: null,
  isLoading: true,
  user: null,
  errors: {},
};

/**
 * Función reducer que maneja el estado de autenticación
 * @name auth
 * @param {InitialAuthState} state - Estado actual de autenticación
 * @param {Object} action - Objeto que contiene la acción y los datos asociados
 * @returns {InitialAuthState} Nuevo estado de autenticación
*/
export default function auth(state=initialState, action) {

  switch (action.type) {

    case 'USER_LOADING':
      return {...state, isLoading: true};
/**
 * Acción para indicar que se ha cargado la información del usuario
 * @param {Object} action.user - Objeto que contiene la información del usuario autenticado
 * @return {InitialAuthState} Nuevo estado de autenticación con isAuthenticated en true, isLoading en false y user con la información del usuario
*/
    case 'USER_LOADED':
      return {...state, isAuthenticated: true, isLoading: false, user: action.user};
/**
 * Acción para indicar que el inicio de sesión o registro ha sido exitoso
 * @param {Object} action.data - Objeto que contiene los datos de la respuesta del servidor, incluyendo el token de autenticación
 * @param {string} action.data.token - Token de autenticación enviado por el servidor
 * @return {InitialAuthState} Nuevo estado de autenticación con los datos de la respuesta del servidor, isAuthenticated en true, isLoading en false y errors en null
*/
    case 'LOGIN_SUCCESSFUL':
    case 'REGISTRATION_SUCCESSFUL':
      localStorage.setItem("token", action.data.token);
      return {...state, ...action.data, isAuthenticated: true, isLoading: false, errors: null};
/**
 * Acción para indicar que ha ocurrido un error de autenticación o inicio de sesión
 * @param {Object} action.data - Objeto que contiene los datos de la respuesta del servidor, incluyendo los errores de autenticación
 * @return {InitialAuthState} Nuevo estado de autenticación con los errores de autenticación, token y user en null, isAuthenticated en false y isLoading en false
*/
    case 'AUTHENTICATION_ERROR':
    case 'LOGIN_FAILED':
    case 'REGISTRATION_FAILED':
    case 'LOGOUT_SUCCESSFUL':
      localStorage.removeItem("token");
      return {...state, errors: action.data, token: null, user: null,
        isAuthenticated: false, isLoading: false};
/**
 * Acción por defecto que devuelve el estado actual sin realizar cambios
 * @return {InitialAuthState} Estado actual de autenticación
 */
    default:
      return state;
  }
}