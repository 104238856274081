/**
 * Representa el estado inicial de la lista de notas.
 * @type {Array}
*/
const initialState = [];

/**
 * Función reductora para la lista de notas.
 * @name notes
 * @function
 * @param {Array} state - Estado actual de la lista de notas.
 * @param {Object} action - Acción a aplicar en el estado de la lista de notas.
 * @param {string} action.type - Tipo de acción a aplicar.
 * @param {Object} action.note - Nota a añadir o actualizar.
 * @param {Object} action.index - Índice de la nota a actualizar o eliminar.
 * @param {Array} action.notes - Lista de notas a añadir o actualizar.
 * @returns {Array} Nuevo estado de la lista de notas.
*/
export default function notes(state=initialState, action) {
  let noteList = state.slice();

  switch (action.type) {

    case 'ADD_NOTE':
      return [...state, action.note];

    case 'UPDATE_NOTE':
      let noteToUpdate = noteList[action.index];
      noteToUpdate.text = action.note.text;
      noteList.splice(action.index, 1, noteToUpdate);
      return noteList;

    case 'DELETE_NOTE':
      noteList.splice(action.index, 1);
      return noteList;

    case 'FETCH_NOTES':
      return [...action.notes];

    default:
      return state;
  }
}