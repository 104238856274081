import React from "react";
import PropTypes from "prop-types";
import {Col, Container, Row} from "shards-react";

import MainNavbar from "../components/layout/MainNavbar/MainNavbar";
import MainSidebar from "../components/layout/MainSidebar/MainSidebar";

/**
 * Componente de diseño predeterminado que envuelve el contenido principal.
 * @name DefaultLayout
 * @function
 * @param {Object} props - Propiedades del componente.
 * @param {ReactNode} props.children - Contenido del componente.
 * @param {boolean} [props.noNavbar] - Indica si se debe mostrar la barra de navegación superior.
 * @param {boolean} [props.noFooter] - Indica si se debe mostrar el pie de página.
 * @returns {ReactNode} Componente de diseño predeterminado.
*/

const DefaultLayout = ({ children, noNavbar, noFooter }) => (
  <Container fluid>
    <Row>
      <MainSidebar />
      <Col
        className="main-content p-0"
        lg={{ size: 10, offset: 2 }}
        md={{ size: 9, offset: 3 }}
        sm="12"
        tag="main"
      >
        {!noNavbar && <MainNavbar />}
        {children}
        {/*{!noFooter && <MainFooter />}*/}
      </Col>
    </Row>
  </Container>
);

DefaultLayout.propTypes = {
  /**
   * Whether to display the navbar, or not.
   */
  noNavbar: PropTypes.bool,
  /**
   * Whether to display the footer, or not.
   */
  noFooter: PropTypes.bool
};

DefaultLayout.defaultProps = {
  noNavbar: false,
  noFooter: false
};

export default DefaultLayout;
