import axios from "axios";

/**
 * Función que realiza una solicitud GET para obtener los clientes de la API.
 * @name fetch_customers
 * @returns {Function} - Función que recibe dispatch y getState como parámetros y devuelve una promesa.
*/

export const fetch_customers = () => {
    return (dispatch, getState) => {
        let headers = {"Content-Type": "application/json"};
        let {token} = getState().auth;

        if (token) {
            headers["Authorization"] = `Token ${token}`;// Agrega el token a las cabeceras si está disponible
        }

        return axios.get("/api/customers/", {headers}).then(res => {
            return dispatch({type: 'FETCH_CUSTOMERS', customers: res.data})
        }).catch(err => {
            console.log('ERROR');
            console.log(err);
            console.log(err.response.status);
            if (err.response.status === 401) {
                let error = {errors: 'Sesión expirada, inicie sesión nuevamente'};
                dispatch({type: "AUTHENTICATION_ERROR", data: error});
            }
        })
    }
};

/**
 * Función que realiza una solicitud GET para obtener un cliente específico de la API.
 * @name fetch_customer
 * @param {number} id - ID del cliente a obtener.
 * @returns {Function} - Función que recibe dispatch y getState como parámetros y devuelve una promesa.
*/

export const fetch_customer = (id) => {
    return (dispatch, getState) => {
        let headers = {"Content-Type": "application/json"};
        let {token} = getState().auth;

        if (token) {
            headers["Authorization"] = `Token ${token}`;// Agrega el token a las cabeceras si está disponible
        }

        return axios.get(`/api/customers/${id}/`, {headers}).then(res => {
            return dispatch({type: 'FETCH_CUSTOMER', customer: res.data})
        }).catch(err => {
            console.log('ERROR');
            console.log(err.response);
            if (err.response.status === 401) {
                let error = {errors: 'Sesión expirada, inicie sesión nuevamente'};
                dispatch({type: "AUTHENTICATION_ERROR", data: error});
            }
        })
    }
};

/**
 * Función que realiza una solicitud PATCH para actualizar un cliente específico de la API.
 * @name update_customer
 * @param {number} id - ID del cliente a actualizar.
 * @param {Object} data - Objeto con los datos del cliente a actualizar.
 * @returns {Function} - Función que recibe dispatch y getState como parámetros y devuelve una promesa.
*/

export const update_customer = (id, data) => {
    return (dispatch, getState) => {
        let headers = {"Content-Type": "application/json"};
        let {token} = getState().auth;

        if (token) {
            headers["Authorization"] = `Token ${token}`;// Agrega el token a las cabeceras si está disponible
        }

        let body = {...data};

        return axios.patch(`/api/customers/${id}/`, body,{headers}).then(res => {
            return dispatch({type: 'UPDATE_CUSTOMER', customer: res.data})
        }).catch(err => {
            console.log('ERROR');
            console.log(err.response);
            if (err.response.status === 401) {
                let error = {errors: 'Sesión expirada, inicie sesión nuevamente'};
                dispatch({type: "AUTHENTICATION_ERROR", data: error});
            }
        })
    }
};

/**
 * Función que realiza una solicitud GET para obtener la lista de clientes transferidos de la API.
 * @name fetch_transferred_customers
 * @returns {Function} - Función que recibe dispatch y getState como parámetros y devuelve una promesa.
*/

export const fetch_transferred_customers = () => {
    return (dispatch, getState) => {
        let headers = {"Content-Type": "application/json"};
        let {token} = getState().auth;

        if (token) {
            headers["Authorization"] = `Token ${token}`;// Agrega el token a las cabeceras si está disponible
        }

        return axios.get("/api/transferred_customers/", {headers}).then(res => {
            return dispatch({type: 'FETCH_TRANSFERRED_CUSTOMERS', transferred_customers: res.data})
        }).catch(err => {
            console.log('ERROR');
            console.log(err);
            console.log(err.response.status);
            if (err.response.status === 401) {
                let error = {errors: 'Sesión expirada, inicie sesión nuevamente'};
                dispatch({type: "AUTHENTICATION_ERROR", data: error});
            }
        })
    }
};

/**
 * Función para crear un cliente nuevo.
 * @name create_customers
 * @param {object} data - Datos del cliente a crear.
 * @returns {Promise} Promesa que devuelve el resultado de la creación del cliente.
*/

export const create_customers = (data) => {
    return (dispatch, getState) => {
        let headers = {"Content-Type": "application/json"};
        let {token} = getState().auth;

        if (token) {
            headers["Authorization"] = `Token ${token}`;
        }
// Se crea el cuerpo de la petición con los datos del cliente a crear.
        let body = {...data};
// Se realiza la petición POST al endpoint /api/customers/ con los datos y headers establecidos.
// Se devuelve la respuesta con el nuevo cliente creado.
        return axios.post("/api/customers/", body,{headers}).then(res => {
            return dispatch({type: 'CREATE_CUSTOMER', customer: res.data})
        }).catch(err => {
            console.log('ERROR');
            console.log(err.response);
            if (err.response.status === 401) {
                let error = {errors: 'Sesión expirada, inicie sesión nuevamente'};
                dispatch({type: "AUTHENTICATION_ERROR", data: error});
            }
        })
    }
};

/**
 * Crea una nueva visita.
 * @name create_visit
 * @param {Object} data - Datos de la visita.
 * @returns {Promise} - Una promesa con el resultado de la petición.
*/

export const create_visit = (data) => {
    return (dispatch, getState) => {
        let headers = {"Content-Type": "application/json"};
        let {token} = getState().auth;

        if (token) {
            headers["Authorization"] = `Token ${token}`;
        }

        let body = {...data};
/**
 * Realiza una petición POST a la API para crear una nueva visita.
 * @param {string} url - URL de la API.
 * @param {Object} body - Datos de la visita.
 * @param {Object} headers - Cabeceras de la petición.
 * @returns {Promise} - Una promesa con el resultado de la petición.
*/
        return axios.post("/api/visits/", body,{headers}).then(res => {
            return dispatch({type: 'CREATE_VISIT', visit: res.data})
        }).catch(err => {
            console.log('ERROR');
            console.log(err.response);
            if (err.response.status === 401) {
                let error = {errors: 'Sesión expirada, inicie sesión nuevamente'};
                dispatch({type: "AUTHENTICATION_ERROR", data: error});
            }
        })
    }
};

/**
 * Crea un nuevo archivo.
 * @name create_file
 * @param {Object} data - Datos del archivo.
 * @returns {Promise} - Una promesa con el resultado de la petición.
*/

export const create_file = (data) => {
    return (dispatch, getState) => {
        let headers = {};
        let {token} = getState().auth;

        if (token) {
            headers["Authorization"] = `Token ${token}`;
        }

        let body = new FormData();
        let extension = data.file.name.split('.').pop();
        body.append('file', data.file);
        body.append('file_name', `${data.file_name}.${extension}`);
        body.append('customer', data.customer);
/**
 * Realiza una petición POST a la API para crear un nuevo archivo.
 * @param {string} url - URL de la API.
 * @param {Object} body - Datos del archivo.
 * @param {Object} headers - Cabeceras de la petición.
 * @returns {Promise} - Una promesa con el resultado de la petición.
*/
        return axios.post("/api/customer_files/", body, {headers}).then(res => {
            return dispatch({type: 'SUBMIT_CUSTOMER_FILE', file: res.data})
        }).catch(err => {
            console.log('ERROR');
            console.log(err);
            console.log(err.response.status);
            if (err.response.status === 401) {
                let error = {errors: 'Sesión expirada, inicie sesión nuevamente'};
                dispatch({type: "AUTHENTICATION_ERROR", data: error});
            }
        })
    }
};

/**
 * Transfiere un cliente a otro usuario.
 * @param {Object} data - Datos de la transferencia del cliente.
 * @returns {Promise} - Una promesa con el resultado de la petición.
*/

export const transfer_customer = (data) => {
    return (dispatch, getState) => {
        let headers = {"Content-Type": "application/json"};
        let {token} = getState().auth;

        if (token) {
            headers["Authorization"] = `Token ${token}`;
        }
        let body = {...data};
/**
 * Realiza una petición POST a la API para transferir un cliente.
 * @param {string} url - URL de la API.
 * @param {Object} body - Datos de la transferencia del cliente.
 * @param {Object} headers - Cabeceras de la petición.
 * @returns {Promise} - Una promesa con el resultado de la petición.
*/
        return axios.post(`/api/transfer_customers/`, body,  {headers}).then(res => {
            return dispatch({type: 'TRANSFER_CUSTOMER', simulation: res.data})
        }).catch(err => {
            console.log('ERROR');
            console.log(err);
            console.log(err.response.status);
        })
    }
};

/**
 * Obtiene la lista de transferencias pendientes.
 * @name fetch_pending_transfers
 * @function
 * @returns {Function} Función que hace una petición GET al endpoint /api/transfer_customers/ para obtener la lista de transferencias pendientes.
 * Retorna un objeto de acción con el tipo FETCH_PENDING_TRANSFERS y la lista de transferencias pendientes.
 * Si hay un error de autenticación, retorna un objeto de acción con el tipo AUTHENTICATION_ERROR y un mensaje de error.
*/

export const fetch_pending_transfers = () => {
    return (dispatch, getState) => {
        let headers = {};
        let {token} = getState().auth;

        if (token) {
            headers["Authorization"] = `Token ${token}`;
        }

        return axios.get("/api/transfer_customers/", {headers}).then(res => {
            return dispatch({type: 'FETCH_PENDING_TRANSFERS', pending_transfers: res.data})
        }).catch(err => {
            console.log('ERROR');
            console.log(err);
            console.log(err.response.status);
            if (err.response.status === 401) {
                let error = {errors: 'Sesión expirada, inicie sesión nuevamente'};
                dispatch({type: "AUTHENTICATION_ERROR", data: error});
            }
        })
    }
};

/**
 * Función para actualizar la información de una transferencia de clientes
 * @name update_transfer
 * @param {Object} data - Objeto con la información de la transferencia a actualizar
 * @param {number} id - Id de la transferencia a actualizar
 * @returns {Function} - Función que devuelve una promesa que resuelve con la acción "UPDATE_TRANSFER_CUSTOMER" y la transferencia actualizada
 * @throws {Error} - Error si la petición falla con un código de estado diferente a 401
*/

export const update_transfer = (data, id) => {
    return (dispatch, getState) => {
        let headers = {};
        let {token} = getState().auth;

        if (token) {
            headers["Authorization"] = `Token ${token}`;
        }

        let body = {
            "accepted": data.accepted,
        };
        if (!data.accepted) {
            body.rejection_reason = data.rejection_reason;
        }

        return axios.put(`/api/transfer_customers/${id}/`, body, {headers}).then(res => {
            return dispatch({type: 'UPDATE_TRANSFER_CUSTOMER', transfer: res.data, something_else: 'SOMETHING_ELSE'})
        }).catch(err => {
            console.log('ERROR');
            console.log(err);
            console.log(err.response);
            if (err.response.status === 401) {
                let error = {errors: 'Sesión expirada, inicie sesión nuevamente'};
                dispatch({type: "AUTHENTICATION_ERROR", data: error});
            }
        })
    }
};

/**
 * Función que envía los datos de contacto proporcionados por el usuario al servidor.
 * @name submit_contact
 * @param {Object} data - Datos de contacto proporcionados por el usuario.
 * @returns {Promise} - Promesa que devuelve los datos del contacto enviado al servidor.
*/

export const submit_contact = (data) => {
    return (dispatch, getState) => {
        let headers = {};
        let {token} = getState().auth;

        if (token) {
            headers["Authorization"] = `Token ${token}`;
        }

        let body = {...data};

        return axios.post('/api/contacts/', body, {headers}).then(res => {
            return dispatch({type: 'SUBMIT_CONTACT', contact: res.data})
        }).catch(err => {
            console.log('ERROR');
            console.log(err);
            console.log(err.response);
            if (err.response.status === 401) {
                let error = {errors: 'Sesión expirada, inicie sesión nuevamente'};
                dispatch({type: "AUTHENTICATION_ERROR", data: error});
            }
        })
    }
};

/**
 * Función que actualiza los datos de un contacto en el servidor
 * @name update_contact
 * @param {number} id - ID del contacto a actualizar
 * @param {number} index - Índice del contacto en la lista
 * @param {Object} data - Nuevos datos del contacto
 * @returns {Promise} - Promesa que devuelve los datos del contacto actualizado
*/

export const update_contact = (id, index, data) => {
    return (dispatch, getState) => {
        let headers = {"Content-Type": "application/json"};
        let {token} = getState().auth;

        if (token) {
            headers["Authorization"] = `Token ${token}`;
        }

        let body = {...data};

        return axios.patch(`/api/contacts/${id}/`, body,{headers}).then(res => {
            return dispatch({type: 'UPDATE_CONTACT', contact: res.data, index: index})
        }).catch(err => {
            console.log('ERROR');
            console.log(err.response);
            if (err.response.status === 401) {
                let error = {errors: 'Sesión expirada, inicie sesión nuevamente'};
                dispatch({type: "AUTHENTICATION_ERROR", data: error});
            }
        })
    }
};