import React, {Component} from "react";
import {Button, Card, CardBody, CardHeader, Col, Container, FormSelect, FormTextarea, Row} from "shards-react";

import {connect} from "react-redux";
import {polls} from "../actions"
import {Link} from "react-router-dom";

/**
 * La clase Poll es un componente que muestra una encuesta con preguntas y respuestas que son cargadas desde una API.
 * El componente extiende de Component de React, por lo que hereda todas sus funcionalidades.
 * @class
 * @extends Component
 * @property {Object} props - Propiedades recibidas por el componente.
 * @property {string} props.match.params.poll_id - ID de la encuesta que se debe cargar.
 * @property {Object} props.poll - Objeto que contiene la información de la encuesta.
 * @property {Array} props.poll.poll.config - Configuración de la encuesta, que incluye las preguntas y respuestas.
 * @property {string} props.match.params.id - ID del cliente al que se está mostrando la encuesta.
 * @method componentDidMount - Método de ciclo de vida de React que se ejecuta cuando el componente se monta en el DOM.
 * @method render - Método que se encarga de renderizar el componente.
 * @returns {JSX.Element} Retorna el contenido del componente en formato JSX.
*/

class Poll extends Component {

    componentDidMount() {
/**
* Método que se ejecuta cuando el componente se monta en el DOM.
* Se encarga de obtener el ID de la encuesta que se debe cargar y
* hacer una llamada a la API para obtener la información de la encuesta.
* @function
* @returns {void}
*/
        let poll_id = this.props.match.params.poll_id;
        this.props.fetch_customer_poll(poll_id);
    }

/**
 * Método que se encarga de renderizar el componente.
 * Se encarga de mostrar la información de la encuesta en el DOM.
 * @function
 * @returns {JSX.Element} Retorna el contenido del componente en formato JSX.
*/     
    render() {
        let {poll} = this.props;
        let poll_data = '';
        let poll_title = '';
        let customer_id = this.props.match.params.id;
        if (poll !== null) {
            poll_title = poll.poll.name;
            poll_data = <div>
                {poll.poll.config.map((question, index) => {
                    let question_block = <h1>Loading Question</h1>;
                    if (question.type === 'text') {
                        question_block = <Col lg="12">
                            <label htmlFor="Consumption">{question.title}</label>
                            <FormTextarea
                                type="text"
                                value={poll.answers[index]}
                                onChange={(e) => this.update_question(e, index, e.target.value)}
                                required
                                disabled
                            />
                        </Col>
                    } else if (question.type === 'multiple_choice') {
                        question_block = <Col lg="12" className="form-group">
                            <label htmlFor="Consumption">{question.title}</label>
                            <FormSelect
                                value={poll.answers[index]}
                                onChange={(e) => this.update_question(e, index, e.target.value)}
                                required
                                disabled
                            >
                                <option value="">---</option>
                                {question.values.map((value, index) => (
                                    <option key={index} value={value[0]}>{value[1]}</option>
                                ))}
                            </FormSelect>
                        </Col>
                    }

                    return <Row key={`key_${index}`}>{question_block}</Row>
                })}
            </div>;
        }

        let container = <><Container fluid className="main-content-container px-4">

            <Row>
                <Col className="my-4">
                    <Link to={`/clientes/${customer_id}`}>
                        <Button>Regresar</Button>
                    </Link>
                </Col>

                <Col lg="12" className="my-2">
                    <Card small>
                        {/* Card Header */}
                        <CardHeader className="border-bottom">
                            <h6 className="m-0">{poll_title}</h6>
                        </CardHeader>

                        <CardBody className="d-flex flex-column">
                            <Row>
                                <Col lg="12">

                                </Col>
                            </Row>
                            {poll_data}
                        </CardBody>
                    </Card>
                </Col>
            </Row>

        </Container></>;
        return container
    }
}
/**
 * Función que mapea el estado a las props del componente
 * @param {Object} state - Estado actual de la aplicación
 * @returns {Object} - Objeto que contiene las props mapeadas al estado
*/
const mapStateToProps = state => {
    return {
        poll: state.polls.poll,
    }
};
/**
 * Función que mapea las acciones a las props del componente
 * @param {Function} dispatch - Función de redux para despachar acciones
 * @returns {Object} - Objeto que contiene las props mapeadas a las acciones
*/
const mapDispatchToProps = dispatch => {
    return {
        fetch_customer_poll: (id) => {
            dispatch(polls.fetch_customer_poll(id))
        },
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Poll);
