import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";

/**
 * Componente para una ruta de administrador que verifica la autenticación del usuario y si es administrador para mostrar el componente
 * @name AdminRoute
 * @param {Object} props - Propiedades del componente
 * @param {React.Component} props.component - Componente a renderizar si el usuario es administrador
 * @param {Object} props.auth - Objeto de autenticación
 * @param {boolean} props.auth.isLoading - Indica si se está cargando la información de autenticación
 * @param {boolean} props.auth.isAuthenticated - Indica si el usuario está autenticado
 * @param {Object} rest - Resto de propiedades para la ruta
 * @returns {React.Component} Componente de ruta
*/

const AdminRoute = ({ component: Component, auth, ...rest }) => (
    <Route
        {...rest}
        render={props => {
            if (auth.isLoading) {
                return <h2>Loading...</h2>;
            } else if (auth.isAuthenticated) {
                if (auth.user.is_admin) {
                    return <Component {...props} />;
                }
                return null;
            } else {
                return (
                    <Redirect
                        to={{
                            pathname: '/',
                            state: { originPath: props.match.path }
                        }}
                    />
                );
            }
        }}
    />
);

const mapStateToProps = state => ({
    auth: state.auth,
});

export default connect(mapStateToProps)(AdminRoute);