import React, {Component} from "react";
import {Button, Card, CardBody, Col, Container, Form, FormInput, FormSelect, Row} from "shards-react";

import PageTitle from "./../components/common/PageTitle";

import {connect} from "react-redux";
import {canon} from "../actions"

/**
 * Componente que representa la sección de Canon de la aplicación.
 * @class
 * @extends Component
 * @property {object} state - Estado del componente.
 * @property {number} state.investment - Inversión.
 * @property {number} state.meters - Metros cuadrados.
 * @property {string} state.region - Región seleccionada.
 * @property {number} state.yearly_consumption - Consumo anual.
 * @property {string[]} state.region_options - Opciones de región disponibles.
 * @property {function} componentDidMount - Método del ciclo de vida que se ejecuta después de que el componente se monta en el DOM.
 * @property {function} numberFormat - Función para formatear números.
 * @param {number} n - Número a formatear.
 * @returns {string} - Número formateado como cadena.
 * @property {function} submitSimulation - Función que se llama cuando se envía el formulario de simulación.
 * @param {Event} e - Evento de envío de formulario.
 * @property {function} render - Método que renderiza el componente.
 * @property {object} props - Propiedades del componente.
 * @property {object} props.match - Objeto que contiene información sobre cómo se emparejó una URL con una ruta.
 * @property {string} props.match.params.id - ID de la simulación.
 * @property {function} props.fetchCanon - Función que se utiliza para obtener información de Canon de una simulación.
 * @property {object} props.canon - Información de Canon de la simulación.
 * @property {function} props.updateCanon - Función que se utiliza para actualizar la información de Canon de una simulación.
*/

class Canon extends Component {

    state = {
        "investment": null,
        "meters": null,
        "region": "CDMX",
        "yearly_consumption": null,
        "region_options": ["Toluca", "CDMX"],
    };

    componentDidMount() {
        let simulationId = this.props.match.params.id;
        this.props.fetchCanon(simulationId).then(() => {
            let input = this.props.canon.simulation.input;
            this.setState({
                "investment": input.investment,
                "meters": input.meters,
                "region": input.region,
                "yearly_consumption": input.yearly_consumption,
            })
        })
    }

    numberFormat = (n) => {
        if (n !== "en-US") {
            return n.toLocaleString("en-US", {minimumFractionDigits: 2, maximumFractionDigits: 2})
        }
        return '-'
    };

    submitSimulation = (e) => {
        e.preventDefault();

        console.log('IN SUBMIT');
        this.props.updateCanon(this.props.match.params.id, this.state)

    };

    render() {
        const canon = this.props.canon;
        let blocks = [];
        let simulation = {};
        if (canon.simulation !== null) {
            console.log(canon.simulation.simulation.blocks);
            blocks = canon.simulation.simulation.blocks;
            simulation = canon.simulation.simulation;
        }
        console.log(simulation);
        let container = <><Container fluid className="main-content-container px-4">
            {/* Page Header */}


            <Row noGutters className="page-header py-4">
                {/*<PageTitle title={`Simulación de Factura para ${customer_name}`}*/}
                <PageTitle title={`Simulación de Factura para CLIENTE`}
                           className="text-sm-left mb-3 col-sm-12"/>
            </Row>
            <Row>
                <Col className="my-2">
                    {/*<Link to={`/clientes/${this.state.customer_id}`}>*/}
                    <Button className="my-4 my-md-2 col-md-12 col-lg-2">Regresar</Button>
                    {/*</Link>*/}
                    {/*<Link to={`/simulations/download/${sim_id}/`} target='_blank' rel="noopener noreferrer" download>*/}
                    <Button className="float-right col-md-12 col-lg-2">Descargar</Button>
                    {/*</Link>*/}
                </Col>
            </Row>
            <Row>
                <Col lg="8" className="">

                    <Card small className="h-100">
                        {canon.simulation ? (
                            <CardBody className="d-flex flex-column">

                                <Row>
                                    <Col lg={12}>
                                        <table className="table simulation-results table-sm">
                                            <thead className="bg-light">
                                            <tr>
                                                <th>Consumo Anual</th>
                                                <th>Metros</th>
                                                <th>Inversión</th>
                                                <th>cxn estándar</th>
                                                <th>cxn no estándar</th>
                                                <th>total</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr>
                                                <td>{this.numberFormat(simulation.yearly_consumption)}</td>
                                                <td>{simulation.meters}</td>
                                                <td>{simulation.investment}</td>
                                                <td>{simulation.standard}</td>
                                                <td>{simulation.non_standard}</td>
                                                <td>{this.numberFormat(simulation.canon)}</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col lg={12}>
                                        <table className="table simulation-results table-sm">
                                            <thead className="bg-light">
                                            <tr>
                                                <th>Total Anual</th>
                                                <th>Año 1</th>
                                                <th>Año 2</th>
                                                <th>Año 3</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr>
                                                <td>Cargo por Capacidad</td>
                                                <td>{this.numberFormat(simulation.capacity_total)}</td>
                                                <td>{this.numberFormat(simulation.capacity_total)}</td>
                                                <td>{this.numberFormat(simulation.capacity_total)}</td>
                                            </tr>
                                            <tr>
                                                <td>Cargo por Uso</td>
                                                <td>{this.numberFormat(simulation.use_total)}</td>
                                                <td>{this.numberFormat(simulation.use_total)}</td>
                                                <td>{this.numberFormat(simulation.use_total)}</td>
                                            </tr>
                                            <tr>
                                                <td>Cargo por Servicio</td>
                                                <td>{this.numberFormat(simulation.service_total)}</td>
                                                <td>{this.numberFormat(simulation.service_total)}</td>
                                                <td>{this.numberFormat(simulation.service_total)}</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col lg={12}>
                                        <table className="table simulation-results table-sm">
                                            <thead className="bg-light">
                                            <tr>
                                                <th>Simulación de Factura</th>
                                                <th>Gj</th>
                                                <th>Unitario</th>
                                                <th>Subtotal</th>
                                                <th>Subtotal</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {blocks.map((block, index) => {
                                                return ([
                                                    <tr key={`quote_block_c_${index}`}>
                                                        <td>Bloque {block[0]}</td>
                                                        <td>{this.numberFormat(block[1])}</td>
                                                        <td>Capacidad</td>
                                                        <td>{this.numberFormat(block[2]['cap'])}</td>
                                                        <td>{this.numberFormat(block[3]['cap'])}</td>
                                                    </tr>,
                                                    <tr key={`quote_block_u_${index}`} className="table-no-top">
                                                        <td>Bloque {block[0]}</td>
                                                        <td>{this.numberFormat(block[1])}</td>
                                                        <td>Uso</td>
                                                        <td>{this.numberFormat(block[2]['use'])}</td>
                                                        <td>{this.numberFormat(block[3]['use'])}</td>
                                                    </tr>,
                                                ])

                                            })}
                                            </tbody>
                                        </table>
                                    </Col>
                                </Row>

                            </CardBody>
                        ) : null
                        }

                    </Card>
                </Col>

                <Col lg="4" className="mb-8">
                    <Row>
                        <Col md="12">
                            <Card small className="my-lg-0 my-4 ">
                                {/* Card Header */}

                                <CardBody className="d-flex flex-column">
                                    <Form onSubmit={this.submitSimulation}>
                                        <Button type="submit" className="my-2 col-lg-12">Actualizar</Button>

                                        <table className="table table-sm simulation-data">
                                            <tbody>
                                            <tr>
                                                <td><span className="ng-blue">Zona</span></td>
                                                <td>
                                                    <FormSelect
                                                        value={this.state.region}
                                                        onChange={(e) => this.setState({region: e.target.value})}
                                                    >
                                                        {this.state.region_options.map((region, key) => (
                                                                <option key={key} value={region}>{region}</option>
                                                            ))}
                                                    </FormSelect>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Consumo anual GJ</td>
                                                <td>
                                                    <FormInput
                                                        id="contract-id"
                                                        type="number"
                                                        value={this.state.yearly_consumption}
                                                        onChange={(e) => this.setState({yearly_consumption: e.target.value})}
                                                        required
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>[m] Totales</td>
                                                <td>
                                                    <FormInput
                                                        id="contract-id"
                                                        type="number"
                                                        value={this.state.meters}
                                                        onChange={(e) => this.setState({meters: e.target.value})}
                                                        required
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>[$ miles MXN] Inversión</td>
                                                <td>
                                                    <FormInput
                                                        id="contract-id"
                                                        type="number"
                                                        value={this.state.investment}
                                                        onChange={(e) => this.setState({investment: e.target.value})}
                                                        required
                                                    />
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>


                    </Row>

                </Col>

            </Row>

        </Container></>;
        return container
    }

}
/**
 * Función que mapea el estado a las props del componente
 * @param {Object} state - Estado actual de la aplicación
 * @returns {Object} - Objeto que contiene las props mapeadas al estado
*/
const mapStateToProps = state => {
    return {
        canon: state.canon,
    }
};
/**
 * Función que mapea las acciones a las props del componente
 * @param {Function} dispatch - Función de redux para despachar acciones
 * @returns {Object} - Objeto que contiene las props mapeadas a las acciones
*/
const mapDispatchToProps = dispatch => {
    return {
        fetchCanon: (simulationId) => {
            return dispatch(canon.fetchCanon(simulationId))
        },
        updateCanon: (simulation_id, values) => {
            dispatch(canon.updateCanon(simulation_id, values))
        },
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(Canon);
