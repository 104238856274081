import React, {Component} from "react";
import {Button, Card, CardBody, CardHeader, Col, Container, Form, FormInput, Row} from "shards-react";

import PageTitle from "./../components/common/PageTitle";

import {connect} from "react-redux";
import {customersV2} from "../actions";
import {Link} from "react-router-dom";
import CustomTable from "../components/CustomTable";

/**
 * La clase Customers2 extiende de Componente de React.
 * @class
*/
class Customers2 extends Component {
/**
 * Crea una instancia de Customers2.
 * @constructor
 * @param {object} props - Propiedades del componente.
*/
    constructor(props) {
        super(props);
/**
 * Estado inicial del componente.
 * @property {string} name - El nombre del cliente.
*/
        this.state = {
            'name': '',
        };
/**
 * Bind de la función reset_state al componente actual.
*/
        this.reset_state = this.reset_state.bind(this);
    }
/**
 * Ciclo de vida del componente, se ejecuta cuando el componente se ha montado.
*/
    componentDidMount() {
        this.props.fetch_customers();
    }
/**
 * Función que resetea el estado del componente a su estado inicial.
*/
    reset_state = () => {
        console.log('RESET')
        this.setState({
            name: "",
        });
    };
/**
 * Función que maneja el envío del formulario de creación de cliente.
 * @param {object} e - Evento del formulario.
*/
    submit_customer = (e) => {
        e.preventDefault();
        // console.log('SUBMIT')
        this.props.create_customer(this.state).then(this.reset_state);
    }
/**
 * Renderiza el componente.
 * @returns {JSX.Element} - Elemento JSX que representa el componente.
*/
    render() {
        console.log(this.state);

        const customer_header = [
            {title: 'Estado', prop: 'estado', filterable: true},
            {title: 'Nombre', prop: 'name', filterable: true},
            {title: 'Ver', prop: 'ver'},
        ];
        const customLabels = {
            first: '<<',
            last: '>>',
            prev: '<',
            next: '>',
            show: 'Display',
            entries: 'rows',
            noResults: 'No hay resultados',
            filterPlaceholder: 'Buscar',
        };

        let customer_table_items = this.props.customers.list.map((customer, id) => (
            {
                estado: customer.current_status_name,
                name: customer.name,
                ver: <Link to={`/clients/${customer.id}`}><Button>Ver</Button></Link>
            }
        ));

        let container = <><Container fluid className="main-content-container px-4">
            {/* Page Header */}
            <Row noGutters className="page-header py-4">
                <PageTitle title="Clientes" className="text-sm-left mb-3" onClick={this.reset_state}/>
            </Row>

            <Row>
                {/* New Draft */}
                <Col lg="6" className="mb-8 my-4">
                    <Card small className="">
                        <CardHeader className="border-bottom">
                            <i className="icon icon-altacliente header-icon"></i>
                            <h6 className="m-0">Clientes Actuales</h6>
                        </CardHeader>
                        <CardBody className="d-flex flex-column">
                            <CustomTable
                                tableHeaders={customer_header}
                                tableBody={customer_table_items}
                                tableClass="table-striped hover responsive"
                                rowsPerPage={5}
                                initialSort={{prop: 'username', isAscending: true}}
                                labels={customLabels}
                            />
                        </CardBody>
                    </Card>
                </Col>

                <Col lg="6" className="mb-8 my-4">
                    <Card small className="">
                        {/* Card Header */}
                        <CardHeader className="border-bottom">
                            <i className="icon icon-clienteactual header-icon"></i>
                            <h6 className="m-0">Dar de alta nuevo cliente</h6>
                        </CardHeader>

                        <CardBody className="d-flex flex-column">
                            <Form onSubmit={this.submit_customer}>
                                <Row>
                                    <Col>
                                        <Row form>

                                            <Col md="12" className="form-group">
                                                <label htmlFor="Consumption">Razón Social</label>
                                                <FormInput
                                                    id="company-name"
                                                    type="text"
                                                    placeholder="Razón Social"
                                                    value={this.state.name}
                                                    onChange={(e) => this.setState({name: e.target.value})}
                                                    required
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className="my-4">
                                                <Button type="submit" className="col-lg-12">Crear Cliente</Button>
                                            </Col>
                                        </Row>

                                    </Col>
                                </Row>

                            </Form>
                        </CardBody>
                    </Card>
                </Col>

            </Row>

        </Container></>;
        return container
    }

}
/**
 * Función que mapea el estado a las props del componente
 * @param {Object} state - Estado actual de la aplicación
 * @returns {Object} - Objeto que contiene las props mapeadas al estado
*/
const mapStateToProps = state => {
    return {
        customers: state.customersV2,
    }
};
/**
 * Función que mapea las acciones a las props del componente
 * @param {Function} dispatch - Función de redux para despachar acciones
 * @returns {Object} - Objeto que contiene las props mapeadas a las acciones
*/
const mapDispatchToProps = dispatch => {
    return {
        fetch_customers: () => {
            dispatch(customersV2.fetch_customers())
        },
        create_customer: (data) => {
            return dispatch(customersV2.create_customer(data))
        },
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(Customers2);
