import axios from "axios";

/**
 * Obtiene los anuncios desde la API y los agrega al store de la aplicación.
 * @name fetch_announcements
 * @function
 * @returns {Promise} Promesa que se resuelve con el resultado de la petición.
*/

export const fetch_announcements = () => {
    return (dispatch, getState) => {
        let headers = {"Content-Type": "application/json"};
        let {token} = getState().auth;

        if (token) {
            headers["Authorization"] = `Token ${token}`;
        }
/**
  * Realiza una petición GET a la ruta "/api/admin_announcements/" con los encabezados dados.
  * @method
  * @param {Object} headers Encabezados de la petición.
  * @returns {Promise} Promesa que se resuelve con el resultado de la petición.
*/
        return axios.get("/api/admin_announcements/", {headers}).then(res => {
/**
* Envía una acción al store con los anuncios obtenidos de la API.
* @method
* @param {String} type Tipo de acción.
* @param {Array} announcements Array de anuncios obtenidos de la API.
*/
            return dispatch({type: 'FETCH_ANNOUNCEMENTS', announcements: res.data})
        }).catch(err => {
            console.log('ERROR');
            console.log(err);
            console.log(err.response.status);
        })
    }
};

/**
 * Crea un nuevo anuncio en la API y agrega el anuncio al store de la aplicación.
 * @function
 * @param {Object} data Información del nuevo anuncio.
 * @param {File} data.file Archivo adjunto al anuncio.
 * @param {String} data.message Mensaje del anuncio.
 * @returns {Promise} Promesa que se resuelve con el resultado de la petición.
*/

export const create_announcement = (data) => {
    return (dispatch, getState) => {
        // let headers = {"Content-Type": "application/json"};
        let headers = {};
        let {token} = getState().auth;

        if (token) {
            headers["Authorization"] = `Token ${token}`;
        }

        let body = new FormData();

        body.append('file', data.file);
        body.append('message', data.message);
/**
  * Realiza una petición POST a la ruta "/api/admin_announcements/" con el cuerpo y los encabezados dados.
  * @method
  * @param {String} url URL de la petición.
  * @param {Object} body Cuerpo de la petición.
  * @param {Object} headers Encabezados de la petición.
  * @returns {Promise} Promesa que se resuelve con el resultado de la petición.
*/
        return axios.post("/api/admin_announcements/", body, {headers}).then(res => {
/**
* Envía una acción al store con el nuevo anuncio creado en la API.
* @method
* @param {String} type Tipo de acción.
* @param {Object} announcement Anuncio creado en la API.
*/
            return dispatch({type: 'SUBMIT_ANNOUNCEMENT', announcement: res.data})
        }).catch(err => {
            console.log('ERROR');
            console.log(err);
            console.log(err.response.status);
        })
    }
};

/**
 * Función que lee los anuncios del usuario.
 * @name read_announcements
 * @param {Object} data - Datos de entrada.
 * @return {Function} Función que hace una petición GET a la API de anuncios del usuario y devuelve una promesa.
 * @throws {Error} Si ocurre un error durante la petición a la API.
*/

export const read_announcements = (data) => {
    return (dispatch, getState) => {
        // let headers = {"Content-Type": "application/json"};
        let headers = {};
        let {token} = getState().auth;

        if (token) {
            headers["Authorization"] = `Token ${token}`;
        }

        return axios.get("/api/user/notifications_read/", {headers}).then(res => {
            // console.log(res.data);
            // return dispatch({type: 'SUBMIT_ANNOUNCEMENT', announcement: res.data})
        }).catch(err => {
            console.log('ERROR');
            console.log(err);
            console.log(err.response.status);
        })
    }
};