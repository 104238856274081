import React, { Component } from "react";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Form,
    FormCheckbox,
    FormInput,
    FormSelect, FormTextarea, Modal, ModalBody, ModalHeader,
    Row
} from "shards-react";

import PageTitle from "./../components/common/PageTitle";

import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { customers, users } from "../actions";
import CustomTable from "../components/CustomTable";
/**
 * Clase que representa un componente de React llamado Customers. 
 * @name Customers
 * @extends Component
*/

class Customers extends Component {
/**
 * Constructor de la clase Customers.
 * @param {Object} props - Las propiedades del componente.
*/
    constructor(props) {
        super(props);
/**
 * Estado inicial del componente.
 * @type {Object}
 * @property {Boolean} existing_installation - Indica si existe una instalación existente.
 * @property {String} contract_id - Identificador del contrato.
 * @property {String} company_name - Nombre de la compañía.
 * @property {String} kam - Nombre del KAM.
 * @property {String} user_in_charge - Usuario encargado.
 * @property {String} type - Tipo de empresa.
 * @property {String} commercial_business - Negocio comercial.
 * @property {Boolean} transfer_user_open - Indica si se está transfiriendo un usuario.
 * @property {Number|null} transfer_id - Identificador de la transferencia.
 * @property {Boolean} accept_transfer - Indica si se acepta la transferencia.
 * @property {String} rejection_reason - Razón de rechazo de la transferencia.
 * @property {String} description - Descripción de la compañía.
 * @property {String} current_status - Estado actual de la compañía.
*/
        this.state = {
            existing_installation: false,
            contract_id: "",
            company_name: "",
            kam: "",
            user_in_charge: "",
            type: "",
            commercial_business: "",
            transfer_user_open: false,
            transfer_id: null,
            accept_transfer: true,
            rejection_reason: "",
            description: "",
            current_status: ""
        };
        this.toggle = this.toggle.bind(this);
    }
/**
 * Manejador de evento que se ejecuta cuando se cambia el valor del checkbox de "existing_installation".
 * Actualiza el estado del componente con el nuevo valor.
 * @param {Object} e - Evento de cambio.
*/
    handleChecked = (e) => {
        this.setState({existing_installation: !this.state.existing_installation});
    };
/**
 * Función que se ejecuta cuando se hace clic en el botón de "transferir usuario".
 * Actualiza el estado del componente para mostrar el formulario de transferencia.
 * @param {Object} e - Evento de clic.
*/
    toggle = (e) => {
        const transfer = this.props.customers.pending_transfers[e.target.value];
        this.setState({
            transfer_user_open: !this.state.transfer_user_open,
            transfer_id: transfer.id,
            accept_transfer: true,
            rejection_reason: "",
        });
    };
/**
 * Función que restablece el estado del componente a su valor inicial.
*/
    reset_state = () => {
        this.setState({
            existing_installation: false,
            contract_id: "",
            company_name: "",
            kam: "",
            user_in_charge: "",
            type: "",
            commercial_business: "",
            current_status: "",
        });
    };
/**
 * Función que se ejecuta cuando se envía el formulario de creación de cliente.
 * Crea un nuevo cliente a través de la función "create_customer" pasando el estado actual del componente.
 * Finalmente, se restablece el estado del componente a su valor inicial.
 * @param {Object} e - Evento de envío de formulario.
*/
    submit_customer = (e) => {
        e.preventDefault();
        this.props.create_customer(this.state).then(this.reset_state);
    };
/**
 * Función para enviar la transferencia y actualizar la información en la interfaz.
 * @param {Object} e - Evento del submit del formulario.
 * @returns {void}
*/
    submit_transfer = (e) => {
        e.preventDefault();
        let data = {
            accepted: this.state.accept_transfer,
            rejection_reason: this.state.rejection_reason,
        };
        this.props.update_transfer(data, this.state.transfer_id).then(() => {
            this.setState({
                transfer_user_open: false,
                transfer_id: null,
                accept_transfer: true,
                rejection_reason: "",
            });
            this.props.fetch_customers();
            this.props.fetch_pending_transfers();
            this.props.fetch_transferred_customers();
            this.props.fetch_notifications();

        })
    };
/**
 * Método que se ejecuta después de que el componente se haya montado.
 * @returns {void}
*/
    componentDidMount() {
        this.props.fetch_customers();
        this.props.fetch_users();
        this.props.fetch_pending_transfers();
        this.props.fetch_transferred_customers();
    }

    render() {
        const {pending_transfers} = this.props.customers;
        const customer_types = [
            ['lc', '<5,000 Distribución con comercialización'],
            ['lcr', '<5,000 Distribución con comercialización. + IRI + Calibraciones'],
            ['lcse', '<5,000 Distribución con comercialización. + Solución Energetica'],
            ['gs', '>5,000 Distribución Simple'],
            ['gsr', '>5,000 Distribución Simple  + IRI + Calibraciones'],
            ['gsse', '>5,000 Distribución Simple + SE'],
            ['gsbe', '>5,000 Distribución Simple + VE'],
            ['gsc', '>5,000 Distribución Simple + Coberturas'],
            ["gsg", ">5000 Distribución Simple + GNV"],
        ];
        const status_types = [
            ['detectado', 'Detectado'],
            ['potencial', 'Potencial'],
            ['oferta', 'Oferta'],
            ['contratado', 'Contratado'],
            ['construccion', 'Construcción'],
            ['en servicio', 'En Servicio'],
            ['cancelado', 'Cancelado'],
            ['cortado', 'Cortado'],
        ]
        let customer_table_items = this.props.customers.list.map((customer, id) => (
            {estado: <i className={`material-icons label-success tl-${customer.status}`}>brightness_1</i>,
                razonsocial: customer.company_name,
                contract_id: customer.contract_id,
                ver: <Link to={`/clientes/${customer.id}`}><Button>Ver</Button></Link>}
        ));

        let transferred_customer_table_items = this.props.customers.transferred_customers.map((customer, id) => (
            {estado: <i className={`material-icons label-success tl-${customer.status}`}>brightness_1</i>, razonsocial: customer.company_name,
                ver: <Link to={`/clientes/${customer.id}`}><Button>Ver</Button></Link>}
        ));


        const customer_header = [
            { title: 'Estado', prop: 'estado'},
            { title: 'Razón Social', prop: 'razonsocial', filterable:true },
            { title: 'ID de contrato', prop: 'contract_id', filterable:true },
            { title: 'Ver', prop: 'ver' },
        ];

        const customLabels = {
            first: '<<',
            last: '>>',
            prev: '<',
            next: '>',
            show: 'Display',
            entries: 'rows',
            noResults: 'No hay resultados',
            filterPlaceholder: 'Buscar',
        };

        let container = <><Container fluid className="main-content-container px-4">
            {/* Page Header */}
            <Row noGutters className="page-header py-4">
                <PageTitle title="Clientes" className="text-sm-left mb-3"/>
            </Row>
            {pending_transfers.length > 0 && (
                <Row>
                    <Col className="mb-8">
                        <Card>
                            <CardHeader className="border-bottom">
                                <h6 className="m-0">Transferencias Pendientes</h6>
                            </CardHeader>
                            <CardBody className="d-flex flex-column">
                                <Row>
                                    <Col>
                                        <div className="table-responsive">
                                            <table className="table mb-0 simulation_results">
                                                <thead className="bg-light">
                                                <tr>
                                                    <th>Razón Social</th>
                                                    <th>Usuario que solicitó la transferencia</th>
                                                    <th>Razón de Transferencia</th>
                                                    <th>Procesar</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {pending_transfers.map((transfer, key) => (
                                                    <tr key={`requested_transfer_${transfer.id}`}>
                                                        <td>{transfer.company_name}</td>
                                                        <td>{transfer.transferred_by}</td>
                                                        <td>{transfer.transfer_reason}</td>
                                                        <td>
                                                            <Button
                                                                value={key}
                                                                onClick={e => this.toggle(e, "value")}
                                                            >Procesar</Button>
                                                        </td>
                                                    </tr>
                                                ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            )}

            <Row>
                {/* New Draft */}
                <Col lg="6" className="mb-8 my-4">
                    <Card small className="">
                        <CardHeader className="border-bottom">
                            <i className="icon icon-altacliente header-icon"></i>
                            <h6 className="m-0">Clientes Actuales</h6>
                        </CardHeader>
                        <CardBody className="d-flex flex-column">
                            <Row>
                                <div className="table-responsive">
                                    <Col>

                                        <CustomTable
                                            tableHeaders={customer_header}
                                            tableBody={customer_table_items}
                                            tableClass="table-striped hover responsive"
                                            rowsPerPage={5}
                                            initialSort={{ prop: 'username', isAscending: true }}
                                            labels={customLabels}
                                        />

                                    </Col>
                                </div>
                            </Row>

                        </CardBody>
                    </Card>
                </Col>

                <Col lg="6" className="mb-8 my-4">
                    <Card small className="">
                        {/* Card Header */}
                        <CardHeader className="border-bottom">
                            <i className="icon icon-clienteactual header-icon"></i>
                            <h6 className="m-0">Dar de alta nuevo cliente</h6>
                        </CardHeader>

                        <CardBody className="d-flex flex-column">
                            <Form onSubmit={this.submit_customer}>
                                <Row>
                                    <Col>
                                        <Row form>

                                            <Col md="12" className="form-group">
                                                <FormCheckbox
                                                    checked={this.state.existing_installation}
                                                    id="new-contract"
                                                    // onClick={(e) => this.setState({newContract: !e.target.value})}
                                                    onChange={e => this.handleChecked(e)}
                                                >
                                                    Punto de Suministro Existente
                                                </FormCheckbox>
                                            </Col>

                                            <Col md="12" className="form-group">
                                                <label htmlFor="Consumption">Razón Social</label>
                                                <FormInput
                                                    id="company-name"
                                                    type="text"
                                                    placeholder="Razón Social"
                                                    value={this.state.company_name}
                                                    onChange={(e) => this.setState({company_name: e.target.value})}
                                                    required
                                                />
                                            </Col>
                                            <Col md="12" className="form-group">
                                                <label htmlFor="Consumption">Descripción</label>
                                                <FormTextarea
                                                    value={this.state.description}
                                                    onChange={(e) => this.setState({description: e.target.value})}
                                                >
                                                </FormTextarea>
                                            </Col>
                                            <Col md="6" className="form-group">
                                                <label htmlFor="Consumption">ID de Contrato</label>
                                                <FormInput
                                                    id="contract-id"
                                                    type="text"
                                                    placeholder="ID de Contrato"
                                                    value={this.state.contract_id}
                                                    onChange={(e) => this.setState({contract_id: e.target.value})}
                                                    required
                                                />
                                            </Col>
                                            <Col md="6" className="form-group">
                                                <label htmlFor="Consumption">Tipo de Cliente</label>
                                                <FormSelect
                                                    value={this.state.type}
                                                    onChange={(e) => this.setState({type: e.target.value})}
                                                    required
                                                >
                                                    <option value="">---</option>
                                                    {customer_types.map((type, index) => (
                                                        <option key={index} value={type[0]}>{type[1]}</option>
                                                    ))}
                                                </FormSelect>
                                            </Col>
                                            <Col md="6" className="form-group">
                                                <label htmlFor="Consumption">Giro Comercial</label>
                                                <FormSelect
                                                    value={this.state.commercial_business}
                                                    onChange={(e) => this.setState({commercial_business: e.target.value})}
                                                    required
                                                >
                                                    <option value="">---</option>
                                                    <option value="Manufactura">Manufactura</option>
                                                    <option value="Servicios">Servicios</option>
                                                    <option value="Consumo">Consumo</option>
                                                    <option value="Energía">Energía</option>
                                                    <option value="Energía Eléctrica">Energía Eléctrica</option>
                                                    <option value="Gobierno">Gobierno</option>
                                                    <option value="GNV">GNV</option>
                                                    <option value="Papel">Papel</option>
                                                    <option value="Vidrio">Vidrio</option>
                                                    <option value="Alimentos">Alimentos</option>
                                                    <option value="Fundición / Acero">Fundición / Acero</option>
                                                    <option value="Química">Química</option>
                                                    <option value="Farmacéutica">Farmacéutica</option>
                                                    <option value="Hotelería">Hotelería</option>
                                                    <option value="Restaurante">Restaurante</option>
                                                    <option value="Escuela">Escuela</option>
                                                    <option value="Industria">Industria</option>
                                                    <option value="Industria Química">Industria Química</option>
                                                    <option value="Servicios y Restaurantes">Servicios y Restaurantes</option>
                                                    <option value="Hospitales">Hospitales</option>
                                                    <option value="Cerámica">Cerámica</option>
                                                    <option value="Autopartes">Autopartes</option>
                                                    <option value="Automotriz">Automotriz</option>
                                                    <option value="Construcción">Construcción</option>
                                                    <option value="Servicios Funerarios">Servicios Funerarios</option>
                                                    <option value="Otros">Otros</option>
                                                </FormSelect>
                                            </Col>
                                            <Col md="6" className="form-group">
                                                <label htmlFor="Consumption">KAM Asignado</label>
                                                <FormSelect
                                                    value={this.state.kam}
                                                    onChange={(e) => this.setState({kam: e.target.value})}
                                                    required
                                                >
                                                    <option value="">---</option>
                                                    {this.props.users.list.map((user, id) => (
                                                        <option key={id} value={user.id}>{user.username}</option>
                                                    ))}
                                                </FormSelect>
                                            </Col>
                                            <Col md="6" className="form-group">
                                                <label htmlFor="Consumption">Enviar Oportunidad a</label>
                                                <FormSelect
                                                    value={this.state.user_in_charge}
                                                    onChange={(e) => this.setState({user_in_charge: e.target.value})}
                                                >
                                                    <option value="">---</option>
                                                    {this.props.users.list.map((user, id) => (
                                                        <option key={id} value={user.id}>{user.username}</option>
                                                    ))}
                                                </FormSelect>
                                            </Col>
                                            <Col md="6" className="form-group">
                                                <label htmlFor="Consumption">Estado</label>
                                                <FormSelect
                                                    value={this.state.current_status}
                                                    onChange={(e) => this.setState({current_status: e.target.value})}
                                                >
                                                    <option value="">---</option>
                                                    {status_types.map((status, id) => (
                                                        <option key={id} value={status[0]}>{status[1]}</option>
                                                    ))}
                                                </FormSelect>
                                            </Col>

                                        </Row>
                                        <Row>
                                            <Col className="my-4">
                                                <Button type="submit" className="col-lg-12">Crear Cliente</Button>
                                            </Col>
                                        </Row>

                                    </Col>
                                </Row>

                            </Form>
                        </CardBody>
                    </Card>
                </Col>

                <Col lg="6" className="mb-8 my-4">
                    <Card small className="">
                        <CardHeader className="border-bottom">
                            <i className="icon icon-altacliente header-icon"></i>
                            <h6 className="m-0">Clientes Transferidos</h6>
                        </CardHeader>
                        <CardBody className="d-flex flex-column">
                            <Row>
                                <div className="table-responsive">

                                    <Col>
                                        <CustomTable
                                            tableHeaders={customer_header}
                                            tableBody={transferred_customer_table_items}
                                            tableClass="table-striped hover responsive"
                                            rowsPerPage={5}
                                            initialSort={{ prop: 'username', isAscending: true }}
                                            labels={customLabels}
                                        />
                                    </Col>
                                </div>

                            </Row>

                        </CardBody>
                    </Card>
                </Col>

            </Row>
            <Modal open={this.state.transfer_user_open} toggle={this.toggle} size="lg">
                <ModalHeader>Procesar Transferencia</ModalHeader>
                <ModalBody>
                    <Form onSubmit={this.submit_transfer}>
                        <Row>
                            <Col md="12">
                                <FormCheckbox
                                    checked={this.state.accept_transfer}
                                    id="new-contract"
                                    // onClick={(e) => this.setState({newContract: !e.target.value})}
                                    onClick={(e) => this.setState({accept_transfer: !this.state.accept_transfer})}
                                >
                                    Aceptar
                                </FormCheckbox>
                            </Col>
                            <Col md="12" hidden={this.state.accept_transfer}>
                                <label htmlFor="Consumption">Razón de rechazo</label>
                                <FormTextarea
                                    value={this.state.rejection_reason}
                                    onChange={(e) => this.setState({rejection_reason: e.target.value})}
                                    required={!this.state.accept_transfer}
                                >
                                </FormTextarea>
                            </Col>

                        </Row>
                        <Row>
                            <Col className="my-4">
                                <Button type="submit" className="col-lg-12">Enviar</Button>
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>
            </Modal>
        </Container></>;
        return container
    }

}
/**
 * Función que mapea el estado a las props del componente
 * @param {Object} state - Estado actual de la aplicación
 * @returns {Object} - Objeto que contiene las props mapeadas al estado
*/
const mapStateToProps = state => {
    return {
        customers: state.customers,
        users: state.users,
    }
};
/**
 * Función que mapea las acciones a las props del componente
 * @param {Function} dispatch - Función de redux para despachar acciones
 * @returns {Object} - Objeto que contiene las props mapeadas a las acciones
*/
const mapDispatchToProps = dispatch => {
    return {
        fetch_users: () => {
            dispatch(users.fetch_users())
        },
        fetch_notifications: () => {
            dispatch(users.fetch_notifications())
        },
        fetch_customers: () => {
            dispatch(customers.fetch_customers())
        },
        fetch_transferred_customers: () => {
            dispatch(customers.fetch_transferred_customers())
        },
        create_customer: (data) => {
            return dispatch(customers.create_customers(data))
        },
        fetch_pending_transfers: () => {
            return dispatch(customers.fetch_pending_transfers())
        },
        update_transfer: (data, id) => {
            return dispatch(customers.update_transfer(data, id))
        },
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(Customers);
