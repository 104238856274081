const initialState = [];

/**
 * Reductor que maneja el estado de la lista de archivos según las acciones que se reciban.
 * @param {Array} state - Estado actual de la lista de archivos.
 * @param {Object} action - Acción a realizar sobre la lista de archivos.
 * @param {string} action.type - Tipo de acción a realizar sobre la lista de archivos.
 * @param {Array} action.files - Lista de archivos a actualizar.
 * @returns {Array} - Estado actualizado de la lista de archivos.
*/
export default function files(state=initialState, action) {

  switch (action.type) {

    case 'FETCH_RECURRENT':
      return [...action.files];

    case 'UPDATE_RECURRENT':
      return state;

    default:
      return state;
  }
}