import React, {Component} from "react";
import {Col, Row} from "shards-react";
import {connect} from "react-redux";

import {customersV2} from "../../actions";
import StatusFields from "./StatusFields";

/**
 * Clase que representa el estado actual de un objeto
 * @extends Component
*/
// const Login = () => (
class CurrentStatus extends Component {
/**
 * Método que renderiza el estado actual del objeto
 * @returns {ReactNode} - Nodo React que representa el estado actual del objeto
*/
    render() {
        const {status, specific, groups} = this.props;
        let arr = [];

        Object.keys(status.sub_status).forEach(function (key) {
            arr.push(status.sub_status[key]);
        })
        console.log('SPECIFIC', specific, groups)
        if (!specific) {
            return (
                <Row>
                    <Col>
                        <ul>
                            {arr.map((block, id) => (
                                <li key={id}>
                                    {block.name}
                                    <ul>
                                        {block.fields.map((field, id) => (
                                            <StatusFields key={id} field={field} customer_id={this.props.customer} rejected={this.props.rejected}/>
                                        ))}
                                    </ul>
                                </li>
                            ))}
                        </ul>
                    </Col>

                </Row>
            )
        } else {
            return (
                <Row>
                    <Col>
                        <ul>
                            {arr.map((block, id) => {
                                console.log('BLOCK', block)
                                let intersections = groups.filter(e => block.tags.indexOf(e) !== -1);
                                console.log('INTERSECTIONS', intersections)
                                if (intersections.length < 1) return <div/>
                                return <li key={id}>
                                    {block.name}
                                    <ul>
                                        {block.fields.map((field, id) => (
                                            <StatusFields key={id} field={field} customer_id={this.props.customer} rejected={this.props.rejected}/>
                                        ))}
                                    </ul>
                                </li>
                            })}
                        </ul>
                    </Col>

                </Row>
            )
        }
    }
}
/**
 * Función que mapea el estado a las props del componente
 * @param {Object} state - Estado actual de la aplicación
 * @returns {Object} - Objeto que contiene las props mapeadas al estado
*/
const mapStateToProps = state => {
    return {};
};
/**
 * Función que mapea las acciones a las props del componente
 * @param {Function} dispatch - Función de redux para despachar acciones
 * @returns {Object} - Objeto que contiene las props mapeadas a las acciones
*/
const mapDispatchToProps = dispatch => {
    return {
        update_customer_storage: (id, file) => {
            return dispatch(customersV2.update_customer_storage(id, file))
        },
        fetch_customer: (id) => {
            dispatch(customersV2.fetch_customer(id))
        },
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(CurrentStatus);
