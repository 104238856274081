const initialState = {
  simulations: [],
  simulation: {},
  simulation_options: { months: [], indexes: [], tariffs: [], regions: [] },
  update_from_file: { errors: [] },
  data_updated: false,
};
/**
 * Función que maneja los cambios en el estado de las simulaciones.
 * @param {Object} state - El estado actual de las simulaciones.
 * @param {Object} action - La acción que se va a realizar.
 * @param {string} action.type - El tipo de acción a realizar.
 * @param {Object} action.simulations - Las simulaciones a mostrar.
 * @param {Object} action.simulation - La simulación a actualizar o mostrar.
 * @returns {Object} El nuevo estado de las simulaciones.
 */

export default function simulations(state = initialState, action) {
  switch (action.type) {
    case "FETCH_CMDT_SIMULATIONS":
      return { ...state, simulations: [...action.simulations] };
    // return [...action.simulations];

    case "CREATE_CMDT_SIMULATION":
      return {
        ...state,
        simulations: [action.simulation, ...state.simulations],
      };

    case "FETCH_CMDT_SIMULATION":
      return {
        ...state,
        simulation: action.simulation,
      };

    case "UPDATE_CMDT_SIMULATION":
      return { ...state, simulation: action.simulation };

    case "DELETE_CMDT_SIMULATION":
      return {
        ...state,
        simulations: state.simulations.filter(
          (item) => item.id !== action.simulation
        ),
      };

    case "FETCH_CMDT_OPTIONS":
      return {
        ...state,
        simulation_options: {
          months: action.options.months,
          indexes: action.options.indexes,
          tariffs: action.options.tariffs,
          regions: action.options.regions,
        },
      };

    case "UPDATE_DAILY_VALUES_CMDT":
      return {
        ...state,
        simulation: {
          ...state.simulation,
          days: [...state.simulation.days, { ...action.day }],
        },
      };
    case "UPDATE_FROM_FILE":
      return {...state, data_updated: true}
    default:
      return state;
  }
}
