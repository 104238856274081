import React from "react";
import PropTypes from "prop-types";
import { Navbar, NavbarBrand } from "shards-react";

import { Dispatcher, Constants } from "../../../flux";

// import logo from '../../../images/acerca_industrial_ch.png'
import logo from '../../../images/NTGY_CNAI_Logo_Horizontal.jpg'

/**
 * Componente que muestra la barra de navegación principal en la barra lateral.
 * @name SidebarMainNavbar
 * @component
 * @param {Object} props - Propiedades del componente.
 * @param {boolean} props.hideLogoText - Indica si se debe ocultar el texto del logo.
 * @returns {JSX.Element} Elemento JSX que representa la barra de navegación principal.
*/

class SidebarMainNavbar extends React.Component {
  /**
 * Constructor del componente.
 * @constructor
 * @param {Object} props - Propiedades del componente.
*/
  constructor(props) {
    super(props);

    this.handleToggleSidebar = this.handleToggleSidebar.bind(this);
  }
/** 
 * Manejador del evento para cambiar el estado de la barra lateral.
*/
  handleToggleSidebar() {
    Dispatcher.dispatch({
      actionType: Constants.TOGGLE_SIDEBAR
    });
  }
/**
 * Método que renderiza el componente.
 * @returns {JSX.Element} Elemento JSX que representa la barra de navegación principal.
*/
  render() {
    const { hideLogoText } = this.props;
    return (
      <div className="main-navbar">
        <Navbar
          className="align-items-stretch bg-white flex-md-nowrap border-bottom p-0"
          type="light"
        >
          <NavbarBrand
            className="w-100 mr-0"
            href="#"
            style={{ lineHeight: "25px" }}
          >
            <div className="d-table m-auto">
              {!hideLogoText && (
                <span className="d-none d-md-inline ml-1">
                  <img src={logo} alt="Acerca Industrial" className="logo-side"/>
                </span>
              )}
            </div>
          </NavbarBrand>
          <a
            className="toggle-sidebar d-sm-inline d-md-none d-lg-none"
            onClick={this.handleToggleSidebar}
          >
            <i className="material-icons">&#xE5C4;</i>
          </a>
        </Navbar>
      </div>
    );
  }
}

SidebarMainNavbar.propTypes = {
  /**
   * Whether to hide the logo text, or not.
   */
  hideLogoText: PropTypes.bool
};

SidebarMainNavbar.defaultProps = {
  hideLogoText: false
};

export default SidebarMainNavbar;
