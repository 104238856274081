import React, { Component } from "react";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Form,
    FormInput,
    Row,
} from "shards-react";

import PageTitle from "./../components/common/PageTitle";

import { connect } from "react-redux";

import { Link } from "react-router-dom";
import { reports } from "../actions";

/**
 * Componente para la generación de reportes de clientes.
 * @component
 */

class Reports extends Component {
  /**
   * Crea una instancia de Reports.
   * @constructor
   * @param {Object} props - Propiedades para el componente Reports.
   */
  constructor(props) {
    super(props);
    /**
     * Estado inicial del componente.
     * @type {Object}
     * @property {String} start_date - Fecha de inicio del rango para la generación del reporte.
     * @property {String} end_date - Fecha final del rango para la generación del reporte.
     * @property {Boolean} processing - Indica si se está procesando el reporte o no.
     */
    this.state = {
      start_date: "",
      end_date: "",
      processing: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.downloadTxtFile = this.downloadTxtFile.bind(this);
  }
  /**
   * Maneja el cambio en los valores de los campos del formulario.
   * @param {Object} event - Evento que disparó la función.
   */
  handleChange(event) {
    this.setState({
      ...this.state,
      [event.target.name]: event.target.value,
    });
  }
  /**
   * Maneja la acción de envío del formulario de generación de reporte.
   * @param {Object} event - Evento que disparó la función.
   */
  handleSubmit(event) {
    event.preventDefault();
    this.setState({ processing: true });
    this.props.create_report(this.state).then(this.downloadTxtFile);
  }
  /**
   * Descarga el archivo generado por el reporte.
   */
  downloadTxtFile = () => {
    const file = this.props.reports.file;
    if (file !== null) {
      console.log(file);
      console.log(`/api/customer_report/${file.id}/`);
      const newWindow = window.open(
        `/api/customer_report/${file.id}/`,
        "_self",
        "noopener,noreferrer,download"
      );
      this.setState({ processing: false });
      if (newWindow) newWindow.opener = null;
    }
  };
  /**
   * Obtiene los reportes del cliente una vez que el componente ha sido montado.
   */
  componentDidMount() {
    this.props.fetch_customer_2_reports();
  }

  render() {
    console.log(this.props.customer2_reports);
    let container = (
      <>
        <Container fluid className="main-content-container px-4">
          {/* Page Header */}
          <Row noGutters className="page-header py-4">
            <PageTitle title="Clientes" className="text-sm-left mb-3" />
          </Row>

          <Row>
            {/* New Draft */}
            <Col lg="12" className="mb-8 my-4">
              <Card small className="">
                <CardHeader className="border-bottom">
                  <i className="icon icon-altacliente header-icon" />
                  <h6 className="m-0">Generar Reporte de Clientes</h6>
                </CardHeader>
                <CardBody className="d-flex flex-column">
                  <Form onSubmit={this.handleSubmit}>
                    <Row>
                      <Col md="6" className="mb-3">
                        <label htmlFor="">Fecha Inicial</label>
                        <FormInput
                          size="lg"
                          type="date"
                          name="start_date"
                          onChange={this.handleChange}
                          value={this.state.start_date}
                          disabled={this.state.processing}
                          required
                        />
                      </Col>
                      <Col md="6" className="mb-3">
                        <label htmlFor="">Fecha Final</label>
                        <FormInput
                          size="lg"
                          type="date"
                          name="end_date"
                          onChange={this.handleChange}
                          value={this.state.end_date}
                          disabled={this.state.processing}
                          required
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col className="my-4">
                        <Button type="submit" className="col-lg-12">
                          Generar Reporte
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>

            <Col lg="12" className="mb-8 my-4">
              <Card small className="">
                <CardHeader className="border-bottom">
                  <i className="icon icon-altacliente header-icon" />
                  <h6 className="m-0">Generar reporte de Contactos</h6>
                </CardHeader>
                <CardBody className="d-flex flex-column">
                  <Row>
                    <Col className="my-4">
                      <Link
                        to={`/api/contact_report/`}
                        target="_blank"
                        className="btn btn-primary col-lg-12"
                        rel="noopener noreferrer"
                        download
                      >
                        Descargar
                      </Link>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            {this.props.customer2_reports.map((report, id) => (
              <Col lg="12" className="mb-8 my-4">
                <Card small className="">
                  <CardHeader className="border-bottom">
                    <i className="icon icon-altacliente header-icon" />
                    <h6 className="m-0">{report.name}</h6>
                  </CardHeader>
                  <CardBody className="d-flex flex-column">
                    <Row>
                      <Col className="my-4">
                        <Link
                          to={`/api/customer2_report/${report.id}/`}
                          target="_blank"
                          className="btn btn-primary col-lg-12"
                          rel="noopener noreferrer"
                          download
                        >
                          Descargar
                        </Link>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </>
    );
    return container;
  }
}
/**
 * Función que mapea el estado a las props del componente
 * @param {Object} state - Estado actual de la aplicación
 * @returns {Object} - Objeto que contiene las props mapeadas al estado
 */
const mapStateToProps = (state) => {
  return {
    reports: state.reports,
    customer2_reports: state.reports.customer2_reports,
  };
};
/**
 * Función que mapea las acciones a las props del componente
 * @param {Function} dispatch - Función de redux para despachar acciones
 * @returns {Object} - Objeto que contiene las props mapeadas a las acciones
 */
const mapDispatchToProps = (dispatch) => {
  return {
    create_report: (data) => {
      return dispatch(reports.create_customer_report(data));
    },
    fetch_customer_2_reports: () => {
      return dispatch(reports.fetch_customer_2_reports());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Reports);
