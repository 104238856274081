import React, {Component} from "react";
import {Button, Card, CardBody, CardHeader, Col, Container, Form, FormInput, FormTextarea, Row,} from "shards-react";
import 'react-dropzone-uploader/dist/styles.css'
import {announcements} from "../actions"
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import CustomTable from "../components/CustomTable";

/**
 * Componente de Anuncios
 * @class
 * @extends Component
*/

class Announcements extends Component {
/**
 * Estado inicial del componente
 * @type {Object}
 * @property {string} message - Mensaje del anuncio
 * @property {string} file - Archivo adjunto al anuncio
*/
    state = {
        message: "",
        file: "",
    };
/**
 * Método que se ejecuta después de que el componente ha sido montado
 * @memberof Announcements 
 * @method componentDidMount
 * @returns {void}
*/
    componentDidMount() {
        this.props.fetch_announcements();
        this.props.read_announcements();
    }
/**
 * Manejador de cambio de archivo
 * @memberof Announcements
 * @method fileChangeHandler
 * @param {Object} e - Evento de cambio de archivo
 * @returns {void}
*/
    fileChangeHandler = (e) => {
        this.setState({
            file: e.target.files[0],
        })
    };
/**
 * Reinicia el formulario de anuncios
 * @memberof Announcements
 * @method reset_form
 * @returns {void}
*/
    reset_form = () => {
        this.setState({message: "", file: ""})
    };
/**
 * Envía el anuncio al servidor
 * @memberof Announcements
 * @method submit_announcement
 * @param {Object} e - Evento de envío de formulario
 * @returns {void}
*/
    submit_announcement = (e) => {
        e.preventDefault();
        this.props.submit_announcement(this.state).then(this.reset_form());
    };
/**
 * Formatea una fecha en formato de fecha localizada
 * @memberof Announcements
 * @method format_date
 * @param {string} date_string - Cadena de fecha
 * @returns {string} - Fecha formateada
*/
    format_date(date_string) {
        let date = new Date(date_string);
        let config = new Intl.DateTimeFormat('es-MX');
        return config.format(date);
    }
/**
 * Método de renderizado del componente
 * @memberof Announcements
 * @method render
 * @returns {JSX.Element}
*/
    render() {
        console.log('ANNOUNCEMENT');
        let create_announcement = '';
        if (this.props.user.role === 3) {
            create_announcement = <Row>
                {/* New Draft */}
                <Col lg="12" className="mb-8 my-4">
                    <Card small className="h-100">
                        {/* Card Header */}
                        <CardHeader className="border-bottom">
                            {/*<i className="material-icons header-icon">notifications</i>*/}
                            <i className="icon icon-anuncios header-icon"></i>
                            <h6 className="m-0">Crear Aviso</h6>
                        </CardHeader>

                        <CardBody className="d-flex flex-column">
                            <Form onSubmit={this.submit_announcement}>
                                <Row form>
                                    <Col lg="8">
                                        <label htmlFor="Consumption">Mensaje</label>
                                        <FormTextarea
                                            type="text"
                                            value={this.state.message}
                                            onChange={(e) => this.setState({message: e.target.value})}
                                            required
                                        />
                                    </Col>
                                    <Col lg="4">
                                        <label htmlFor="Consumption">Archivo</label>
                                        <FormInput
                                            type="file"
                                            // placeholder="Razón Social"
                                            // value={this.state.file}
                                            onChange={this.fileChangeHandler}
                                            required
                                        />
                                    </Col>
                                    <Col lg="12" className="my-4">
                                        <Button type="submit">Crear Aviso</Button>
                                    </Col>
                                </Row>
                            </Form>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        }

        const customer_header = [
            {title: 'Mensaje', prop: 'message', filterable: true},
            {title: 'Fecha', prop: 'date', filterable: true},
            {title: 'Archivo', prop: 'file'},
        ];

        const customLabels = {
            first: '<<',
            last: '>>',
            prev: '<',
            next: '>',
            show: 'Display',
            entries: 'rows',
            noResults: 'No hay resultados',
            filterPlaceholder: 'Buscar',
        };

        const users_body = this.props.announcements.map((announcement, id) => {
            let file_url = '';
            if (announcement.file !== null) {
                file_url =
                    <Link to={`/files/download/${announcement.file.id}/`} target='_blank' className="btn btn-primary"
                          rel="noopener noreferrer" download>Descargar</Link>
            }
            return {
                message: <pre>{announcement.message}</pre>, date: this.format_date(announcement.created_at), file: file_url,
            }

        });

        return (
            <Container fluid className="main-content-container px-4">


                {create_announcement}

                <Row>
                    {/* New Draft */}
                    <Col lg="12" className="mb-8 my-4">
                        <Card small className="h-100">
                            {/* Card Header */}
                            <CardHeader className="border-bottom">
                                <i className="icon icon-anuncios header-icon"></i>
                                <h6 className="m-0">Avisos</h6>
                            </CardHeader>
                            <div className="table-responsive">
                                <CardBody className="d-flex flex-column">

                                    <CustomTable
                                        tableHeaders={customer_header}
                                        tableBody={users_body}
                                        tableClass="table-striped hover responsive"
                                        rowsPerPage={10}
                                        initialSort={{prop: 'email', isAscending: true}}
                                        labels={customLabels}
                                    />

                                </CardBody>
                            </div>
                        </Card>
                    </Col>
                </Row>

            </Container>
        )
    }

}
/**
 * Función que mapea el estado a las props del componente
 * @param {Object} state - Estado actual de la aplicación
 * @returns {Object} - Objeto que contiene las props mapeadas al estado
*/
const mapStateToProps = state => {
    return {
        announcements: state.announcements,
        user: state.auth.user,
    }
};
/**
 * Función que mapea las acciones a las props del componente
 * @param {Function} dispatch - Función de redux para despachar acciones
 * @returns {Object} - Objeto que contiene las props mapeadas a las acciones
*/
const mapDispatchToProps = dispatch => {
    return {
        fetch_announcements: () => {
            dispatch(announcements.fetch_announcements());
        },
        submit_announcement: (data) => {
            return dispatch(announcements.create_announcement(data));
        },
        read_announcements: () => {
            return dispatch(announcements.read_announcements());
        },
        // shareFile: (id, shared_with) => {
        //     dispatch(files.shareFile(id, shared_with));
        // }
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(Announcements);
