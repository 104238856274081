import React from "react";
import {Redirect, Route} from "react-router-dom";
import PrivateRoute from "./components/common/PrivateRoute";
import GroupRoute from "./components/common/GroupRoute";
// Layout Types
import {DefaultLayout} from "./layouts";
// Route Views
import BlogOverview from "./views/BlogOverview";
import UserProfileLite from "./views/UserProfileLite";
import AddNewPost from "./views/AddNewPost";
import Errors from "./views/Errors";
import ComponentsOverview from "./views/ComponentsOverview";
import Tables from "./views/Tables";
import BlogPosts from "./views/BlogPosts";
import LoginLayout from "./layouts/Login";
import Login from "./views/Login";
import Files from "./views/Files";
import Simulation from "./views/Simulation";
import RecurrentFiles from "./views/RecurrentFiles";
import Announcements from "./views/Announcements";
import Customers from "./views/Customers";
import CustomerDetail from "./views/CustomerDetail";
import CreatePoll from "./views/CreatePoll";
import Poll from "./views/Poll";
import AdminUsers from "./views/AdminUsers";
import AdminRoute from "./components/common/AdminRoute";
import Home from "./views/Home";
import UploadSimulatorData from "./views/UploadSimulatorData";
import DTT from "./views/DTT";
import Canon from "./views/Canon";
import Reports from "./views/Reports";
import Customers2 from "./views/Customers2";
import CustomerDetail2 from "./views/CustomerDetail2";
import Customers3 from "./views/Customers3";
import AllCustomers from "./views/AllCustomers";
import GenericSimulation from "./views/GenericSimulation";
import UpdateDataFromFile from "./views/UpdateDataFromFile";
import SimulationCMDT from "./views/SimulationCMDT";
import simulationFAI from "./views/SimulationFAI";
import simulationPricingSteps from "./views/SimulationPricingSteps";
import SimulationSwingAndInterruptible from "./views/SwingAndInterruptible";

/**
 * Lista de rutas de la aplicación.
 * @typedef {Object} Route
 * @property {string} path - Ruta de la página.
 * @property {boolean} exact - Determina si la ruta coincide exactamente con la URL.
 * @property {React.Component} layout - Componente de diseño de la página.
 * @property {React.Component} component - Componente de la página.
 * @property {React.Component} routeComponent - Componente de la ruta.
 * @property {string[]} groups - Grupos de usuarios con permisos para acceder a la página.
 * @property {boolean} admin - Indica si la ruta es accesible solo para administradores.
*/

export default [
    {
        path: "/login",
        exact: true,
        layout: LoginLayout,
        component: Login,
        routeComponent: Route,
    },
    {
        path: "/",
        exact: true,
        layout: DefaultLayout,
        component: () => <Redirect to="/inicio"/>,
        routeComponent: PrivateRoute,
        groups: [""],
    },
    {
        path: "/inicio",
        exact: true,
        layout: DefaultLayout,
        component: Home,
        routeComponent: PrivateRoute,
        groups: [""],
    },
    {
        path: "/files",
        exact: true,
        layout: DefaultLayout,
        component: Files,
        routeComponent: PrivateRoute,
        groups: [""],
    },
    {
        path: "/announcements",
        exact: true,
        layout: DefaultLayout,
        component: Announcements,
        routeComponent: PrivateRoute,
        groups: [""],
    },
    {
        path: "/reportes",
        exact: true,
        layout: DefaultLayout,
        component: Reports,
        groups: ["reports"],
        routeComponent: GroupRoute,
    },
    {
        path: "/materiales_recurrentes",
        exact: true,
        layout: DefaultLayout,
        component: RecurrentFiles,
        routeComponent: PrivateRoute,
        groups: [""],
    },
    {
        path: "/actualizar_datos",
        exact: true,
        layout: DefaultLayout,
        component: UpdateDataFromFile,
        routeComponent: PrivateRoute,
        groups: [""],
    },
    {
        path: "/clientes",
        exact: true,
        layout: DefaultLayout,
        component: Customers,
        routeComponent: PrivateRoute,
        groups: [""],
    },
    {
        path: "/crm_clientes",
        exact: true,
        layout: DefaultLayout,
        component: Customers2,
        routeComponent: PrivateRoute,
        groups: [""],
    },
    {
        path: "/customers_status",
        exact: true,
        layout: DefaultLayout,
        component: Customers3,
        routeComponent: PrivateRoute,
        groups: [""],
    },
    {
        path: "/all_customers",
        exact: true,
        layout: DefaultLayout,
        component: AllCustomers,
        routeComponent: PrivateRoute,
        groups: [""],
    },
    {
        path: "/clients/:id",
        exact: true,
        layout: DefaultLayout,
        component: CustomerDetail2,
        routeComponent: PrivateRoute,
        groups: [""],
    },
    {
        path: "/clientes/:id",
        exact: true,
        layout: DefaultLayout,
        component: CustomerDetail,
        routeComponent: PrivateRoute,
        groups: [""],
    },
    {
        path: "/clientes/encuestas/crear/:id",
        exact: true,
        layout: DefaultLayout,
        component: CreatePoll,
        routeComponent: PrivateRoute,
        groups: [""],
    },
    {
        path: "/clientes/:id/encuestas/:poll_id",
        exact: true,
        layout: DefaultLayout,
        component: Poll,
        routeComponent: PrivateRoute,
        groups: [""],
    },
    {
        path: "/simulations/:id",
        exact: true,
        layout: DefaultLayout,
        component: Simulation,
        routeComponent: PrivateRoute,
        groups: [""],
    },
    {
        path: "/simulacion/:id",
        exact: true,
        layout: DefaultLayout,
        component: GenericSimulation,
        routeComponent: PrivateRoute,
        groups: [""],
    },
    {
        path: "/simulacion/cmdt/:id",
        exact: true,
        layout: DefaultLayout,
        component: SimulationCMDT,
        routeComponent: PrivateRoute,
        groups: [""],
    },
    {
        path: "/simulacion/fai/:id",
        exact: true,
        layout: DefaultLayout,
        component: simulationFAI,
        routeComponent: PrivateRoute,
        groups: [""],
    },
    {
        path: "/simulacion/pss/:id",
        exact: true,
        layout: DefaultLayout,
        component: simulationPricingSteps,
        routeComponent: PrivateRoute,
        groups: [""],
    },
    {
        path: "/simulacion/sai/:id",
        exact: true,
        layout: DefaultLayout,
        component: SimulationSwingAndInterruptible,
        routeComponent: PrivateRoute,
        groups: [""],
    },
    {
        path: "/administracion/usuarios",
        exact: true,
        layout: DefaultLayout,
        component: AdminUsers,
        routeComponent: AdminRoute,
        admin: true,
        groups: [""],
    },
    {
        path: "/administracion/usuarios/:user_id",
        exact: true,
        layout: DefaultLayout,
        component: AdminUsers,
        routeComponent: AdminRoute,
        admin: true,
        groups: [""],
    },
    {
        path: "/administracion/simuladores/datos",
        exact: true,
        layout: DefaultLayout,
        component: UploadSimulatorData,
        routeComponent: AdminRoute,
        admin: true,
        groups: [""],
    },
    {
        path: "/blog-overview",
        layout: DefaultLayout,
        component: BlogOverview,
        routeComponent: PrivateRoute,
        groups: [""],
    },
    {
        path: "/user-profile-lite",
        layout: DefaultLayout,
        component: UserProfileLite,
        routeComponent: PrivateRoute,
        groups: [""],
    },
    {
        path: "/add-new-post",
        layout: DefaultLayout,
        component: AddNewPost,
        routeComponent: PrivateRoute,
        groups: [""],
    },
    {
        path: "/errors",
        layout: DefaultLayout,
        component: Errors,
        routeComponent: PrivateRoute,
        groups: [""],
    },
    {
        path: "/components-overview",
        layout: DefaultLayout,
        component: ComponentsOverview,
        routeComponent: PrivateRoute,
        groups: [""],
    },
    {
        path: "/tables",
        layout: DefaultLayout,
        component: Tables,
        routeComponent: PrivateRoute,
        groups: [""],
    },
    {
        path: "/blog-posts",
        layout: DefaultLayout,
        component: BlogPosts,
        routeComponent: PrivateRoute,
        groups: [""],
    },
    {
        path: "/dtt",
        layout: DefaultLayout,
        component: DTT,
        routeComponent: PrivateRoute,
        groups: [""],
    },
    {
        path: "/canon/:id",
        exact: true,
        layout: DefaultLayout,
        component: Canon,
        routeComponent: PrivateRoute,
        groups: [""],
    },
];
