import React, {Component} from "react";
import {Col, Container, Row} from "shards-react";

import PageTitle from "./../components/common/PageTitle";

import {connect} from "react-redux";
import {customersV2} from "../actions";
import CustomerStatusCard from "../components/customer/CustomerStatusCard";

/**
 * @classdesc Componente de la página de clientes.
 * @class
 * @extends Component
*/
class Customers3 extends Component {
/**
 * Crea una instancia de Customers3.
 * @constructor
 * @param {Object} props - Propiedades pasadas al componente.
*/
    constructor(props) {
        super(props);
        this.state = {
            'name': '',
        };

    }
/**
 * Se ejecuta después de que el componente se haya montado.
*/
    componentDidMount() {
        this.props.fetch_customers_status();
    }
/**
 * Renderiza la página de clientes.
 * @returns {Object} container - Contenedor de la página de clientes.
*/
    render() {
        // console.log(this.props);
        const status_list = this.props.customers.by_status;
        console.log(status_list)

        let container = <><Container fluid className="main-content-container px-4">
            {/* Page Header */}
            <Row noGutters className="page-header py-4">
                <PageTitle title="Clientes" className="text-sm-left mb-3"/>
            </Row>

            <Row>


                {status_list.map((status, id) => (
                    <Col lg="6" className="mb-8 my-4">
                        <CustomerStatusCard status={status}/>
                    </Col>

                ))}


            </Row>

        </Container></>;
        return container
    }

}
/**
 * Función que mapea el estado a las props del componente
 * @param {Object} state - Estado actual de la aplicación
 * @returns {Object} - Objeto que contiene las props mapeadas al estado
*/
const mapStateToProps = state => {
    return {
        customers: state.customersV2,
    }
};
/**
 * Función que mapea las acciones a las props del componente
 * @param {Function} dispatch - Función de redux para despachar acciones
 * @returns {Object} - Objeto que contiene las props mapeadas a las acciones
*/
const mapDispatchToProps = dispatch => {
    return {
        fetch_customers_status: () => {
            dispatch(customersV2.fetch_customers_status())
        },
        create_customer: (data) => {
            return dispatch(customersV2.create_customer(data))
        },
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(Customers3);
