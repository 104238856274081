import React from "react";

import { Dispatcher, Constants } from "../../../flux";

/**
 * Representa el botón de alternar la barra lateral de la navegación en la versión móvil.
 * @name NavbarToggle
 * @constructor
 * @extends React.Component
*/

class NavbarToggle extends React.Component {
  /**
 * Crea una instancia del NavbarToggle.
 * @param {object} props - Las props iniciales del componente.
*/
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
  }
/**
 * Maneja el evento click del botón de alternar barra lateral.
 * Despacha una acción para cambiar el estado de la barra lateral.
*/
  handleClick() {
    Dispatcher.dispatch({
      actionType: Constants.TOGGLE_SIDEBAR
    });
  }
/**
 * Renderiza el botón de alternar barra lateral.
 * @returns {JSX.Element} El componente JSX del botón de alternar barra lateral.
*/
  render() {
    return (
      <nav className="nav">
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a href="#" onClick={this.handleClick} className="nav-link nav-link-icon toggle-sidebar d-sm-inline d-md-inline d-lg-none text-center">
          <i className="material-icons">&#xE5D2;</i>
        </a>
      </nav>
    )
  }
}

export default NavbarToggle;
