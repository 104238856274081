import axios from "axios";

/**
 * Obtiene las simulaciones de un cliente específico desde la API y las almacena en el estado.
 * @name fetchSimulations
 * @param {Number} customerId - El ID del cliente cuyas simulaciones se quieren obtener.
 * @returns {Promise} Una promesa que se resuelve con el resultado de la acción.
 */

export const fetchSimulations = (customerId) => {
  return (dispatch, getState) => {
    let headers = { "Content-Type": "application/json" };
    let { token } = getState().auth;

    if (token) {
      headers["Authorization"] = `Token ${token}`;
    }

    return axios
      .get(`/api/simulators/?customer=${customerId}`, { headers })
      .then((res) => {
        return dispatch({ type: "FETCH_SIMULATIONS", simulations: res.data });
      })
      .catch((err) => {
        console.log("ERROR");
        console.log(err);
        console.log(err.response.status);
      });
  };
};

/**
 * Obtiene una simulación específica desde la API y la almacena en el estado.
 * @name fetchSimulation
 * @param {Number} simulationId - El ID de la simulación que se desea obtener.
 * @returns {Promise} Una promesa que se resuelve con el resultado de la acción.
 */

export const fetchSimulation = (simulationId) => {
  return (dispatch, getState) => {
    let headers = { "Content-Type": "application/json" };
    let { token } = getState().auth;

    if (token) {
      headers["Authorization"] = `Token ${token}`;
    }

    return axios
      .get(`/api/simulators/${simulationId}/`, { headers })
      .then((res) => {
        return dispatch({ type: "FETCH_SIMULATION", simulation: res.data });
      })
      .catch((err) => {
        console.log("ERROR");
        console.log(err);
        console.log(err.response.status);
      });
  };
};

/**
 * Función para crear una simulación.
 * @name createSimulation
 * @param {number} contract_id - ID del contrato del cliente.
 * @return {Function} - Retorna una función con dispatch y getState como argumentos.
 */

export const createSimulation = (contract_id) => {
  return (dispatch, getState) => {
    let headers = { "Content-Type": "application/json" };
    let { token } = getState().auth;

    if (token) {
      headers["Authorization"] = `Token ${token}`;
    }

    let body = JSON.stringify({
      customer: contract_id,
    });

    return axios
      .post(`/api/simulators/`, body, { headers })
      .then((res) => {
        return dispatch({ type: "CREATE_SIMULATION", simulation: res.data });
      })
      .catch((err) => {
        console.log("ERROR");
        console.log(err);
        console.log(err.response.status);
      });
  };
};

/**
 * Función para actualizar una simulación.
 * @name updateSimulation
 * @param {number} simulation_id - ID de la simulación.
 * @param {Object} values - Objeto con las propiedades a actualizar.
 * @return {Function} - Retorna una función con dispatch y getState como argumentos.
 */

export const updateSimulation = (simulation_id, values) => {
  return (dispatch, getState) => {
    let headers = { "Content-Type": "application/json" };
    let { token } = getState().auth;

    if (token) {
      headers["Authorization"] = `Token ${token}`;
    }

    let body = JSON.stringify({
      month: values.month,
      monthly_consumption: values.monthly_consumption,
      cmd: values.cmd,
      spread: values.spread,
      management_cost: values.management_cost,
      region: values.region,
      index: values.index,
      tariff: values.tariff,
      additional_fields: values.additional_fields,
    });

    return axios
      .patch(`/api/simulators/${simulation_id}/`, body, { headers })
      .then((res) => {
        return dispatch({ type: "UPDATE_SIMULATION", simulation: res.data });
      })
      .catch((err) => {
        console.log("ERROR");
        console.log(err);
        console.log(err.response);
      });
  };
};

export const deleteSimulation = (simulation_id) => {
  return (dispatch, getState) => {
    let headers = { "Content-Type": "application/json" };
    let { token } = getState().auth;

    if (token) {
      headers["Authorization"] = `Token ${token}`;
    }

    return axios
      .delete(`/api/simulators/${simulation_id}/`, { headers })
      .then((res) => {
        return dispatch({
          type: "DELETE_SIMULATION",
          simulation: simulation_id,
        });
      })
      .catch((err) => {
        console.log("ERROR");
        console.log(err);
        console.log(err.response.status);
      });
  };
};

/**
 * Recupera las opciones disponibles.
 * @name fetchOptions
 * @return {Function} Función que devuelve una promesa con los datos de las opciones recuperadas.
 */

export const fetchOptions = () => {
  return (dispatch, getState) => {
    let headers = { "Content-Type": "application/json" };
    let { token } = getState().auth;

    if (token) {
      headers["Authorization"] = `Token ${token}`;
    }

    return axios
      .get(`/api/options/`, { headers })
      .then((res) => {
        return dispatch({ type: "FETCH_OPTIONS", options: res.data });
      })
      .catch((err) => {
        console.log("ERROR");
        console.log(err);
        console.log(err.response.status);
      });
  };
};

/**
 * Elimina un canon específico.
 * @name deleteCanon
 * @param {Number} canon_id - El ID del canon a eliminar.
 * @return {Function} Función que devuelve una promesa con la respuesta del servidor.
 */

export const deleteCanon = (canon_id) => {
  return (dispatch, getState) => {
    let headers = { "Content-Type": "application/json" };
    let { token } = getState().auth;

    if (token) {
      headers["Authorization"] = `Token ${token}`;
    }

    return axios
      .delete(`/api/canon/${canon_id}/`, { headers })
      .then((res) => {
        return dispatch({ type: "DELETE_CANON", canon: null });
      })
      .catch((err) => {
        console.log("ERROR");
        console.log(err);
        console.log(err.response.status);
      });
  };
};

/**
 * Crea uno nuevo para una simulación dada.
 * @name createCanon
 * @param {number} simulation_id - El ID de la simulación para la que se desea crear el cañón.
 * @returns {function} - Una función que recibe dispatch y getState y devuelve una promesa que se resuelve con la acción 'CREATE_CANON' si la solicitud es exitosa.
 */

export const createCanon = (simulation_id) => {
  return (dispatch, getState) => {
    let headers = { "Content-Type": "application/json" };
    let { token } = getState().auth;

    if (token) {
      headers["Authorization"] = `Token ${token}`;
    }
    let data = {
      simulation: simulation_id,
    };

    return axios
      .post(`/api/canon/`, data, { headers })
      .then((res) => {
        console.log("CREATED_CANON", res.data);
        return dispatch({ type: "CREATE_CANON", canon: res.data });
      })
      .catch((err) => {
        console.log("ERROR");
        console.log(err);
        console.log(err.response.status);
      });
  };
};

/**
 * Actualiza uno existente con los nuevos datos proporcionados.
 * @name updateCannon
 * @param {number} canon_id - El ID del cañón que se desea actualizar.
 * @param {object} data - Los nuevos datos para el cañón.
 * @returns {function} - Una función que recibe dispatch y getState y devuelve una promesa que se resuelve con la acción 'UPDATE_CANON' si la solicitud es exitosa.
 */

export const updateCannon = (canon_id, data) => {
  return (dispatch, getState) => {
    let headers = { "Content-Type": "application/json" };
    let { token } = getState().auth;

    if (token) {
      headers["Authorization"] = `Token ${token}`;
    }

    return axios
      .patch(`/api/canon/${canon_id}/`, data, { headers })
      .then((res) => {
        return dispatch({ type: "UPDATE_CANON", canon: res.data });
      })
      .catch((err) => {
        console.log("ERROR");
        console.log(err);
        console.log(err.response.status);
      });
  };
};
