import React, {Component} from "react";
import {Alert, Button, Card, CardBody, CardHeader, Col, Container, Form, FormInput, Row} from "shards-react";

import {connect} from "react-redux";
import {simulations} from "../actions";

/**
 * Componente que permite actualizar datos a partir de un archivo.
 * @class
 * @extends Component
*/
class UpdateDataFromFile extends Component {
/**
 * Crea una instancia de UpdateDataFromFile.
 * @constructor
 * @param {object} props - Propiedades del componente.
*/
    constructor(props) {
        super(props);
        this.state = {
            'file': "",
        };

    }

/**
 * Limpia el estado de file.
 * @function
*/
    clear_all = () => {
        this.setState({
            file: "",
        })
    };
/**
 * Manejador de eventos que actualiza el estado de file con el archivo seleccionado.
 * @function
 * @param {object} e - Evento del cambio de archivo.
*/
    fileChangeHandler = (e) => {
        this.setState({
            file: e.target.files[0],
        })
    };
/**
 * Manejador de eventos que envía el archivo al servidor y luego limpia el estado de file.
 * @function
 * @param {object} e - Evento del envío del formulario.
*/
    submit_file = (e) => {
        e.preventDefault();
        this.props.update_from_file(this.state).then(this.clear_all());
    };
/**
 * Renderiza el componente UpdateDataFromFile.
 * @function
 * @returns {JSX.Element} - Componente React que renderiza el formulario para subir el archivo.
*/
    render() {
        console.log('OVER HERE', this.props.updated);

        return <Container fluid className="main-content-container px-4">
            {this.props.updated && <Alert className="mb-0" theme="success">
                Datos actualizados existosamente
            </Alert>}

            <Row>
                <Col lg="12" className="mb-8 my-4">
                    <Card small className="">
                        <CardHeader className="border-bottom">
                            <i className="icon icon-altacliente header-icon"/>
                            {/*<h6 className="m-0"></h6>*/}
                        </CardHeader>
                        <CardBody className="d-flex flex-column">
                            <Form onSubmit={this.submit_file}>
                                <Row>
                                    <Col lg="12" className="form-group">
                                        <label>Archivo</label>
                                        <FormInput
                                            type="file"
                                            onChange={this.fileChangeHandler}
                                            required
                                        />
                                    </Col>
                                    <Col lg="12" className="my-4">
                                        <Button type="submit" className="col-lg-12">Agregar Archivo</Button>
                                    </Col>
                                </Row>
                            </Form>
                        </CardBody>
                    </Card>
                </Col>


            </Row>

        </Container>;

    }

}
/**
 * Función que mapea el estado a las props del componente
 * @param {Object} state - Estado actual de la aplicación
 * @returns {Object} - Objeto que contiene las props mapeadas al estado
*/
const mapStateToProps = state => {
    return {
        customer: state.customersV2.detail,
        updated: state.simulations.data_updated,
    }
};
/**
 * Función que mapea las acciones a las props del componente
 * @param {Function} dispatch - Función de redux para despachar acciones
 * @returns {Object} - Objeto que contiene las props mapeadas a las acciones
*/
const mapDispatchToProps = dispatch => {
    return {
        update_from_file: (data) => {
            return dispatch(simulations.update_from_file(data))
        },
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(UpdateDataFromFile);
