import React from "react";
import {
    Filter,
    Pagination,
    PaginationOpts,
    shouldTableUpdate,
    TableBody,
    TableHeader,
    useDatatableLifecycle
} from "react-bs-datatable";

/**
 * Componente que renderiza una tabla personalizada con opciones de filtrado, paginación y ordenamiento.
 * @name CustomTable
 * @param {Object} props - Objeto que contiene las propiedades necesarias para renderizar la tabla.
 * @param {Array} props.data - Datos a mostrar en la tabla.
 * @param {Array} props.rowsPerPageOption - Opciones de cantidad de filas por página.
 * @param {Array} props.tableHeaders - Encabezados de la tabla.
 * @param {Function} props.onChangeFilter - Función que se ejecuta cuando se realiza un cambio en el filtro de búsqueda.
 * @param {Function} props.onPageNavigate - Función que se ejecuta cuando se cambia de página.
 * @param {Object} props.classes - Objeto que contiene las clases CSS para los diferentes elementos de la tabla.
 * @param {Function} props.onRowsPerPageChange - Función que se ejecuta cuando se cambia la cantidad de filas por página.
 * @param {Function} props.onSortChange - Función que se ejecuta cuando se cambia el ordenamiento de la tabla.
 * @param {String} props.tableClass - Clase CSS para aplicar a la tabla.
 * @param {Object} props.labels - Objeto que contiene las etiquetas para los diferentes elementos de la tabla.
 * @param {Boolean} props.filterable - Indica si la tabla es filtrable.
 * @param {String} props.filterText - Texto a buscar en la tabla.
 * @param {Number} props.rowsPerPage - Cantidad de filas por página.
 * @param {Number} props.currentPage - Página actual.
 * @param {String} props.sortedProp - Propiedad por la cual se está ordenando la tabla.
 * @param {Number} props.maxPage - Página máxima.
 * @param {Object} props.Components - Objeto que contiene los componentes que se van a utilizar en la tabla.
 * @param {React.Component} props.Components.Row - Componente de fila.
 * @param {React.Component} props.Components.Col - Componente de columna.
 * @param {React.Component} props.Components.Adornment - Componente para adornar.
 * @param {React.Component} props.Components.Button - Componente de botón.
 * @param {React.Component} props.Components.ClearIcon - Componente de ícono para limpiar.
 * @param {React.Component} props.Components.FormControl - Componente para control de formulario.
 * @param {React.Component} props.Components.InputGroup - Componente de grupo de entrada.
 * @param {React.Component} props.Components.Table - Componente de tabla.
 * @param {React.Component} props.Components.TableHeader - Componente de encabezado de tabla.
 * @param {React.Component} props.Components.TableCell - Componente de celda de tabla.
 * @param {React.Component} props.Components.TableRow - Componente de fila de tabla.
 * @param {React.Component} props.Components.TableBody - Componente de cuerpo de tabla.
 * @param {React.Component} props.Components.Form - Componente de formulario.
 * @param {React.Component} props.Components.FormGroup - Componente de grupo de formulario.
 * @param {React.Component} props.Components.ButtonGroup - Componente de grupo de botones.
 * @returns {JSX.Element} Tabla personalizada.
*/


const CustomTable = React.memo(props => {
  const {
    data,
    rowsPerPageOption,
    tableHeaders,
    onChangeFilter,
    onPageNavigate,
    classes,
    onRowsPerPageChange,
    onSortChange,
    tableClass,
    labels,
    filterable,
    filterText,
    rowsPerPage,
    currentPage,
    sortedProp,
    maxPage,
    Components
  } = useDatatableLifecycle(props);

  return (
    <>
      <Components.Row className="controlRow__root">
        <Components.Col xs="12">
          <Filter
            classes="My Class"
            tableHeaders={tableHeaders}
            placeholder={labels.filterPlaceholder}
            onChangeFilter={onChangeFilter}
            filterText={filterText}
            filterable={filterable}
            components={{
              Adornment: Components.Adornment,
              Button: Components.Button,
              ClearIcon: Components.ClearIcon,
              FormControl: Components.FormControl,
              InputGroup: Components.InputGroup
            }}
          />
        </Components.Col>
      </Components.Row>
      <Components.Row>
        <Components.Col xs="12">
          <Components.Table className={tableClass}>
            <TableHeader
              classes={classes}
              tableHeaders={tableHeaders}
              sortedProp={sortedProp}
              onSortChange={onSortChange}
              components={{
                TableHead: Components.TableHead,
                TableCell: Components.TableCell,
                TableRow: Components.TableRow
              }}
            />
            <TableBody
              classes={classes}
              tableHeaders={tableHeaders}
              labels={labels}
              data={data}
              components={{
                TableBody: Components.TableBody,
                TableCell: Components.TableCell,
                TableRow: Components.TableRow
              }}
            />
          </Components.Table>
        </Components.Col>
      </Components.Row>
      <Components.Row className="controlRow__root bottom">
        <Components.Col xs={12} />
        <Components.Col xs={12}>
          <PaginationOpts
            classes={classes}
            labels={labels}
            onRowsPerPageChange={onRowsPerPageChange}
            rowsPerPage={rowsPerPage}
            rowsPerPageOption={rowsPerPageOption}
            components={{
              Form: Components.Form,
              FormGroup: Components.FormGroup,
              FormControl: Components.FormControl
            }}
          />
        </Components.Col>
        <Components.Col xs={12} md={4} className="text-right">
          <Pagination
            classes={classes}
            data={data}
            rowsPerPage={rowsPerPage}
            currentPage={currentPage}
            onPageNavigate={onPageNavigate}
            labels={labels}
            maxPage={maxPage}
            components={{
              Button: Components.Button,
              ButtonGroup: Components.ButtonGroup
            }}
          />
        </Components.Col>
      </Components.Row>
    </>
  );
}, shouldTableUpdate);

export default CustomTable;