import React, {Component} from "react";
import {connect} from "react-redux";

import {BrowserRouter as Router} from "react-router-dom";
import routes from "../routes";
import withTracker from "../withTracker";

/**
 * Componente de rutas que define las rutas de la aplicación y renderiza los componentes correspondientes.
 * @name Routes
 * @component
*/

class Routes extends Component {
/**
 * Función que renderiza el componente de administrador si el usuario es un administrador.
 * @param {Component} admin_component - El componente de administrador.
 * @param {User} admin_user - El usuario administrador.
*/
    render_admin = (admin_component, admin_user) => {

    };
/**
 * Renderiza las rutas de la aplicación y los componentes correspondientes.
 * @returns {JSX.Element} Las rutas de la aplicación.
*/
    render() {
        return (
            <Router>
                <div>
                    {routes.map((route, index) => {
                        return (
                            <route.routeComponent
                                key={index}
                                path={route.path}
                                exact={route.exact}
                                groups={route.groups}
                                component={withTracker(props => {
                                    return (
                                        <route.layout {...props}>
                                            <route.component {...props} />
                                        </route.layout>
                                    );
                                })}
                            />
                        );
                    })}
                </div>
            </Router>
        )
    }
}
/**
 * Función que mapea el estado a las props del componente
 * @param {Object} state - Estado actual de la aplicación
 * @returns {Object} - Objeto que contiene las props mapeadas al estado
*/
const mapStateToProps = state => {

    return {
        user: state.auth.user,
    };
};
/**
 * Función que mapea las acciones a las props del componente
 * @param {Function} dispatch - Función de redux para despachar acciones
 * @returns {Object} - Objeto que contiene las props mapeadas a las acciones
*/
const mapDispatchToProps = dispatch => {
    return {

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Routes);