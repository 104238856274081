import React, {Component} from "react";
import {Button, Card, CardBody, CardHeader, Col, Container, Form, FormInput, Row} from "shards-react";

import PageTitle from "./../components/common/PageTitle";

import {connect} from "react-redux";
import {customersV2} from "../actions";
import {Link} from "react-router-dom";


class AllCustomers extends Component {

    constructor(props) {
        super(props);
        this.state = {
            'search': '',
        };

    }

    componentDidMount() {
        this.props.fetch_all_customers(this.state.search);
    }

    submit_search = (e) => {
        e.preventDefault();
        console.log('SEARCH', this.state.search);
        this.props.fetch_all_customers(this.state.search);
    }

    render() {
        console.log(this.props.customers.all);
        const {all} = this.props.customers;
        let customers = [];

        if (all !== null) {
            customers = all.results;
        }

        console.log(all)

        let container = <><Container fluid className="main-content-container px-4">
            <Row noGutters className="page-header py-4">
                <PageTitle title="Clientes" className="text-sm-left mb-3"/>
            </Row>

            <Col lg="12" className="mb-8 my-4">
                <Card small className="">
                    <CardHeader className="border-bottom">
                        <i className="icon icon-altacliente header-icon"></i>
                        <h6 className="m-0">Clientes Actuales</h6>
                    </CardHeader>
                    <CardBody className="d-flex flex-column">
                        <Form onSubmit={this.submit_search}>
                            <Row>
                                <Col>
                                    <Row form>

                                        <Col md="12" className="form-group">
                                            <FormInput
                                                id="company-name"
                                                type="text"
                                                placeholder="Razón Social, correo de KAM"
                                                value={this.state.search}
                                                onChange={(e) => this.setState({search: e.target.value})}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="">
                                            <Button type="submit" className="col-lg-12">Buscar</Button>
                                        </Col>
                                    </Row>

                                </Col>
                            </Row>

                        </Form>
                        <table className="table mb-0 my-4">
                            <thead className="bg-light">
                            <tr>
                                <th scope="col" className="border-0">
                                    Nombre
                                </th>
                                <th scope="col" className="border-0">
                                    KAM
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            {customers.map((customer, id) => (
                                <tr key={customer.id}>
                                    <td><Link to={`/clientes/${customer.id}`}>{customer.company_name}</Link></td>
                                    <td>{customer.kam}</td>
                                </tr>
                            ))}

                            </tbody>
                        </table>
                    </CardBody>
                </Card>
            </Col>

        </Container></>;
        return container
    }

}
/**
 * Función que mapea el estado a las props del componente
 * @param {Object} state - Estado actual de la aplicación
 * @returns {Object} - Objeto que contiene las props mapeadas al estado
*/
const mapStateToProps = state => {
    return {
        customers: state.customersV2,
    }
};
/**
 * Función que mapea las acciones a las props del componente
 * @param {Function} dispatch - Función de redux para despachar acciones
 * @returns {Object} - Objeto que contiene las props mapeadas a las acciones
*/
const mapDispatchToProps = dispatch => {
    return {
        fetch_all_customers: (search) => {
            dispatch(customersV2.fetch_all_customers(search))
        },
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(AllCustomers);
