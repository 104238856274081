import axios from "axios";


/**
 * Obtiene los archivos recurrentes del servidor.
 * @name fetch_recurrent_files
 * @function
 * @returns {function} Retorna una función con los parámetros dispatch y getState para obtener el estado actual y actualizarlo.
*/

export const fetch_recurrent_files = () => {
    return (dispatch, getState) => {
        let headers = {"Content-Type": "application/json"};
        let {token} = getState().auth;

        if (token) {
            headers["Authorization"] = `Token ${token}`;
        }

        return axios.get("/api/recurrent/", {headers}).then(res => {
            return dispatch({type: 'FETCH_RECURRENT', files: res.data})
        }).catch(err => {
            console.log('ERROR');
            console.log(err);
            console.log(err.response.status);
        })
    }
};

/**
 * Actualiza los archivos recurrentes en el servidor.
 * @name update_recurrent_files
 * @function
 * @param {object} data - Objeto con los datos del archivo y su tipo.
 * @param {object} data.file - Archivo a ser actualizado.
 * @param {string} data.type - Tipo de archivo a ser actualizado.
 * @returns {function} Retorna una función con los parámetros dispatch y getState para obtener el estado actual y actualizarlo.
*/

export const update_recurrent_files = (data) => {
    return (dispatch, getState) => {
        let headers = {"Content-Type": "application/json"};
        let {token} = getState().auth;

        if (token) {
            headers["Authorization"] = `Token ${token}`;
        }

        let body = new FormData();

        body.append('files', data.file);
        body.append('type', data.type);

        return axios.post("/api/update_recurrent/", body, {headers}).then(res => {
            return dispatch({type: 'UPDATE_RECURRENT', response: res.data})
        }).catch(err => {
            console.log('ERROR');
            console.log(err);
            console.log(err.response);
            console.log(err.response.status);
        })
    }
};