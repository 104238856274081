const initialState = {types: [], poll: null};
/**
 * Objeto que representa el estado inicial de la aplicación.
 * @typedef {Object} InitialState
 * @property {Array} types - Lista de tipos de encuestas.
 * @property {Object} poll - Encuesta actual.
*/

/**
 * Función reductora de la tienda que maneja las acciones relacionadas con las encuestas.
 * @name polls
 * @param {InitialState} state - Estado actual de la aplicación.
 * @param {Object} action - Acción que se está ejecutando.
 * @param {string} action.type - Tipo de acción que se está ejecutando.
 * @param {*} action.poll_types - Tipos de encuestas disponibles.
 * @param {*} action.poll - Encuesta actual.
 * @returns {InitialState} Nuevo estado de la aplicación después de ejecutar la acción.
*/

export default function polls(state=initialState, action) {
  // let contractList = state.slice();

  switch (action.type) {

    case 'FETCH_POLL_TYPES':
      return {...state, types: action.poll_types};

    case 'CREATE_POLL':
      return {...state};

    case 'FETCH_POLL':
      return {...state, poll: action.poll};

    default:
      return state;
  }
}
