import React from "react";
import {Badge, Collapse, DropdownItem, NavItem, NavLink} from "shards-react";
import {users} from "../../../../actions";
import {connect} from "react-redux";
import {Link} from "react-router-dom";

/**
 * Componente que renderiza la sección de notificaciones en la barra de navegación.
 * @name Notifications
 * @class
 * @extends React.Component
*/

class Notifications extends React.Component {
    /**
 * Constructor del componente.
 * @constructor
 * @param {Object} props - Props que se pasan al componente.
*/
    constructor(props) {
        super(props);

        this.state = {
            visible: false
        };

        this.toggleNotifications = this.toggleNotifications.bind(this);
    }
/**
 * Función que cambia la visibilidad de las notificaciones.
 * @function
*/
    toggleNotifications() {
        this.setState({
            visible: !this.state.visible
        });
    }
/**
 * Ciclo de vida del componente que se ejecuta una vez que se ha montado en el DOM.
 * Se utiliza para cargar las notificaciones desde el servidor.
 * @function
*/
    componentDidMount() {
        this.props.fetch_notifications();
    }
/**
 * Ciclo de vida del componente que se ejecuta justo antes de desmontarlo del DOM.
 * @function
*/
    componentWillUnmount() {
        // clearInterval(this.getFilesInterval);
    }
/**
 * Función que renderiza el componente.
 * @function
 * @returns {JSX.Element} Elemento JSX que contiene la sección de notificaciones.
*/
    render() {
        return (

            <NavItem className="border-right dropdown notifications">
                <NavLink
                    className="nav-link-icon text-center"
                    onClick={this.toggleNotifications}
                >
                    <div className="nav-link-icon__wrapper">
                        <i className="material-icons">&#xE7F4;</i>
                        {this.props.notifications.total > 0 &&
                        <Badge pill theme="danger">
                            {this.props.notifications.total}
                        </Badge>
                        }
                    </div>
                </NavLink>
                <Collapse
                    open={this.state.visible}
                    className="dropdown-menu dropdown-menu-small"
                >
                    {this.props.notifications.transfers > 0 &&
                    <DropdownItem>
                        <div className="notification__icon-wrapper">
                            <div className="notification__icon">
                                <i className="material-icons">notification_important</i>
                            </div>
                        </div>
                        <div className="notification__content">
                            <span className="notification__category">Transferencias</span>
                            <p>
                                Tienes {this.props.notifications.transfers} transferencia{this.props.notifications.transfers > 1 && 's'} pendiente{this.props.notifications.transfers > 1 && 's'}.
                            </p>
                        </div>
                    </DropdownItem>
                    }
                    {this.props.notifications.announcements > 0 &&
                    <DropdownItem>

                            <div className="notification__icon-wrapper">
                                <div className="notification__icon">
                                    <i className="material-icons">notification_important</i>
                                </div>
                            </div>
                            <div className="notification__content">
                                <span className="notification__category">Avisos</span>
                                <Link to="/announcements">
                                <p>
                                    Tienes {this.props.notifications.announcements} aviso{this.props.notifications.announcements > 1 && 's'} pendiente{this.props.notifications.transfers > 1 && 's'}.
                                </p>
                                </Link>
                            </div>

                    </DropdownItem>
                    }
                </Collapse>
            </NavItem>
        );
    }
}
/**
 * Función que mapea el estado a las props del componente
 * @param {Object} state - Estado actual de la aplicación
 * @returns {Object} - Objeto que contiene las props mapeadas al estado
*/
const mapStateToProps = state => {
    return {
        notifications: state.users.notifications,
    }
};
/**
 * Función que mapea las acciones a las props del componente
 * @param {Function} dispatch - Función de redux para despachar acciones
 * @returns {Object} - Objeto que contiene las props mapeadas a las acciones
*/
const mapDispatchToProps = dispatch => {
    return {
        fetch_notifications: () => {
            dispatch(users.fetch_notifications());
        },
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(Notifications);