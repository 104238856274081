import React, {Component} from "react";
import {Card, CardBody, Col, Container, Row} from "shards-react";

import {connect} from "react-redux";
import PageTitle from "../components/common/PageTitle";
import {simulations} from "../actions";

/**
 * Componente que muestra la información de la simulación de precios en pasos.
 * @component
 * @param {Object} props - Propiedades para el componente.
 * @param {Object} props.match - Objeto que contiene información de la ruta de la URL.
 * @param {string} props.match.params.id - ID de la simulación.
 * @param {Function} props.fetch_simulation - Función para obtener la información de la simulación.
 * @param {Object} props.simulation - Objeto que contiene la información de la simulación.
 * @param {Array} props.simulation.simulation - Arreglo que contiene los valores de la simulación diaria.
 * @returns {JSX.Element} Elemento JSX que muestra la información de la simulación de precios en pasos.
*/

class SimulationPricingSteps extends Component {
/**
 * Constructor de la clase SimulationPricingSteps.
 * @constructor
 * @param {Object} props - Propiedades para el componente.
*/
    constructor(props) {
        super(props);
        this.state = {};
    }
/**
 * Función que se ejecuta después de que el componente ha sido montado en el DOM.
 * @function
 * @returns {void}
*/
    componentDidMount() {
        this.props.fetch_simulation(this.props.match.params.id);
    }
/**
 * Función que renderiza el componente.
 * @function
 * @returns {JSX.Element} Elemento JSX que muestra la información de la simulación de precios en pasos.
*/
    render() {
        const {simulation} = this.props;

        if (simulation === null) {
            return <h1>Loading...</h1>
        }

        console.log(simulation)
        const simulation_values = simulation.simulation;
        const simulation_daily_values = simulation_values.simulation;

        console.log(simulation_daily_values)

        return <><Container fluid className="main-content-container px-4">
            <Row noGutters className="page-header py-4">
                <PageTitle title="Simulación Firme e Interrumpible"
                           className="text-sm-left mb-3 col-sm-12"/>
            </Row>
            <Row>

            </Row>

            <Row>
                <Col>
                    <Card className="my-4">
                        <CardBody className="d-flex flex-column">
                            <table className={"table table-sm"}>
                                <thead>
                                <tr>
                                    <th>GJ</th>
                                    <th>Costo</th>
                                    <th>Precio Unitario</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>{simulation_values['gj']}</td>
                                    <td>{simulation_values['total']}</td>
                                    <td>{simulation_values['unit_price']}</td>
                                </tr>
                                </tbody>
                            </table>
                        </CardBody>
                    </Card>
                </Col>
            </Row>

            <Card small className="my-4">
                <CardBody className="d-flex flex-column">
                    <div className={"table-responsive"}>
                        <table className="table table-sm simulation-data">
                            <thead>
                            <tr>
                                <th>Día</th>
                                <th>Consumo</th>
                                <th>Precio</th>
                            </tr>
                            </thead>
                            <tbody>
                            {simulation_daily_values.map((data, id) => (
                                <tr key={id}>
                                    <td>{data['day']}</td>
                                    <td>{data['consumption']}</td>
                                    <td>{data['price']}</td>
                                </tr>
                            ))}
                            <tr>

                            </tr>
                            </tbody>
                        </table>
                    </div>

                </CardBody>
            </Card>

        </Container></>;

    }

}
/**
 * Función que mapea el estado a las props del componente
 * @param {Object} state - Estado actual de la aplicación
 * @returns {Object} - Objeto que contiene las props mapeadas al estado
*/
const mapStateToProps = state => {
    return {
        simulation: state.simulations.detail_pss,
    }
};
/**
 * Función que mapea las acciones a las props del componente
 * @param {Function} dispatch - Función de redux para despachar acciones
 * @returns {Object} - Objeto que contiene las props mapeadas a las acciones
*/
const mapDispatchToProps = dispatch => {
    return {
        fetch_simulation: (id) => {
            return dispatch(simulations.fetchPricingStepssimulation(id))
        },
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(SimulationPricingSteps);
