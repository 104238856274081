import axios from "axios";

/**
 * Obtiene los tipos de encuesta de la API y los almacena en el estado.
 * @name fetch_poll_types
 * @returns {Promise} Una promesa que se resuelve con el resultado de la acción.
*/

export const fetch_poll_types = () => {
    return (dispatch, getState) => {
        let headers = {"Content-Type": "application/json"};
        let {token} = getState().auth;

        if (token) {
            headers["Authorization"] = `Token ${token}`;
        }

        return axios.get("/api/polls/", {headers}).then(res => {
            return dispatch({type: 'FETCH_POLL_TYPES', poll_types: res.data})
        }).catch(err => {
            console.log('ERROR');
            console.log(err);
            console.log(err.response.status);
        })
    }
};

/**
 * Crea una nueva encuesta en la base de datos y la agrega al estado.
 * @name create_poll
 * @param {Object} data - Los datos de la encuesta que se va a crear.
 * @returns {Promise} Una promesa que se resuelve con el resultado de la acción.
*/

export const create_poll = (data) => {
    return (dispatch, getState) => {
        let headers = {"Content-Type": "application/json"};
        let {token} = getState().auth;

        if (token) {
            headers["Authorization"] = `Token ${token}`;
        }

        let body = {...data};

        return axios.post("/api/customer_polls/", body, {headers}).then(res => {
            return dispatch({type: 'CREATE_POLL', poll: res.data})
        }).catch(err => {
            console.log('ERROR');
            console.log(err);
            console.log(err.response.status);
        })
    }
};

/**
 * Obtiene una encuesta específica de la API y la almacena en el estado.
 * @name fetch_customer_poll
 * @param {Number} id - El ID de la encuesta que se desea obtener.
 * @returns {Promise} Una promesa que se resuelve con el resultado de la acción.
*/

export const fetch_customer_poll = (id) => {
    return (dispatch, getState) => {
        let headers = {"Content-Type": "application/json"};
        let {token} = getState().auth;

        if (token) {
            headers["Authorization"] = `Token ${token}`;
        }

        return axios.get(`/api/customer_polls/${id}/`, {headers}).then(res => {
            return dispatch({type: 'FETCH_POLL', poll: res.data})
        }).catch(err => {
            console.log('ERROR');
            console.log(err);
            console.log(err.response.status);
        })
    }
};