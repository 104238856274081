/**
 * Estado inicial del arreglo de archivos.
 * @type {Array}
*/
const initialState = [];
/**
 * Reducer para los archivos.
 * @name files
 * @param {Array} state - Estado actual del arreglo de archivos.
 * @param {Object} action - Acción a realizar.
 * @param {string} action.type - Tipo de acción.
 * @param {Array} action.announcements - Arreglo de anuncios a retornar.
 * @param {Object} action.announcement - Anuncio a agregar.
 * @returns {Array} Nuevo estado del arreglo de archivos.
*/

export default function files(state=initialState, action) {

  switch (action.type) {

    case 'FETCH_ANNOUNCEMENTS':
      return [...action.announcements];

    case 'SUBMIT_ANNOUNCEMENT':
      return [action.announcement, ...state];

    default:
      return state;
  }
}