import React, {Component} from "react";
import {Button, FormCheckbox, FormInput, InputGroup, InputGroupAddon, FormSelect} from "shards-react";
import {connect} from "react-redux";

import {customersV2} from "../../actions";

/**
 * Componente que muestra un campo de estado que puede ser de varios tipos: file, boolean, choice y number.
 * @component
 * @param {Object} props - Las props del componente.
 * @param {Object} props.field - Un objeto que contiene información sobre el campo a mostrar, incluyendo su nombre, tipo y valor actual.
 * @param {Boolean} props.rejected - Indica si la actualización de este campo ha sido rechazada.
 * @param {Function} props.update_customer_storage - Una función que actualiza el valor del campo en la base de datos.
 * @returns {JSX.Element} - Devuelve un elemento JSX que representa el campo de estado.
*/
// const Login = () => (
class StatusFields extends Component {
/**
 * Crea una instancia del componente StatusFields.
 * @constructor
 * @param {Object} props - Las props del componente.
*/
    constructor(props) {
        super(props);
/**
 * El estado del componente. Contiene el valor actual del campo de estado (file, generic o number).
 * @type {Object}
 * @property {String} file - El valor actual del campo de tipo file.
 * @property {String} generic - El valor actual del campo de tipo boolean, choice o string.
 * @property {Number} number - El valor actual del campo de tipo number.
*/
        this.state = {
            'file': '',
            'generic': '',
            'number': 0,
        };

    }
/**
 * Método del ciclo de vida del componente que se ejecuta después de que el componente se haya montado.
 * @method componentDidMount
*/

    componentDidMount() {

    }
/**
 * Manejador de eventos que se activa cuando el usuario selecciona un archivo.
 * @method fileChangeHandler
 * @param {Object} e - El evento generado por el input de tipo file.
*/
    fileChangeHandler = (e) => {
        this.submit_file(e.target.files[0])
    };
/**
 * Función que actualiza el valor del campo de tipo file en la base de datos.
 * @method submit_file
 * @param {Object} file - El archivo que el usuario ha seleccionado.
*/
    submit_file = (file) => {

        this.props.update_customer_storage(this.props.field.id, file, 'file').then(() => (
            this.setState({file: '', generic: ''})
        ));

    };
/**
 * Manejador de eventos que se activa cuando el usuario hace clic en el botón Guardar de un campo de tipo boolean, choice o string.
 * @method submit_generic
 * @param {Object} e - El evento generado por el botón Guardar.
*/
    submit_generic = (e) => {
        const {field} = this.props;
        let value = null;
        if (field.type === 'number') {
            value = this.state.number;
        } else {
            value = this.state.generic;
        }
        this.props.update_customer_storage(this.props.field.id, value, field.type).then(() => (
            this.setState({file: '', generic: ''})
        ));
    }

/**
 * Renderiza el componente.
 * @method render
 * @returns {JSX.Element} - Devuelve un elemento JSX que representa el campo de estado.
*/
    render() {
        const {field} = this.props;
        const {rejected} = this.props;
        let field_item = ""
        if (field.type === 'file') (
            field_item = (
                <li key={field.id}>
                    <span className={field.complete ? "": "incomplete"}>{field.name}:</span> {field.field}<FormInput
                    type="file"
                    onChange={this.fileChangeHandler}
                    value={this.state.file}
                    disabled={rejected}
                />

                </li>
            )
        );
        else if (field.type === 'boolean') {
            field_item = (
                <li key={field.id}>
                    <span className={field.complete ? "": "incomplete"}>{field.name}:</span> {field.field}
                    <InputGroup seamless className="mb-3">
                        <FormCheckbox
                            checked={this.state.generic}
                            id="new-contract"
                            // onClick={(e) => this.setState({newContract: !e.target.value})}
                            onChange={(e) => this.setState({generic: !this.state.generic})}
                            disabled={rejected}
                        />
                        <InputGroupAddon type="append">
                            <Button theme="primary" onClick={this.submit_generic} disabled={rejected}>Guardar</Button>
                        </InputGroupAddon>
                    </InputGroup>

                </li>
            );
        } else if (field.type === 'choice') {
            field_item = (
                <li key={field.id}><span className={field.complete ? "": "incomplete"}>{field.name}:</span> {field.field}
                    <InputGroup seamless className="mb-3">
                        <FormSelect
                            checked={this.state.generic}
                            onChange={(e) => this.setState({generic: e.target.value})}
                            disabled={rejected}
                        >
                            <option value=''>-----</option>
                            {field.choices.map((choice, id) => (
                                <option value={choice}>{choice}</option>
                            ))}
                        </FormSelect>
                        <InputGroupAddon type="append">
                            <Button theme="primary" onClick={this.submit_generic} disabled={rejected}>Guardar</Button>
                        </InputGroupAddon>
                    </InputGroup>
                </li>
            )
        } else if (field.type === 'number') {
            field_item = (
                <li key={field.id}>
                    <span className={field.complete ? "": "incomplete"}>{field.name}:</span> {field.field}
                    <InputGroup seamless className="mb-3">
                        <FormInput
                            type={field.type}
                            onChange={(e) => this.setState({number: e.target.value})}
                            value={this.state.number}
                            disabled={rejected}
                        />
                        <InputGroupAddon type="append">
                            <Button theme="primary" onClick={this.submit_generic} disabled={rejected}>Guardar</Button>
                        </InputGroupAddon>
                    </InputGroup>

                </li>
            )
        } else {
            field_item = (
                <li key={field.id}>
                    <span className={field.complete ? "": "incomplete"}>{field.name}:</span> {field.field}
                    <InputGroup seamless className="mb-3">
                        <FormInput
                            type={field.type}
                            onChange={(e) => this.setState({generic: e.target.value})}
                            value={this.state.generic}
                            disabled={rejected}
                        />
                        <InputGroupAddon type="append">
                            <Button theme="primary" onClick={this.submit_generic} disabled={rejected}>Guardar</Button>
                        </InputGroupAddon>
                    </InputGroup>

                </li>
            )
        }

        return field_item
    }
}
/**
 * Función que mapea el estado a las props del componente
 * @param {Object} state - Estado actual de la aplicación
 * @returns {Object} - Objeto que contiene las props mapeadas al estado
*/
const mapStateToProps = state => {
    return {};
};
/**
 * Función que mapea las acciones a las props del componente
 * @param {Function} dispatch - Función de redux para despachar acciones
 * @returns {Object} - Objeto que contiene las props mapeadas a las acciones
*/
const mapDispatchToProps = dispatch => {
    return {
        update_customer_storage: (id, file, type) => {
            return dispatch(customersV2.update_customer_storage(id, file, type))
        },
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(StatusFields);
