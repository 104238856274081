import {combineReducers} from 'redux';
import notes from "./notes";
import auth from "./auth";
import files from "./files"
import simulations from "./simulations";
import contracts from "./contracts";
import quoteSimulations from "./quoteSimulations";
import recurrent from "./recurrent";
import announcements from "./announcements";
import customers from "./customers";
import customersV2 from "./customers_v2";
import users from "./users";
import polls from "./polls"
import reports from "./reports"

/**
 * Reducers combinados que manejan el estado global de la aplicación.
 * @typedef {object} ponyApp
 * @property {function} notes - Reducer para las notas.
 * @property {function} auth - Reducer para la autenticación.
 * @property {function} files - Reducer para los archivos.
 * @property {function} simulations - Reducer para las simulaciones.
 * @property {function} contracts - Reducer para los contratos.
 * @property {function} quoteSimulations - Reducer para las cotizaciones.
 * @property {function} recurrent - Reducer para los pagos recurrentes.
 * @property {function} announcements - Reducer para los anuncios.
 * @property {function} customers - Reducer para los clientes.
 * @property {function} customersV2 - Reducer para los clientes en una nueva versión.
 * @property {function} users - Reducer para los usuarios.
 * @property {function} polls - Reducer para las encuestas.
 * @property {function} reports - Reducer para los reportes.
*/

/**
 * Combinación de todos los reducers para crear el estado global de la aplicación.
 * @function
 * @returns {ponyApp} Objeto que contiene todos los reducers combinados.
*/

const ponyApp = combineReducers({
    notes,
    auth,
    files,
    simulations,
    contracts,
    quoteSimulations,
    recurrent,
    announcements,
    customers,
    users,
    polls,
    reports,
    customersV2,
});

export default ponyApp;